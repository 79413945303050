import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {ListaErros} from '../../../sessoes/Processamento/preview/listaErros.js';
import {Submit} from '../../../sessoes/Processamento/preview/Submit';
import EnvioComponent from './EnvioComponent.js';
import EventoRecebidoPreview from './EventoRecebidoPreview.js';
import { ProgressBar } from 'primereact/progressbar';
import EventoEmProcessamentoPreview from './EventoEmProcessamentoPreview.js';


export class TabEnvio extends Component{
    constructor(){
      super();  
      this.state={
        visiblePreview:false,
        sendPreview:false,
        idEventoFila:0,
        activeIndex:0,
        idEventoEsocial:0,
        listaErros:[],
        listaPrerequisitos:[]
        
      }
      
  
    }
    
    tabChange(e){
      this.setState({activeIndex: e.index});
    }
    renderEnvioComponent(activeIndex, isEventosEnviados, respostaEnvio){
      if(activeIndex==1){
        console.log(respostaEnvio);
        return <EnvioComponent isEventosEnviados={isEventosEnviados} respostaEnvio={respostaEnvio[0]}></EnvioComponent>
      }
    }
    renderMensagemEnvioEvento(situacao, listaPreRequisito, listaErros, idEvento){
      console.log(situacao);
      if(situacao!='RECEBIDO'){
        if(situacao=='AGUARDANDO_RETORNO' || situacao=='EM_PROCESSAMENTO'){
          return <EventoEmProcessamentoPreview idEventoFila={idEvento}/>
        }
        return <ListaErros listaPreRequisito={listaPreRequisito} listaErros={listaErros}/>
      }else{
        return <EventoRecebidoPreview idEvento={idEvento}></EventoRecebidoPreview>
      }
    }

    renderLoading(){
      return (
        <div>
          <h3>Validando o evento, por favor aguarde.</h3>
          <ProgressBar mode="indeterminate" />
        </div>
      )
    }

    
    render(){
      
      return(
        <div>
          <TabView 
            activeIndex={this.props.activeIndex} 
            onTabChange={(e) => this.tabChange(e) } 
            style={{width:'650px'}}
          >

              <TabPanel 
                header="Info" 
                leftIcon="pi pi-calendar" 
                className="p-tabview-selected"
                renderActiveOnly={true} 
              >
                {this.props.listaErros.length==0 ? this.renderLoading() : this.renderMensagemEnvioEvento(this.props.situacao, this.props.listaPreRequisito, this.props.listaErros, this.props.idEventoFila)}
                {/* {this.renderMensagemEnvioEvento(this.props.situacao, this.props.listaPreRequisito, this.props.listaErros, this.props.idEventoFila)} */}

              </TabPanel>  

              <TabPanel 
                header="Informações do Envio" 
                leftIcon="pi pi-envelope" 
                disabled={this.props.tabEnviandoDisable}
              >

                {this.renderEnvioComponent(this.props.activeIndex, this.props.isEventosEnviados, this.props.respostaEnvio)}
                  {/* <Submit 
                    idEventoFila={this.props.idEventoFila} 
                  /> */}

              </TabPanel>

          </TabView>
        </div>
      );
    }
  }
import React, {Component} from 'react';
import {Menubar} from 'primereact/menubar';
import $ from 'jquery';
import './../css/pure-min.css';
import './../css/marketing.css';
import {SplitButton} from 'primereact/splitbutton';
import {Button} from 'primereact/button';
import {Badge} from 'primereact/badge';


export class MenuBar extends Component{
  constructor(){
    super();
    var baseurl = window.location.protocol + "//" + window.location.host ;
    this.state={lista : [],menuBar :[
              
                {label: 'Configurações ',
                    icon: 'pi pi-refresh',
                    command: (e) => {
                        window.location.href = baseurl+ "/user/config"
                    }
                },
                { 
                  label: 'Plano de Ação',
                  icon: 'pi pi-cloud-download',
                  command: (e) => {
                      window.location.href = baseurl+ "/documentacao/MapeamentoCampos.xlsx"
                  }
                },
                { 
                  label: 'Ajuda ',
                  icon: 'pi pi-question-circle',
                  command: (e) => {
                      window.location.href = baseurl+ "/ajuda/"
                  }
                },
                {   label: 'Sair',
                    icon: 'pi pi-power-off',
                    command:(e) => {
                        window.location.href = baseurl+ "/";
                    }
                }],items:[],eventos:[],usuario:{},qtdeMensagens:'0',qtdAnterior:'0'}
                this.acessarMensagens=this.acessarMensagens.bind(this);
  }
  
  componentDidMount(){
   this.setState({usuario:this.props.usuario});
   this.qtdeMensagens();
   this.construirMenu();
  }
  

  construirMenu(){
    var baseurl = window.location.protocol + "//" + window.location.host ;
    $.ajax({      
      url:"/EsocialAPI/usuario/menu/"+this.state.usuario.login,
      dataType: 'json',
      error:function(erro){
        //window.location.href= baseurl;
        console.log(erro);
      }, 
      success:function(retorno){
      
        this.setState({items : retorno});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  qtdeMensagens(){
    var baseurl = window.location.protocol + "//" + window.location.host ;
    let us = this.state.usuario.idUsuario;
    if((us!==null)&&(us!==undefined)){
      $.ajax({
        url:"/EsocialAPI/usuario/mensagens/"+this.state.usuario.idUsuario,
        dataType: 'json',
        success:function(resposta){
          console.log('mensagens: '+resposta);
          this.setState({qtdeMensagens:resposta.length.toString()});
  
        }.bind(this),
        error:function(error){
          console.log(error);
          //window.location.href= baseurl;
        },
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      }); 
    }
  }
  acessarMensagens(){
    var baseurl = window.location.protocol + "//" + window.location.host ;
    console.log("Clicou");
    window.location.href = baseurl+ "/mensagem"
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render(){
    const logo=<img src="/esocial-logo.png" alt="" style={{margin: 'auto'}}/>;
    const botoes = (    
      <div>
      {/* <Button type="button" label="Alertas" icon="pi pi-bell" className="p-button-info" onClick={(e)=> this.acessarMensagens()} >
          <Badge value={this.state.qtdeMensagens} severity="warning"/>
      </Button> */}
      <span className="p-overlay-badge" style={{marginLeft:'15px'}}>
          <SplitButton label={this.props.usuario.login} icon="pi pi-user-plus" onClick={this.save} model={this.state.menuBar} />
      </span>
      
    </div>);
    return(
      <div style={{marginTop:'12px'}}>
        <Menubar start={logo} model={this.state.items} end={botoes}></Menubar>
      </div>
    );
  }
}
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { EditarUsuario } from './EditarUsuario';

export class UsuarioTabela extends Component {
  constructor() {
    super();
    this.state = { lista: [], visible: false, usuario: {}, nome: "", login: "", email: "", senha: "", recebesms: false, whatsapp: false, telegram: false, grupo: null, grupos: [], visibleEditar: false };

    this.actionTemplate = this.actionTemplate.bind(this);
  }
  componentDidMount() {
    $.ajax({
      url: "/EsocialAPI/api/grupo/select",
      dataType: 'json',
      success: function (rsp) {
        console.log(rsp);
        this.setState({ grupos: rsp });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  enviarNovoUsuario() {
    var grupo = { idGrupo: this.state.grupo };
    var usuario = { nome: this.state.nome, login: this.state.login, grupo: grupo, email: this.state.email, password: this.state.senha, recebeSms: this.state.recebesms, whatsapp: this.state.whatsapp, telegram: this.state.telegram };
    var dados = JSON.stringify(usuario);
    console.log(dados);
    $.ajax({
      url: "/EsocialAPI/usuario/novo",
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      data: dados,
      error: function (erro) {
        this.setState({ visible: false });
        this.messages.show({ severity: 'success', summary: 'Usuário salvo com sucesso', detail: 'Usuario salvo' });
        this.atualizarLista();
      }.bind(this),
      success: function (resposta) {
        console.log(resposta);
        this.setState({ catalogo: resposta });
        this.atualizaEventos();
        this.setState({ visible: false });
        this.atualizarLista();
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  componentWillMount() {
    this.atualizarLista();

  }
  atualizarLista() {
    $.ajax({
      url: "/EsocialAPI/usuario/listar",
      dataType: 'json',
      error: function(erro){
        console.log(erro);
      },
      success: function (resposta) {
        console.log(resposta);
        this.setState({ lista: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  onHide() {
    this.setState({ visible: false });
  }
  actionTemplate(rowData, column) {
    console.log(rowData);
    //console.log(rowData['grupo'].idGrupo);
    const editarComponent = (
      <div>
         <EditarUsuario 
          nome={rowData['nome']}
          login={rowData['login']}
          grupos={this.state.grupos}
          grupo={rowData['grupo'].idGrupo}
          email={rowData['email']}
          recebeSms={rowData['recebeSms']}
          whatsapp={rowData['whatsapp']}
          telegram={rowData['telegram']}
          idUsuario={rowData['idUsuario']}
          dataCadastro={rowData['dataCadastro']}
          dataExpiracao={rowData['dataExpiracao']}
         >
         </EditarUsuario>
      </div>
    );
    return editarComponent;
  }
  render() {
    let paginatorLeft = <Button icon="pi pi-refresh" />;
    const footer = (
      <div>
        <Button label="Salvar" icon="pi pi-check" onClick={this.enviarNovoUsuario.bind(this)} />
        <Button label="Cancelar" icon="pi pi-times" onClick={this.onHide} />
      </div>
    );
    return (
      <div>
        <br />
        <Messages ref={(el) => this.messages = el}></Messages>
        <Panel header="Usuários">
          <div>
            <div className="feature-intro">
              <br />
              <em>Usuarios de sistemas registrados para acesso:</em>
            </div>
            <div>
              <Button label="Novo" icon="pi pi-user-plus" iconPos="left" style={{ margin: '3px' }} onClick={(e) => this.setState({ visible: true })} />
            </div>
            <Dialog header="Novo Usuário" visible={this.state.visible} style={{ width: '50vw' }} modal={true}
              onHide={(e) => this.setState({ visible: false })} footer={footer}>
              <div className="pure-g">
                <div className="pure-u-2-3">
                  <br />
                  <span className="p-float-label">
                    <InputText id="nomeCompleto" value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} />
                    <label htmlFor="nomeCompleto">Nome Completo</label>
                  </span>
                  <br />
                  <span className="p-float-label">
                    <InputText id="usuario" value={this.state.login} onChange={(e) => this.setState({ login: e.target.value })} />
                    <label htmlFor="usuario">Usuário</label>
                  </span>
                  <br />
                  <span className="p-float-label">
                    <Password id="senha" value={this.state.senha} onChange={(e) => this.setState({ senha: e.target.value })} />
                    <label htmlFor="senha">Senha</label>
                  </span>
                  <br />
                  <Dropdown optionLabel="label" value={this.state.grupo} options={this.state.grupos} onChange={(e) => { this.setState({ grupo: e.value }); console.log(e.value) }} placeholder="Escolha o Grupo" />
                  <br />
                  <br />
                  <span className="p-float-label">
                    <InputText id="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                    <label htmlFor="email">Email</label>
                  </span>
                  <br />
                  <Checkbox checked={this.state.recebesms} onChange={e => this.setState({ recebesms: e.checked })} /> - SMS<br />
                  <Checkbox checked={this.state.whatsapp} onChange={e => this.setState({ whatsapp: e.checked })} /> - WhatsApp<br />
                  <Checkbox checked={this.state.telegram} onChange={e => this.setState({ telegram: e.checked })} /> - Telegram<br />
                </div>
                <div className="pure-u-1-3">
                  <p>Recebe SMS: <span style={{ fontWeight: 'bold' }}>{this.state.recebesms ? 'true' : 'false'}</span></p>
                  <p>Recebe WhatsApp: <span style={{ fontWeight: 'bold' }}>{this.state.whatsapp ? 'true' : 'false'}</span></p>
                  <p>Recebe Telegram: <span style={{ fontWeight: 'bold' }}>{this.state.telegram ? 'true' : 'false'}</span></p>
                </div>
              </div>
            </Dialog>
          </div>
          <div className="content-section implementation">
            <DataTable value={this.state.lista} paginator={true} paginatorLeft={paginatorLeft} rows={10} emptyMessage="Desculpe, nenhum usuário registrado para exibição">
              <Column field="idUsuario" header="Id" sortable={true} />
              <Column field="nome" header="Nome" />
              <Column field="login" header="Login" />
              <Column field="grupo.grupo" header="Grupo" />
              <Column body={this.actionTemplate} header="Editar" />
            </DataTable>
          </div>
        </Panel>
      </div>
    );
  }
}

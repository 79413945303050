import React, {Component} from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Panel} from 'primereact/panel';
import {Grafico} from '../componentes/Grafico';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Carousel} from 'primereact/carousel';
import {InputMask} from 'primereact/inputmask';
import {Messages} from 'primereact/messages';
import { faThumbsDown, faThumbsUp, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';

export class Principal extends Component{
  constructor() {
    super();
    this.state={lista : []
      ,resposta:{}
      ,agendamentos:[]
      ,artigos:[]
      ,layout: 'list'
      ,instituicao:''
      ,cnpj:''
      ,alertas:0
      ,pendencias:0
      ,enviados:0
      ,certExpirado:{color:'black'}
      ,validadeCertificado:''
      ,ambienteEsocial:''
      ,versaoProcessamento:''
      ,processoEmissao:''
      ,tipoAmbiente:''
      ,versaoServico:''
      ,arquivoCertificado:''
      ,certificadora:''
      ,dataEmissao:''
      ,dataExpiracao:''
      ,razaoSocial:''
      ,nrInsc:''
      ,cnpjRaiz:false
      ,nrInscRaiz:''
      ,eventosPrazos:[]
      ,eventos:[{evento:'S1000',dataEnvio:'2019-10-31'},{evento:'S1010',dataEnvio:'2019-10-30'},{evento:'S1010',dataEnvio:'2019-10-29'}]
      ,tipoEvento:"tabela"
      ,subMenu:[{label: 'Novo', icon: 'pi pi-fw pi-plus'},{label: 'Editar', icon: 'pi pi-fw pi-pencil'},{label: 'Apagar', icon: 'pi pi-fw pi-trash'}]
    };

    this.retornaCnpj = this.retornaCnpj.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  componentWillMount(){
    this.atualizaGrid();
    this.atualizaEventos();
    this.infoLocal();
    this.carregarPrazoEventos();
  }

  carregarPrazoEventos() {
    $.ajax({
        url: "/EsocialAPI/evento/lista/atual",
        dataType: 'json',
        error: function (e) {
            console.log(e);
        },
        success: function (resposta) {
            this.setState({ eventosPrazos: resposta });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  infoLocal(){
    $.ajax({
      url:"/EsocialAPI/api/dashboard/info",
      dataType: 'json',
      success:function(resposta){
        let expirado = false;
        let today= new Date();

        if(resposta.certificado !== null) {
          let dt = new Date(resposta.certificado.dataExpiro);
          if(dt<today){
            expirado=true;
          }
          if(expirado){
            this.setState({certExpirado:{color:'red'},alertas:1});
            this.messages.show({severity: 'error', summary: 'Erro:', detail: 'Certificado expirado',sticky:true});
  
          }

          this.setState({
            instituicao:resposta.certificado.nome,
            cnpj:resposta.certificado.cpfCnpj,
            dataEmissao:resposta.certificado.dataEmissaoFormatada,
            dataExpiracao:resposta.certificado.dataExpiroFormatada,
            certificadora:resposta.certificado.unidadeCertificadora
          });
        }

        this.setState({
          versaoProcessamento:resposta.configuracaoAmbiente.versaoProcessamento,
          processoEmissao:resposta.configuracaoAmbiente.processoEmissao,
          tipoAmbiente:resposta.configuracaoAmbiente.ambiente,
          versaoServico:resposta.configuracaoAmbiente.versaoServico,
          pendencias:resposta.pendentes,
          alertas:resposta.rejeitados,
          razaoSocial:resposta.configuracaoInstitucional.nomeRazao,
          nrInsc:resposta.configuracaoInstitucional.cpfCnpj,
          nrInscRaiz:resposta.configuracaoInstitucional.numInscRaiz,
          cnpjRaiz:resposta.configuracaoInstitucional.cnpjRaiz,
          enviados:resposta.enviados
        });
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  atualizaGrid(){
    $.ajax({
      url:"/EsocialAPI/api/dashboard/agenda",
      dataType: 'json',
      success:function(resposta){
        //console.log(resposta);
        this.setState({artigos:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  mostrarDiv(alvo){
    var objeto = '#'+alvo;
    $(objeto).fadeIn();
  }

  atualizaEventos(){
    $.ajax({
      url:"/EsocialAPI/api/dashboard/ultimosEventos",
      dataType: 'json',
      success:function(resposta){
        //console.log(resposta);
        this.setState({eventos:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  isCnpjRaiz(props) {
    const isRaiz = props.isRaiz;
    if (isRaiz) {    return <FontAwesomeIcon icon={faThumbsUp} />;  }  return <FontAwesomeIcon icon={faThumbsDown} />;
  }

  itemTemplate(artigo, layout) {
    if(artigo!=null){
      return (
        <Panel header={artigo.dataPublicacao+' '+artigo.titulo}  collapsed={false} style={{marginLeft:'8px'}}>
            <ul>
              <li style={{listStyle:'none'}}>Compromisso: {artigo.dataCompromisso}</li>
              <li style={{listStyle:'none'}}>Publicacao: {artigo.dataPublicacao+' '+artigo.horaPublicacao}</li>
              <li style={{listStyle:'none'}}>
                <a href={artigo.url} target="_blank" rel="noopener noreferrer">Saiba mais</a>
              </li>
            </ul>
        </Panel>        
      );
    } else{
      return("");
    }
  }

  renderDataEnvio(rowData, column) {
    return <span>{new Date(rowData['dataEnvio']).toLocaleString()}</span>;
  }

  renderData(dataAExibir){
    console.log(dataAExibir)
    try{
      let data = new Date(dataAExibir);
      console.log(data.toLocaleString());
      return data.toLocaleString();
    }catch(erro){
      console.log(erro);
    }
  }

  retornaCnpj(){
    if(this.state.cnpj==="Não contem" || this.state.cnpj===null || this.state.cnpj===undefined){
      return this.state.nrInsc;
    }else{
      return this.state.cnpj;
    }
  }

  render() {
    console.log(this.state.eventosPrazos)
    const panelRejeitados={
      width:'20%',
      height:'100px',
      display:'inline-block',
      border:'1px solid #ffc',
      textAlign:'center',
      margin:'8px 8px 8px 8px',
      color:'#fff',
      backgroundColor:'#FF0000',
      borderRadius:'10px'
    };
    const panelPendentes={
      width:'20%',
      height:'100px',
      display:'inline-block',
      border:'1px solid #ffc',
      textAlign:'center',
      margin:'8px 8px 8px 8px',
      color:'#fff',
      backgroundColor:'#FFCA28',
      borderRadius:'10px'
    };
    const panelEnviado={
      width:'20%',
      height:'100px',
      display:'inline-block',
      border:'1px solid #ffc',
      textAlign:'center',
      margin:'8px 8px 8px 8px',
      color:'#fff',
      backgroundColor:'#6DD900',
      borderRadius:'10px'
    };
    return(
      <div className="content align-center">
        <br />
        <Panel header="Bem-vindo">
          <Messages ref={(el) => this.messages = el}></Messages>
          <div className="pure-u-3-3">
            <div className="pure-u-3-3" style={{marginBottom:'25px'}}>
              <a href={window.location.href} onClick={(e)=>this.mostrarDiv('moreInfo')}><img src="/EsocialAPI/api/logo" alt="" /></a>
            </div>
            <div className="pure-u-1-4">
              <h4 style={{verticalAlign:'bottom'}}>
                <img src="/img/icons/64/info.png" height="24" width="24" alt="informações Institucionais"/>Informações Institucionais
              </h4>
              <em></em>
              <div  className="pure-u-3-3">
                <div className="pure-u-4-4" style={{display:'block'}} id="moreInfo">
                  <div>
                    <label>Razão Social:</label><strong>{this.state.razaoSocial}</strong><br />
                    <label>Nrº de inscrição:</label><strong>{this.state.nrInsc}</strong><br />
                    <label>Usar CNPJRaiz: &nbsp;</label><strong><FontAwesomeIcon icon={faThumbsUp} /></strong><br />
                  </div>
                </div>
              </div>          
            </div>
            <div className="pure-u-1-4" style={{borderLeft:'1px solid #ccc',padding:'0px 5px 15px 5px'}}>
              <h4 style={{textAlign:'center'}}>Ambiente eSocial</h4>
              <div>
                  <label>Processo de emissão:</label><strong>{this.state.processoEmissao}</strong><br />
                  <label>Tipo de ambiente:</label><em>{this.state.tipoAmbiente}</em><br />
                  <label>Catalogo:</label> <strong>{this.state.versaoProcessamento}</strong><br />
                  <label>Versão de comunicação:</label><strong>{this.state.versaoServico}</strong>
              </div>
            </div>
            <div className="pure-u-1-4"style={{padding:'0px 5px 5px 5px',borderLeft:'1px solid #ccc', borderRight:'1px solid #ccc'}}> 
                <h4><img src="/img/icons/64/certificado.png" height="32" width="32" alt=""/> Certificado</h4>
                <label>Instituição: <strong>{this.state.instituicao}</strong></label><br />
                <label>Unidade Certificadora: <strong>{this.state.certificadora}</strong></label><br />
                <label>CPF/CNPJ:</label> <InputMask mask="99.999.999/9999-99" value={this.retornaCnpj()} onChange={(e) => this.setState({value: e.value})} disabled={true}></InputMask><br />
                <label>Data Emissão: </label><strong>{this.state.dataEmissao}</strong><br />
                <label>Data Expiração: </label><strong>{this.state.dataExpiracao}</strong>
            </div>
            <div className="pure-u-1-4" >
              <h4><FontAwesomeIcon icon={faTachometerAlt} /> Status de envio</h4>
              <div className="pure-3-3" style={{textAlign:'center'}}>
                <div className="pure-u-1-3" style={panelRejeitados}>Rejeitados<h1 style={{color:'#fff'}}>{this.state.alertas}</h1></div>
                <div className="pure-u-1-3" style={panelPendentes}>Pendências<h1 style={{color:'#fff'}}>{this.state.pendencias}</h1></div>
                <div className="pure-u-1-3" style={panelEnviado}>Enviados<h1 style={{color:'#fff'}}>{this.state.enviados}</h1></div>
              </div>
            </div>
          </div>
          <br />
          <div className="pure-g">
            <div className="pure-u-1-2" style={{paddingRight:'12px'}}>
              <Panel header="Agenda eSocial">
                <Carousel value={this.state.artigos} itemTemplate={this.itemTemplate} numVisible={3} numScroll={3}></Carousel>
              </Panel>
            </div>
            <div className="pure-u-1-2">
              <Panel header="Atividades Recentes">
                <DataTable scrollable scrollHeight="161px" value={this.state.eventos.eventos} emptyMessage="Nenhum evento foi enviado até o momento.">
                    <Column field="evento" header="Evento" />
                    <Column field="dataEnvio" header="Data Envio" body={this.renderDataEnvio} />
                </DataTable>
                <div style={{textAlign:'right'}}></div>
              </Panel>
            </div>
          </div>
          <hr />
          <div className="pure-g">
            <div className="pure-u-1-1">
              <Panel header="Prazos Eventos">
                <DataTable scrollable scrollHeight="200px" value={this.state.eventosPrazos} selectionMode="single" emptyMessage="Nenhum evento registrado até o momento.">
                    <Column field="codigo" header="Evento" style={{ width: '5%'}}/>
                    <Column field="descricaoPrazo" header="Prazo envio" style={{ width: '80%'}}/>
                </DataTable>
                <div style={{textAlign:'right'}}></div>
              </Panel>
            </div>
          </div>
          <hr />
          <div className="pure-g">
            <div className="pure-u-1-3">
              <Panel header="Eventos de Tabela">
                <div>
                  <Grafico idTipoEvento="6" />
                </div>
              </Panel>
            </div>
            <div className="pure-u-1-3">
              <Panel header="Eventos Não Periódicos" style={{paddingLeft:'12px',paddingRight:'12px'}}>
                <div>
                  <Grafico idTipoEvento="7"  />
                </div>
              </Panel>
            </div>
          <div className="pure-u-1-3">
            <Panel header="Eventos Periódicos">
              <div>
                <Grafico idTipoEvento="8" />
              </div>
            </Panel>
          </div>
        </div>
      </Panel>
    </div>      
    );
  }
}

import React, { Component } from 'react';
import $ from 'jquery';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faIdCard, faCheckCircle, faTimesCircle, faMale, faFemale, faAddressCard, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../css/PreviewEventos.css';
import { ListaErros } from '../../../sessoes/Processamento/preview/listaErros.js';
export class AdmissaoView extends Component {
    constructor() {
        super();
        library.add(fab, faIdCard, faCheckCircle, faTimesCircle, faMale, faFemale, faAddressCard, faWheelchair);
        this.state = {
            idEventoFila: 0,
            bolAtivo: false,
            evtValido: true,
            activeIndex: 0,
            listaErros: null,
            listaPreRequisito: null,
            previewErros: 'none',
            panelErros: true,
            qtdErros: 0,
            evtJson: null,
            tabs: ["tabActive0", "tabActive1", "tabActive2", "tabActive3"],
            tabActive0: 'block',
            tabActive1: 'none',
            tabActive2: 'none',
            tabActive3: 'none',
            ideEmpregador: { nrInsc: "", tpInsc: 0 },
            ideEvento: { indRetif: 0, nrRecibo: "", procEmi: 0, tpAmb: 0, verProc: "" },
            trabalhador: {
                "cpfTrab": "",
                "nmTrab": "",
                "sexo": "",
                "racaCor": 0,
                "estCiv": 0,
                "grauInstr": null,
                "nmSoc": null,
                "nascimento":
                {
                    "dtNascto": 0,
                    "paisNascto": "",
                    "paisNac": ""
                },
                "endereco":
                {
                    "brasil":
                    {
                        "tpLograd": "",
                        "dscLograd": "",
                        "nrLograd": "",
                        "complemento": null,
                        "bairro": "",
                        "cep": "",
                        "codMunic": null,
                        "uf": "PA"
                    },
                    "exterior": null
                },
                "trabImig": null,
                "infoDeficiencia":
                {
                    "defFisica": "",
                    "defVisual": "",
                    "defAuditiva": "",
                    "defMental": "",
                    "defIntelectual": "",
                    "reabReadap": "",
                    "infoCota": "",
                    "observacao": null
                },
                "dependente":
                    [],
                "contato": null
            },
            vinculo: {
                "matricula": "",
                "tpRegTrab": 0,
                "tpRegPrev": 0,
                "cadIni": "",
                "infoRegimeTrab":
                {
                    "infoCeletista": null,
                    "infoEstatutario":
                    {
                        "tpProv": 0,
                        "dtExercicio": null,
                        "tpPlanRP": null,
                        "indTetoRGPS": null,
                        "indAbonoPerm": null,
                        "dtIniAbono": null
                    }
                },
                "infoContrato":
                {
                    "nmCargo": null,
                    "dtIngrCargo": null,
                    "nmFuncao": null,
                    "acumCargo": null,
                    "codCateg": 0,
                    "remuneracao":
                    {
                        "vrSalFx": 0,
                        "undSalFixo": 0,
                        "dscSalVar": null
                    },
                    "duracao":
                    {
                        "tpContr": 0,
                        "dtTerm": null,
                        "clauAssec": "",
                        "objDet": null
                    },
                    "localTrabalho":
                    {
                        "localTrabGeral": null,
                        "localTempDom":
                        {
                            "tpLograd": "",
                            "dscLograd": "",
                            "nrLograd": "",
                            "complemento": null,
                            "bairro": "",
                            "cep": "",
                            "codMunic": null,
                            "uf": ""
                        }
                    },
                    "horContratual": null,
                    "alvaraJudicial": null,
                    "observacoes":
                        [],
                    "treiCap":
                        [],
                    "cbocargo": null,
                    "cbofuncao": null
                },
                "sucessaoVinc": null,
                "transfDom": null,
                "mudancaCPF": null,
                "afastamento": null,
                "desligamento": null,
                "cessao": null
            },
            carregando: false,
            previewAdmissao: false
        };
        this.consultaEventos = this.consultaEventos.bind(this);
        this.atualizaTabAtiva = this.atualizaTabAtiva.bind(this);
        this.desabilitaTab = this.desabilitaTab.bind(this);
        this.habilitaId = this.habilitaId.bind(this);
        this.carregaListaErro = this.carregaListaErro.bind(this);
        this.carregaListaPreRequisito = this.carregaListaPreRequisito.bind(this);
    }
    componentWillReceiveProps(props) {
        //console.log(props.eventos);
        this.setState({ idObjeto: props.idObjetos });
    }
    componentDidMount() {
        this.consultaEventos();
        this.carregaListaErro();
        this.carregaListaPreRequisito();
    }
    renderTrabalhador(json) {
        try {
            console.log(json);
            let evento = json.evtAdmissao;
            this.setState({ trabalhador: evento.trabalhador, vinculo: evento.vinculo, ideEmpregador: evento.ideEmpregador, ideEvento: evento.ideEvento });
        } catch (error) {
            console.log(error);
        }

    }
    consultaEventos() {
        $.ajax({
            url: "/EsocialAPI/api/trabalhador/preview/JSON/" + this.props.idEventoFila,
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta);
                this.renderTrabalhador(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    atualizaTrabalhador() {
        this.consultaEventos();
        let estado = this.state.previewAdmissao;
        if (estado) {
            this.setState({ previewAdmissao: false });
        } else {
            this.setState({ previewAdmissao: true });
        }
    }
    atualizaTabAtiva(index) {
        try {
            let alvo = index.idAlvo;
            let tabs = this.state.tabs;
            let tabsApagadas = this.removeItemArray(tabs, alvo);
            this.setState({ activeIndex: index.indice });
            //$(alvo).css("display", "none");
            this.habilitaId(alvo);
            tabsApagadas.map(function (id) {
                this.desabilitaTab(id);
            }.bind(this));
        } catch (erro) {
            console.log(erro)
        }
        //$(alvo).fadeIn();
    }
    renderData(rowData) {
        console.log(rowData);
        let dataCriacao = new Date(rowData.dtNascto);
        console.log(dataCriacao.toLocaleDateString());
        return dataCriacao.toLocaleDateString();
    }
    habilitaId(id) {
        if (id === "tabActive0") {
            this.setState({ tabActive0: 'block' });
        }
        if (id === "tabActive1") {
            this.setState({ tabActive1: 'block' });
        }
        if (id === "tabActive2") {
            this.setState({ tabActive2: 'block' });
        }
        if (id === "tabActive3") {
            this.setState({ tabActive3: 'block' });
        }
    }
    desabilitaTab(id) {
        if (id === "tabActive0") {
            this.setState({ tabActive0: 'none' });
        }
        if (id === "tabActive1") {
            this.setState({ tabActive1: 'none' });
        }
        if (id === "tabActive2") {
            this.setState({ tabActive2: 'none' });
        }
        if (id === "tabActive3") {
            this.setState({ tabActive3: 'none' });
        }
    }
    renderSimNao(field) {
        try {
            if (field === "N") {
                return (<FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />);
            } else {
                return (<FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />);
            }
        } catch (erro) {
            console.log(erro);
        }
    }
    renderTemplateSimNao(rowData) {
        try {
            if (rowData.incTrab === "N") {
                return (<FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />);
            } else {
                return (<FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />);
            }
        } catch (erro) {
            console.log(erro);
        }
    }
    renderSexo(field) {
        try {
            if (field === "M") {
                return (
                    <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon icon={faMale} style={{ color: 'darkgreen' }} />
                        <span> Masculino</span>
                    </div>
                );
            }
            if (field === "F") {
                return (
                    <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon icon={faFemale} style={{ color: 'lightgreen' }} />
                        <span> Feminino</span>
                    </div>
                );
            }
        } catch (erro) {
            console.log(erro);
        }
    }
    removeItemArray(arr, value) {
        return arr.filter(function (ele) {
            return ele != value;
        });
    }
    renderCPF(rowData) {
        const cpf = require('node-cpf');
        return cpf.mask(rowData.cpfDep);

    }
    renderAlvaraJudicial(alvaraJudicial) {
        let retorno = '';
        if (alvaraJudicial !== null) {
            if (alvaraJudicial.length > 0) {
                alvaraJudicial.map(function (nrProcJud) {
                    retorno += (
                        <div>
                            <label>nrProcJud:</label> <label>{nrProcJud}</label>
                        </div>
                    );
                });
            } else {
                retorno = (
                    <label>Nenhum Processo judicial registrado</label>
                );
            }
            return (
                <div>
                    {retorno}
                </div>
            );

        } else {
            return (
                <div>
                    <label>Nenhum Processo judicial registrado</label>
                </div>
            );
        }

    }
    renderObservacoes(observacoes) {
        let retorno = '';
        if (observacoes !== null) {
            if (observacoes.length > 0) {
                observacoes.map(function (observacao) {
                    retorno += (
                        <div>
                            <label>Observacao:</label> <label>{observacao}</label>
                        </div>
                    );
                });
            } else {
                retorno = (
                    <label>Nenhuma observação registrada.</label>
                );
            }
            return (
                <div>
                    {retorno}
                </div>
            );
        } else {
            return (
                <div>
                    <label>Nenhuma observação registrada.</label>
                </div>
            );
        }

    }
    rendertreiCap(treiCap) {
        let retorno = '';
        if (treiCap !== null) {
            if (treiCap.length > 0) {
                treiCap.map(function (codTreiCap) {
                    retorno += (
                        <div>
                            <label>codTreiCap:</label> <label>{codTreiCap}</label>
                        </div>
                    );
                });
            } else {
                retorno = (
                    <label>Nenhum treinamento registrado.</label>
                );
            }
            return (
                <div>
                    {retorno}
                </div>
            );
        } else {
            return (
                <div>
                    <label>Nenhum treinamento registrado.</label>
                </div>
            );
        }
    }
    renderHorarioContratual(horContratual) {
        console.log(horContratual);
        if (horContratual !== null) {
            return (
                <div>
                    <label>qtdHrsSem</label> <label>{this.state.vinculo.infoContrato.horContratual.qtdHrsSem}</label><br />
                    <label>tpJornada</label> <label>{this.state.vinculo.infoContrato.horContratual.tpJornada}</label><br />
                    <label>tmpParc</label><label>{this.state.vinculo.infoContrato.horContratual.tmpParc}</label><br />
                    <label>horNoturno</label><label>{this.state.vinculo.infoContrato.horContratual.horNoturno}</label><br />
                    <label>dscJorn</label><label>{this.state.vinculo.infoContrato.horContratual.dscJorn}</label>
                </div>);
        } else {
            return (
                <div>
                    <em>Nenhum valor declarado em horário contratual</em>
                </div>
            );
        }
    }
    /* 
    
                            <label>desligamento</label><em>{this.state.vinculo.desligamento}</em><br />
                            <label>cessao</label><em>{this.state.vinculo.cessao}</em>
    */
    renderDesligamento(desligamento) {
        try {
            if (desligamento !== null) {
                return (
                    <div>
                        <label>dtDeslig:</label><em>{desligamento.dtDeslig}</em>
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhum desligamento registrado.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderCessao(cessao) {
        try {
            if (cessao !== null) {
                return (
                    <div>
                        <label>dtIniCessao:</label><em>{cessao.dtIniCessao}</em><br />
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhuma cessao registrada.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderSucessao(sucessaoVinc) {
        try {
            if (sucessaoVinc !== null) {
                return (
                    <div>
                        <label>tpInsc:</label><em>{sucessaoVinc.tpInsc}</em><br />
                        <label>nrInsc:</label><em>{sucessaoVinc.nrInsc}</em><br />
                        <label>matricAnt</label><em>{sucessaoVinc.matricAnt}</em><br />
                        <label>dtTransf</label><em>{sucessaoVinc.dtTransf}</em><br />
                        <label>observacao</label><em>{sucessaoVinc.observacao}</em>
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhuma sucessao registrada.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderTransfDom(transfDom) {
        try {
            if (transfDom !== null) {
                return (
                    <div>
                        <label>cpfSubstituido:</label><em>{transfDom.cpfSubstituido}</em><br />
                        <label>matricAnt</label><em>{transfDom.matricAnt}</em><br />
                        <label>dtTransf</label><em>{transfDom.dtTransf}</em><br />
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhuma Transferencia registrada.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderMudancaCPF(mudancaCPF) {
        try {
            if (mudancaCPF !== null) {
                return (
                    <div>
                        <label>cpfAnt:</label><em>{mudancaCPF.cpfAnt}</em><br />
                        <label>matricAnt</label><em>{mudancaCPF.matricAnt}</em><br />
                        <label>dtAltCPF</label><em>{mudancaCPF.dtAltCPF}</em><br />
                        <label>observacao</label><em>{mudancaCPF.observacao}</em>
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhuma mudança de CPF registrada.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderAfastamento(afastamento) {
        try {
            if (afastamento !== null) {
                return (
                    <div>
                        <label>dtIniAfast: </label><em>{afastamento.dtIniAfast}</em><br />
                        <label>codMotAfast: </label><em>{afastamento.codMotAfast}</em>
                    </div>
                );
            } else {
                return (
                    <div>
                        <strong>Nenhum afastamento registrado.</strong>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    renderLocalDeTrabalhoGeral(localTrabGeral) {
        if (localTrabGeral != null && localTrabGeral != undefined) {
            return (
                <div>
                    <label>tpInsc: </label><em>{localTrabGeral.tpInsc}</em><br />
                    <label>nrInsc: </label><em>{localTrabGeral.nrInsc}</em><br />
                    <label>descComp: </label><em>{localTrabGeral.descComp}</em><br />
                </div>

            )
        }else{
            return (
                <div>
                    <p>Nenhum local de trabalho geral registrado.</p>
                </div>
            );
        }
    }
    carregaListaErro() {

        $.ajax({
            url: "/EsocialAPI/api/processamento/valida/" + this.props.idEventoFila,
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            error: function (erro) {
                console.log(erro);
            },
            success: function (resposta) {
                console.log(resposta.erro.length);
                this.setState({ listaErros: resposta.erro, previewErros: 'block', qtdErros: resposta.erro.length });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    carregaListaPreRequisito() {
        $.ajax({
            url: "/EsocialAPI/api/processamento/prerequisito/" + this.props.idEventoFila,
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            error: function (erro) {
                console.log(erro);
            },
            success: function (resposta) {
                console.log(resposta);
                this.setState({ listaPreRequisito: resposta, previewErros: 'block' });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    renderRacaCor(cod) {
        let retorno = null;
        let codigo = parseInt(cod);
        let racas = [
            { codRaca: 1, descricao: "Branca" },
            { codRaca: 2, descricao: "Preta" },
            { codRaca: 3, descricao: "Parda" },
            { codRaca: 4, descricao: "Amarela" },
            { codRaca: 5, descricao: "Indígena" },
            { codRaca: 6, descricao: "Não informado" }
        ];
        racas.map(function (raca) {
            //console.log(raca.codRaca===codigo);
            if (raca.codRaca === codigo) {
                console.log(raca.descricao);
                retorno = (
                    <strong>&nbsp;
                        {raca.descricao}
                    </strong>);
            }
        });
        return retorno;
    }
    renderGrauInstr(cod) {
        let retorno = null;
        let codigo = parseInt(cod);
        let grauInstrs = [
            { codGrauInstr: 1, descGrauInstr: "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou" },
            { codGrauInstr: 2, descGrauInstr: "Até o 5º ano incompleto do ensino fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular" },
            { codGrauInstr: 3, descGrauInstr: "5º ano completo do ensino fundamental" },
            { codGrauInstr: 4, descGrauInstr: "Do 6º ao 9º ano do ensino fundamental incompleto (antiga 5ª a 8ª série)" },
            { codGrauInstr: 5, descGrauInstr: "Ensino fundamental completo" },
            { codGrauInstr: 6, descGrauInstr: "Ensino médio incompleto" },
            { codGrauInstr: 7, descGrauInstr: "Ensino médio completo" },
            { codGrauInstr: 8, descGrauInstr: "Educação superior incompleta" },
            { codGrauInstr: 9, descGrauInstr: "Educação superior completa" },
            { codGrauInstr: 10, descGrauInstr: "Pós-graduação completa" },
            { codGrauInstr: 11, descGrauInstr: "Mestrado completo" },
            { codGrauInstr: 12, descGrauInstr: "Doutorado completo" }
        ];
        grauInstrs.map(function (grauInstr) {
            console.log(grauInstr.codGrauInstr === codigo);
            if (grauInstr.codGrauInstr === codigo) {
                retorno = (
                    <strong>&nbsp;
                        {grauInstr.descGrauInstr}
                    </strong>);
            }
        });
        if (retorno !== null) {
            return retorno;
        } else {
            return (
                <strong>&nbsp;Grau de instrução sem informação registrada</strong>
            );
        }
    }
    renderTrabTemporario() {
        if (this.state.vinculo.infoRegimeTrab.infoCeletista.trabTemporario !== null) {
            return (
                <div>
                    <h5>ideEstabVinc</h5>
                    <label>tpInsc</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista.trabTemporario.ideEstabVinc.tpInsc}</em><br />
                    <label>nrInsc</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista.trabTemporario.ideEstabVinc.nrInsc}</em>
                </div>
            );
        } else {
            return (
                <div>
                    <em>Nenhuma informação referente a trabalhador temporário registradas. </em>
                </div>
            );
        }
    }
    renderTrabSubstituido() {
        try {
            if (this.state.vinculo.infoRegimeTrab.infoCeletista.trabTemporario.ideTrabSubstituido !== null) {
                return (
                    <div>
                        <h5>ideTrabSubstituido</h5>
                        <label>cpfTrabSubst</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista.trabTemporario.ideTrabSubstituido.cpfTrabSubst}</em>
                    </div>
                );
            } else {
                return (
                    <div>
                        <em>Nenhuma informação referente a trabalhador temporário registradas. </em>
                    </div>
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    renderInfoRegimeTrab() {
        let retorno = null;
        try {
            let infoCeletista = (
                <div>
                    <h5>infoCeletista</h5>
                    <div style={{ paddingRight: '20px' }}>
                        <label>dtAdm</label><em>{new Date(this.state.vinculo.infoRegimeTrab.infoCeletista).toLocaleDateString()}</em><br />
                        <label>tpAdmissao</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>indAdmissao</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>nrProcTrab</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>tpRegJor</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>natAtividade</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>dtBase</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                        <label>cnpjSindCategProf</label><em>{this.state.vinculo.infoRegimeTrab.cnpjSindCategProf}</em><br />
                        <div style={{ paddingRight: '20px' }}>
                            <h5>FGTS</h5>
                            <label>dtOpcFGTS</label>
                        </div>
                        <div style={{ paddingRight: '20px' }}>
                            <h5>trabTemporario</h5>
                            <label>hipLeg</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em><br />
                            <label>justContr</label><em>{this.state.vinculo.infoRegimeTrab.infoCeletista}</em>
                            {this.renderTrabTemporario}
                            {this.renderTrabSubstituido}
                        </div>
                    </div>
                </div>
            );
            let infoEstatutario = (
                <div>
                    <h5>infoEstatutario</h5>
                    <div style={{ paddingRight: '20px' }}>
                        <label>Tipo Prov: </label><em>{this.state.vinculo.infoRegimeTrab.infoEstatutario.tpProv}</em><br />
                        <label>Data Exercicio: </label><em>{new Date(this.state.vinculo.infoRegimeTrab.infoEstatutario.dtExercicio).toLocaleDateString()}</em><br />
                        <label>Tipo PlanRP: </label><em>{this.state.vinculo.infoRegimeTrab.infoEstatutario.tpPlanRP}</em><br />
                        <label>indTetoRGPS: </label><em>{this.state.vinculo.infoRegimeTrab.infoEstatutario.indTetoRGPS}</em><br />
                        <label>indAbonoPerm: </label><em>{this.state.vinculo.infoRegimeTrab.infoEstatutario.indAbonoPerm}</em><br />
                        <label>dtIniAbono: </label><em>{this.state.vinculo.infoRegimeTrab.infoEstatutario.dtIniAbono}</em>
                    </div>
                </div>
            );
            console.log(this.state.vinculo.infoRegimeTrab.infoCeletista);
            if (this.state.vinculo.infoRegimeTrab.infoCeletista !== null) {
                retorno = (
                    <div>
                        {infoCeletista}
                    </div>
                );
            } else {
                retorno = (
                    <div>
                        {infoEstatutario}
                    </div>
                );
            }
            return retorno;
        } catch (error) {
            console.log(error);
        }


    }
    renderEstCivil(cod) {
        let retorno = null;
        let codigo = parseInt(cod);
        let estCivils = [
            { codEstCivil: 1, descEstCivil: "Solteiro" },
            { codEstCivil: 2, descEstCivil: "Casado" },
            { codEstCivil: 3, descEstCivil: "Divorciado" },
            { codEstCivil: 4, descEstCivil: "Separado" },
            { codEstCivil: 5, descEstCivil: "Viúvo" }
        ];
        estCivils.map(function (estCivil) {
            console.log(estCivil.codEstCivil === codigo);
            if (estCivil.codEstCivil === codigo) {
                retorno = (
                    <strong>&nbsp;
                        {estCivil.descEstCivil}
                    </strong>);
            }
        });
        return retorno;
    }
    renderTipoDependente() {
        let tipoDependentes = [
            { codTipoDependente: 1, descTipoDependente: 'Cônjuge' },
            { codTipoDependente: 2, descTipoDependente: 'Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável' },
            { codTipoDependente: 3, descTipoDependente: 'Filho(a) ou enteado(a)' },
            { codTipoDependente: 4, descTipoDependente: 'Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau' },
            { codTipoDependente: 6, descTipoDependente: 'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial' },
            { codTipoDependente: 7, descTipoDependente: 'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do(a) qual detenha a guarda judicial' },
            { codTipoDependente: 9, descTipoDependente: 'Pais, avós e bisavós' },
            { codTipoDependente: 10, descTipoDependente: 'Menor pobre do qual detenha a guarda judicial' },
            { codTipoDependente: 11, descTipoDependente: 'A pessoa absolutamente incapaz, da qual seja tutor ou curador' },
            { codTipoDependente: 12, descTipoDependente: 'Ex-cônjuge' },
            { codTipoDependente: 99, descTipoDependente: 'Agregado/Outros' }
        ]
    }

    renderEndereco(endereco) {
        if (endereco.brasil != null && endereco.brasil != undefined) {
            return (
                <div style={{ textAlign: 'left' }}>
                    <label>Bairro: </label>{endereco.brasil.bairro}<br />
                    <label>CEP: </label>{endereco.brasil.cep}<br />
                    <label>CodMunic: </label>{endereco.brasil.codMunic}<br />
                    <label>DscLograd: </label>{endereco.brasil.dscLograd}<br />
                    <label>NrLograd: </label>{endereco.brasil.nrLograd}<br />
                    <label>TpLograd: </label>{endereco.brasil.tpLograd}<br />
                    <label>Uf: </label>{endereco.brasil.uf}
                </div>
            )
        } else if (endereco.exterior != null && endereco.exterior != undefined) {
            return (
                <div style={{ textAlign: 'left' }}>
                    <label>paisResid: </label>{endereco.exterior.paisResid}<br />
                    <label>dscLograd: </label>{endereco.exterior.dscLograd}<br />
                    <label>nrLograd: </label>{endereco.exterior.nrLograd}<br />
                    <label>complemento: </label>{endereco.exterior.complemento}<br />
                    <label>bairro: </label>{endereco.exterior.bairro}<br />
                    <label>nmCid: </label>{endereco.exterior.nmCid}<br />
                    <label>codPostal: </label>{endereco.exterior.codPostal}
                </div>
            )

        }
    }
    render() {
        const cpf = require('node-cpf');
        const items = [
            { label: 'IdeEmpregador', icon: 'pi pi-fw pi-home', idAlvo: 'tabActive0', indice: 0 },
            { label: 'IdeEvento', icon: 'pi pi-fw pi-calendar', idAlvo: 'tabActive1', indice: 1 },
            { label: 'Trabalhador', icon: 'pi pi-fw pi-pencil', idAlvo: 'tabActive2', indice: 2 },
            { label: 'Vinculo', icon: 'pi pi-fw pi-file', idAlvo: 'tabActive3', indice: 3 }
        ];
        return (
            <div>
                <Button onClick={(e) => this.atualizaTrabalhador()} >
                    <FontAwesomeIcon icon={faIdCard} />
                </Button>
                <Dialog header="Trabalhador" visible={this.state.previewAdmissao} style={{ width: '50vw' }} onHide={(e) => this.atualizaTrabalhador()}>
                    <div style={{ display: this.state.previewErros }}>
                        <Panel header={this.state.qtdErros + " - Inconsistencias"} toggleable collapsed={this.state.panelErros} onToggle={(e) => this.setState({ panelErros: e.value })}>
                            <ListaErros
                                listaPreRequisito={this.state.listaPreRequisito}
                                listaErros={this.state.listaErros}
                            />
                        </Panel>
                    </div>
                    <TabMenu model={items} activeIndex={this.state.activeIndex} onTabChange={(e) => this.atualizaTabAtiva(e.value)} />
                    <Card id="tab0" title="IdeEmpregador" subTitle="Dados do empregador" style={{ display: this.state.tabActive0 }} >
                        <div>
                            <ol>
                                <li style={{ listStyle: 'none' }}><label>nrInsc: </label><em>{this.state.ideEmpregador.nrInsc}</em></li>
                                <li style={{ listStyle: 'none' }}><label>tpInsc: </label><em>{this.state.ideEmpregador.tpInsc}</em></li>
                            </ol>
                        </div>
                    </Card>
                    <Card id="tab1" title="IdeEvento" subTitle="Dados do evento" style={{ display: this.state.tabActive1 }} >
                        <div>
                            <ol>
                                <li style={{ listStyle: 'none' }}><label>indRetif: </label><em>{this.state.ideEvento.indRetif}</em></li>
                                <li style={{ listStyle: 'none' }}><label>nrRecibo: </label><em>{this.state.ideEvento.nrRecibo}</em></li>
                                <li style={{ listStyle: 'none' }}><label>procEmi: </label><em>{this.state.ideEvento.procEmi}</em></li>
                                <li style={{ listStyle: 'none' }}><label>tpAmb: </label><em>{this.state.ideEvento.tpAmb}</em></li>
                                <li style={{ listStyle: 'none' }}><label>verProc: </label><em>{this.state.ideEvento.verProc}</em></li>
                            </ol>
                        </div>
                    </Card>
                    <Card id="tab2" title="Trabalhador" subTitle="Dados do Trabalhador" style={{ display: this.state.tabActive2 }} >
                        <div style={{ paddingLeft: '25px' }}>
                            <label>Nome Trab:</label><strong>{this.state.trabalhador.nmTrab}</strong><br />
                            <label>CPF:</label><strong>{cpf.mask(this.state.trabalhador.cpfTrab)}</strong><br />
                            <label>Sexo:</label><strong style={{ display: 'inline-block' }} >{this.renderSexo(this.state.trabalhador.sexo)}</strong><br />
                            <label>RacaCor:</label><label>{this.renderRacaCor(this.state.trabalhador.racaCor)}</label><br />
                            <label>EstCiv:</label><label>{this.renderEstCivil(this.state.trabalhador.estCiv)}</label><br />
                            <label>GrauInstr:</label><label>{this.renderGrauInstr(this.state.trabalhador.grauInstr)}</label><br />
                            <label>NmSoc:</label><label>{this.state.trabalhador.nmSoc}</label>
                        </div>
                        <Panel header="Detalhes" className="panelPreview">
                            <div className="pure-g">
                                <div className="pure-u-1-3" style={{ paddingRight: '12px', textAlign: 'center' }}>
                                    <div>
                                        <FontAwesomeIcon icon={faAddressCard} style={{ color: 'darkgreen', marginRight: '20px' }} />
                                        <strong>Nascimento</strong>
                                    </div>
                                    <hr />
                                    <div style={{ textAlign: 'left' }}>
                                        <label>dtNascto:</label><label>{new Date(this.state.trabalhador.nascimento.dtNascto).toLocaleDateString()}</label><br />
                                        <label>paisNascto:</label><label>{this.state.trabalhador.nascimento.paisNascto}</label><br />
                                        <label>paisNac:</label><label>{this.state.trabalhador.nascimento.paisNac}</label>
                                    </div>
                                </div>
                                <div className="pure-u-1-3"
                                    style={{ borderLeft: '3px solid #ccc', borderRight: '3px solid #ccc', textAlign: 'center', paddingLeft: '12px', paddingRight: '12px' }}>
                                    <div>
                                        <FontAwesomeIcon icon={faIdCard} style={{ color: 'darkgreen', marginRight: '20px' }} />
                                        <strong>Endereço</strong>
                                    </div>
                                    <hr />
                                    {this.renderEndereco(this.state.trabalhador.endereco)}
                                </div>
                                <div className="pure-u-1-3" style={{ paddingLeft: '12px', textAlign: 'center' }}>
                                    <div>
                                        <FontAwesomeIcon icon={faWheelchair} style={{ color: 'darkgreen', marginRight: '20px' }} />
                                        <strong>InfoDeficiencia</strong>
                                    </div>
                                    <hr />
                                    <div style={{ textAlign: 'left' }}>
                                        <label>DefFisica: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.defFisica)}</label><br />
                                        <label>DefVisual: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.defVisual)}</label><br />
                                        <label>DefAuditiva: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.defAuditiva)}</label><br />
                                        <label>DefMental: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.defMental)}</label><br />
                                        <label>DefIntelectual: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.defIntelectual)}</label><br />
                                        <label>ReabReadap: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.reabReadap)}</label><br />
                                        <label>InfoCota: </label><label>{this.renderSimNao(this.state.trabalhador.infoDeficiencia.infoCota)}</label><br />
                                        <label>Observacao: </label><label> null</label>
                                    </div>
                                </div>

                                <br />

                            </div>
                            <div className="pure-g">
                                <div className="pure-u-5-5">
                                    <label>trabImig</label><label> null</label>
                                </div>
                            </div>
                        </Panel>
                        <br />
                        <Panel header="Dependentes" className="panelPreview">
                            <div>
                                <h5>Dependentes</h5>
                                <DataTable value={this.state.trabalhador.dependente} emptyMessage="Nenhum dependente encontrado para o Servidor" className="p-datatable-sm" >
                                    <Column field="nmDep" header="Nome" />
                                    <Column body={this.renderTemplateSimNao} header="IncTrab" style={{ width: '5em', textAlign: 'center' }} />
                                    <Column header="Data Nascimento" body={this.renderData} />
                                    <Column body={this.renderCPF} header="CPF" style={{ textAlign: 'center' }} />
                                    <Column field="depIrrf" header="DepIRRF" />
                                </DataTable>
                            </div>
                        </Panel>
                    </Card>
                    <Card id="tab3" title="Vinculo" subTitle="Dados do vinculo" style={{ display: this.state.tabActive3, fontSize: '14px' }} >
                        <div style={{ borderTop: '1px solid #ccc', padding: '10px 10px 10px 10px' }} >
                            <label>Matricula:</label><em>{this.state.vinculo.matricula}</em><br />
                            <label>tpRegTrab:</label><em>{this.state.vinculo.tpRegTrab}</em><br />
                            <label>tpRegPrev:</label><em>{this.state.vinculo.tpRegPrev}</em><br />
                            <label>CadIni:</label><em>{this.state.vinculo.cadIni}</em>
                        </div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '25px', padding: '10px 10px 10px 10px' }}>
                            <Panel header="InfoRegimeTrab">
                                {this.renderInfoRegimeTrab()}

                            </Panel>
                        </div>
                        <div style={{ borderTop: '1px solid #ccc', marginTop: '25px', padding: '10px 10px 10px 10px' }}>
                            <h5>infoContrato</h5>
                            <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '15px' }}>
                                <label>nmCargo</label> <label>{this.state.vinculo.infoContrato.nmCargo}</label><br />
                                <label>dtIngrCargo</label> <label>{new Date(this.state.vinculo.infoContrato.dtIngrCargo).toLocaleDateString}</label><br />
                                <label>nmFuncao</label> <label>{this.state.vinculo.infoContrato.nmFuncao}</label><br />
                                <label>acumCargo</label> <label>{this.state.vinculo.infoContrato.acumCargo}</label><br />
                                <label>codCateg</label> <label>{this.state.vinculo.infoContrato.codCateg}</label><br />
                                <Panel header="Horário Contratual" className="panelPreview">
                                    {this.renderHorarioContratual(this.state.vinculo.infoContrato.horContratual)}

                                </Panel>
                                <br />
                                <Panel header="Remuneração" className="panelPreview">
                                    <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '15px' }}>
                                        <strong>Remuneração: </strong><br />
                                        {/* <label></label>vrSalFx: <em>{this.state.vinculo.infoContrato.remuneracao.vrSalFx.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</em><br /> */}
                                        <label></label>undSalFixo: <em> {this.state.vinculo.infoContrato.remuneracao != null ? this.state.vinculo.infoContrato.remuneracao.undSalFixo : ''}</em><br />
                                        <label></label>dscSalVar: <em> {this.state.vinculo.infoContrato.remuneracao != null ? this.state.vinculo.infoContrato.remuneracao.dscSalVar : ''}</em>
                                    </div>
                                </Panel>
                                <br />
                                <Panel header="Duração" className="panelPreview">
                                    <label>tpContr: </label><label> {this.state.vinculo.infoContrato.duracao != null ? this.state.vinculo.infoContrato.duracao.tpContr : ''}</label><br />
                                    <label>dtTerm: </label><label> {this.state.vinculo.infoContrato.duracao != null ? new Date(this.state.vinculo.infoContrato.duracao.dtTerm).toLocaleDateString : ''}</label><br />
                                    <label>clauAssec: </label><label>{this.state.vinculo.infoContrato.duracao != null ? this.state.vinculo.infoContrato.duracao.clauAssec : '' }</label><br />
                                    <label>objDet: </label><label> {this.state.vinculo.infoContrato.duracao != null ? this.state.vinculo.infoContrato.duracao.objDet : ''}</label>
                                </Panel>
                                <br />
                                <Panel header="Local de Trabalho" className="panelPreview">
                                    {this.renderLocalDeTrabalhoGeral(this.state.vinculo.infoContrato.localTrabalho.localTrabGeral)}
                                </Panel>
                                <br />
                                <Panel header="Alvará Judicial" className="panelPreview">
                                    {this.renderAlvaraJudicial(this.state.vinculo.infoContrato.alvaraJudicial)}
                                </Panel>
                                <br />
                                <Panel header="Observações" className="panelPreview">
                                    {this.renderObservacoes(this.state.vinculo.infoContrato.observacoes)}
                                </Panel>
                                <br />
                                <Panel header="Treinamentos, capacitações, exercícios simulados, autorizações ou outras anotações" className="panelPreview">
                                    {this.rendertreiCap(this.state.vinculo.infoContrato.treiCap)}
                                </Panel>
                            </div>

                        </div>

                        <div style={{ borderTop: '1px solid #ccc', marginTop: '25px', padding: '10px 10px 10px 10px' }}>
                            {this.renderSucessao(this.state.vinculo.sucessaoVinc)}
                            {this.renderTransfDom(this.state.vinculo.transfDom)}
                            {this.renderMudancaCPF(this.state.vinculo.mudancaCPF)}
                            {this.renderAfastamento(this.state.vinculo.afastamento)}
                            {this.renderCessao(this.state.vinculo.cessao)}
                            {this.renderDesligamento(this.state.vinculo.desligamento)}
                        </div>
                    </Card>
                </Dialog>
            </div>
        );
    }
}



import { Button } from 'primereact/button';
import React, { Component, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";


const BtnExportCsv = (props) =>{

    const csvLink = useRef();

    const data = props.data;

    const headers = props.header;
    
    const csvReport = {
        filename: props.fileName,
        headers: headers,
        data: data
    }

    function handleButtonClick(){
        csvLink.current.link.click();
    }

    return (
        <div>
            <Button onClick={handleButtonClick} label='CSV' icon="pi pi-file-excel" className="p-button-sm p-button-info" style={{marginBottom: '10px', marginRight: '10px'}}>
                <CSVLink target='_blank' ref={csvLink} className="p-button-sm" style={{visibility:'hidden' }} {...csvReport}>CSV</CSVLink>
            </Button>
        </div>
    )
}

export default BtnExportCsv
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import $ from 'jquery';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import AceEditor from 'react-ace-builds';
import XMLViewer from 'react-xml-viewer';

export class TabTotalizador extends Component {
    constructor() {
        super();
        this.state = {
            idEventoFila: 0,
            bolAtivo: false,
            activeIndex: 0,
            evtValido: false,
            valueTotalizador: '',
            objDetalhe: '',
            totalizadores: [],
            visiblePreview: false,
            totalizadorSelecionado: {tipoTotalizador: '', nomeMatricula: '', xmlEvento: ''}
            , loading: false
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.handleButtonTotalizadorClick = this.handleButtonTotalizadorClick.bind(this);
    }

    renderDropDown(value, options, onChangeFunction, placeholder) {
        return (
            <Dropdown
                value={value}
                options={options}
                style={{ width: '70%' }}
                onChange={(e) => { onChangeFunction(e.value) }}
                placeholder={placeholder}
            />
        );
    }

    buscaTotalizadores(mes, ano, isAnual, codTot, objDetalhe) {
        console.log(objDetalhe);
        const dados = {
            tipoTotalizador: codTot,
            anoApuracao: ano,
            mesApuracao: mes,
            isAnual: isAnual,
            detalhe: objDetalhe
        }
        this.setState({ loading: true });
        $.ajax({
            url: "/EsocialAPI/totalizador/",
            type: 'post',
            dataType: 'json',
            data: JSON.stringify(dados),
            contentType: 'application/json',
            success: function (resposta) {
                console.log(resposta)
                this.setState({ loading: false, totalizadores: resposta });
            }.bind(this),
            error: function (resposta) {
                this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    actionTemplate(rowData, column) {
        console.log(rowData);
        return (
            <div>
                <Button
                    type="button"
                    icon="pi pi-file-excel"
                    className="p-button-info"
                    onClick={() => this.handleButtonTotalizadorClick(rowData)}
                    tooltip="XML Totalizador"
                    tooltipOptions={{ position: 'right' }}
                />

            </div>
        )

    }

    handleButtonTotalizadorClick(rowData){

        const totalizadorSelecionado = {tipoTotalizador: rowData['tipoTotalizador'], nomeMatricula:  rowData['nomeMatricula'], xmlEvento: rowData['xmlEvento']}

        this.setState({totalizadorSelecionado: totalizadorSelecionado});
        this.setState({ visiblePreview: true });
    }

    render() {

        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        }

        const optionsTotalizadores = [
            { label: 'S-5001 - Informações das Contribuições Sociais por Trabalhador', value: 'S-5001' },
            { label: 'S-5002 - Imposto de Renda Retido na Fonte por Trabalhador', value: 'S-5002' },
            { label: 'S-5003 - Informações do FGTS por Trabalhador', value: 'S-5003' },
            { label: 'S-5011 - Informações das Contribuições Sociais Consolidadas por Contribuinte', value: 'S-5011' },
            { label: 'S-5012 - Imposto de Renda Retido na Fonte Consolidado por Contribuinte', value: 'S-5012' },
            { label: 'S-5013 - Informações do FGTS Consolidadas por Contribuinte', value: 'S-5013' }
        ]
        return (
            <div>
                <h3>Totalizadores</h3>
                <Panel header="Filtro" style={{ marginBottom: '10px' }}>
                    <div className="pure-g">

                        <div className="pure-u-1-3">
                            <label style={style.label}>Tipo de Totalizador: </label>
                            <Dropdown
                                value={this.state.valueTotalizador}
                                options={optionsTotalizadores}
                                style={{ width: '70%' }}
                                onChange={(e) => { this.setState({ valueTotalizador: e.value }) }}
                                placeholder={'Selecione o Tipo de Totalizador'}
                            />
                        </div>

                        <div className="pure-u-1-3">
                            <label style={style.label}>Detalhe: </label>
                            <InputText
                                id="in"
                                value={this.state.objDetalhe}
                                onChange={(e) => this.setState({ objDetalhe: e.target.value })}
                                style={style.inputText}
                            />
                        </div>



                    </div>

                    <div className="pure-u-3-3" style={{ textAlign: 'right' }}>
                        <Button icon="pi pi-refresh" style={{ margin: '4px' }} onClick={() => this.buscaTotalizadores(this.props.mesApuracao, this.props.anoApuracao, this.props.isAnual, this.state.valueTotalizador, this.state.objDetalhe)} tooltip="Filtrar" tooltipOptions={{ position: 'right' }} label="Filtrar" />
                    </div>
                    <Dialog
                        header={`XML ${this.state.totalizadorSelecionado.tipoTotalizador} - ${this.state.totalizadorSelecionado.nomeMatricula}`}
                        visible={this.state.visiblePreview}
                        onHide={() => this.setState({ visiblePreview: false })}
                        style={{ width: '50vw' }}

                    >

                        <XMLViewer xml={this.state.totalizadorSelecionado.xmlEvento} />
                    </Dialog>
                </Panel>
                <div>
                    <DataTable
                    value={this.state.totalizadores}
                    emptyMessage="Nenhum totalizador registrado para a folha de pagamento."
                    loading={this.state.loading}
                    >
                        <Column field="idTotalizador" header="#" style={{ width: '5em' }}></Column>
                        <Column field="tipoTotalizador" header="Codigo totalizador" style={{ width: '10em' }}></Column>
                        <Column field="idEventoFila" header="ID Evento Gerador" style={{ width: '10em' }}></Column>
                        <Column field="codEvento" header="Evento Gerador" style={{ width: '10em' }}></Column>
                        <Column field="nomeMatricula" header="Detalhe" style={{ width: '24em' }}></Column>
                        <Column field="xmlEvento" body={this.actionTemplate} header="Ação" style={{ textAlign: 'center', width: '12em' }}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { MenuBar } from '../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { PermissionCheck } from '../componentes/PermissionCheck';
import $ from 'jquery';
import { ProcessamentoFila } from './Processamento/ProcessamentoFila.js';

export class Processamento extends Component {
  constructor() {
    super();
    this.state = {
      lista: []
      , home: { icon: 'pi pi-home' }
      , agendamentos: []
      , activeItem: 0
      , tipoEvento: "tabela"
      , subMenu: [{ label: 'Novo', icon: 'pi pi-fw pi-plus' }, { label: 'Editar', icon: 'pi pi-fw pi-pencil' }, { label: 'Apagar', icon: 'pi pi-fw pi-trash' }]
      , usuario: {}
      , qtdeMensagens: ''
    };
  }
  componentDidMount() {
    this.consultaUsuario();
  }
  componentWillMount() {
    let aba = parseInt(this.props.match.params.id);
    this.setState({ activeItem: aba });
  }
  consultaUsuario() {
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url: "/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error: function () {
        window.location.href = baseurl;
      },
      success: function (resposta) {
        console.log(resposta);
        this.setState({ usuario: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  render() {

    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Processamento', icon: 'pi pi-home', url: '/home' },
      { label: 'Fila', icon: 'pi pi-home', url: '/processamento/fila' }
    ];
    return (

      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content" >
          <div className="content align-center">
            <BreadCrumb home="principal" model={items} />
            <div className="pure-u-5-5">
                <ProcessamentoFila />
            </div>
          </div>
        </div>
        <div className="footer l-box">
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Messages } from 'primereact/messages';
export class WizardConfirmacao extends Component{
    constructor(){
      super();
      this.state={display:'none',qtdServidores:0,qtdEventos:0,mesesSlt:[],confirmacao:'block', execucao:'none',load:'block',frmGeraEventos:'none'};
    }
    componentDidMount(){  
        //this.setState({qtdServidores:this.props.qtd});
    }
    mostraSelecao(event){
      console.log(event.value);
      this.setState({sessao: event.value});
    }
    construirMenu(){
      $.ajax({      
        url:"/EsocialAPI/sessao/arvore/"+this.state.usuario.login,
        dataType: 'json',
        success:function(retorno){
          console.log(retorno);
          this.setState({items : retorno});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    desabilitaLoad(){
      this.setState({load:'none'});
    }

    novaFolhaPagamento(){
      //meses={this.state.mesesSlt} responsavel={this.state.servidor} indApuracao={this.state.indApuracao} anoApuracao={this.state.anoApur.getFullYear()}
      this.setState({execucao: 'block',confirmacao:'none'});
      console.log(this.props.perApur.getMonth())
      var dados=JSON.stringify({
        mes:(this.props.perApur.getMonth()+1).toString(),
        responsavelTecnico:this.props.responsavel,
        indApuracao:this.props.indApuracao,
        ano:this.props.anoApuracao
      });

      $.ajax({      
        url:"/EsocialAPI/folha/novo/",
        data: dados,
        method:'post',
        contentType: "application/json",
        success:function(retorno){
          console.log(retorno);
          this.showMessage(retorno.mensagem);
          this.calculaQtdEventos(retorno.servidores);
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    iniciarPreviewServidores(mes){
      $.ajax({      
        url:"/EsocialAPI/folha/previewservidores/"+this.props.anoApuracao+"/"+mes ,
        dataType: 'json',
        success:function(retorno){
          console.log(retorno);
          //this.setState({items : retorno});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    showMessage(message) {
      this.messages.show(message);
      this.desabilitaLoad();
    }
    showMeses(){
      var meses = this.props.meses;
      return (<div>
        {meses.map((mes, index) => (
            <label key={index} style={{marginLeft:'15px'}}>{mes} </label>
        ))}
        </div>);
    }
    calculaQtdEventos(servidores){
        try{
          let retorno = servidores*2;
          retorno = retorno+2;
          this.setState({qtdServidores:servidores,qtdEventos:retorno});
          return retorno;
        }catch(err){
          console.log('Desculpe houve um erro ao calcular!');
        }
    }
    
    render() {
      return(
        <div>
            <h2>Passo 3: Confirmação.</h2>
            <Messages ref={(el) => this.messages = el}></Messages>
            <div className="p-grid" style={{display: this.state.confirmacao}}>
              <div className="p-col-12" >
                <em>Deseja iniciar a criação dos eventos de folha de pagamento?</em><br />
                <Button label="Sim" icon="pi pi-check" style={{marginRight:'18px'}} onClick={(e) => this.novaFolhaPagamento()}/>
                <Button label="Não" className="p-button-danger" icon="pi pi-times-circle" />
              </div>
            </div>
            <div className="pure-g" style={{display: this.state.execucao}}>
                <div className="pure-u-1-3" >
                    <div>
                    <ul style={{listStyle:'none'}}>
                        <li>Folhas de pagamento: <strong>{this.showMeses()}</strong></li>
                        <li>Quantidade de eventos a serem gerados: <strong><ProgressSpinner style={{height:'18px',display:this.state.load}}/></strong></li>
                        <li>Evento de fechamento parcial.<em>(1298) :<ProgressSpinner style={{height:'18px',display:this.state.load}}/></em><strong></strong></li>
                        <li>Evento de fechamento. <ProgressSpinner style={{height:'18px',display:this.state.load}}/><strong></strong></li>
                    </ul>

                    </div>
                </div>
                <div className="pure-u-2-3" style={{borderLeft:'3px solid #ccc',paddingLeft:'12px'}}>
                    <p>Existem <strong>{this.state.qtdServidores}</strong> servidores cadastrados na base no período selecionado.</p>
                    <p>Os eventos de folha são gerados com base no regime previdenciário RGPS(1200), RPPS(1202) e os eventos de remuneração(1210).</p>
                    <p>O valor aproximado de eventos a serem gerados é: <strong>{this.state.qtdEventos}</strong> por folha de pagamento.</p>
                    <div style={{display:this.state.frmGeraEventos}}>
                      <p>
                        <em>Deseja gerar os eventos para a folha referida? </em>
                      </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
}
import React, {Component} from 'react';
import $ from 'jquery';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FormEvtPeriodico} from '../Importacao/FormEvtPeriodico';
import {ImportacaoEvento} from '../Importacao/ImportacaoEvento';

export class TblImportacao extends Component{
  constructor(){
    super();

    this.state={lista : [],eventos:[],usuario:{},xmljson:"",visiblePreview:false,sendPreview:false,xml:"ei",idEventoFila:0,ocorrencias:[]
    ,idGrupo:0}
  }
  componentWillReceiveProps(props) {
    this.setState({idGrupo: props.idGrupo});
  }
  componentWillMount(){
    this.atualizaGrid();
  }
  atualizaGrid(){
    $.ajax({
      url:"/EsocialAPI/api/importacao/listar/"+this.props.idGrupo,
      dataType: 'json',
      success:function(resposta){
        const eventosFiltrados = resposta.eventos.filter(evento =>evento.hasCargaInicial);
        console.log(resposta.eventos);
        this.setState({eventos:eventosFiltrados});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  displayValueTemplate(value) {
    return (
        <React.Fragment>
            {value}/<b>100</b>
        </React.Fragment>
    );
  }
  handleClick(campo){
     $("#"+campo).css("display","block");
  }
  rowExpansionTemplate(data) {

    if(this.props.idGrupo==="1"){
        return (
          <ImportacaoEvento idEvento={data.idEventoEsocial} idGrupo={1}/>
        );
    }
    if(this.props.idGrupo==="2"){
        return (
          <ImportacaoEvento idEvento={data.idEventoEsocial} idGrupo={2}/>
        );
    }
    if(this.props.idGrupo==="3"){
        return  (
          <ImportacaoEvento idEvento={data.idEventoEsocial} idGrupo={3} idFolha={this.props.idFolha}/>
        );
        }
}
  enviarEvento(){
    this.setState({activeIndex:3});
    $.ajax({
        type:"POST",
        data:{usuario:'Abel','idEventoFila[]':this.props.idEventoFila},
        url:"/EsocialMensageria/EnviarOperacaoEsocial/",
        dataType: 'text',
        success:function(resposta){
          $('#pgrEnviar').css("display", "none");
          this.setState({selecionados:[]});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
  render(){
    return(
      <div>

        <DataTable value={this.state.eventos} 
          expandedRows={this.state.expandedRows} 
          onRowToggle={(e) => this.setState({expandedRows:e.data})}     
          rowExpansionTemplate={this.rowExpansionTemplate.bind(this)} 
          dataKey="idEventoEsocial">
              <Column expander={true} style={{width: '4em'}} />
              <Column field="idEventoEsocial" header="id" />
              <Column field="nome" header="Nome" />
              <Column field="codigo" header="Evento" />
              <Column field="versaoCatalogo" header="Catalogo" />
          </DataTable>
      </div>
    );
  }
}
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';

const TblEventosRejeitados = (props) => {

    return (
        <div className="pure-u-5-5" style={{ marginBottom: '30px' }}>
            <DataTable value={props.eventos}  style={{ marginBottom: '30px' }} emptyMessage="Nenhuma inconsistencia encontrada" paginator={true} rows={50} >
                <Column field="idEventoFila" header="Id" style={{ width: '5%' }} />
                <Column field="codigoEvento" header="Cod. Evento" style={{ width: '8%' }} />
                <Column field="periodo" header="Período" style={{ width: '8%' }} />
                <Column field="indApuracao" header="Ind. Apuração" style={{ width: '8%' }} />
                <Column field="objDetalhe" header="Detalhe" style={{ width: '20%' }} />
                <Column field="ocorrencia" header="Ocorrência" />
                {/* <Column body={this.retornaBtnRelatorio} header="Rejeições" style={{ width: '10%' }} /> */}
            </DataTable>
        </div>
    )
}

export default TblEventosRejeitados;
import React, { useState, useEffect, useRef } from 'react';
import { MenuBar } from '../../../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import AceEditor from "react-ace";
import {Messages} from 'primereact/messages';
import sqlFormatter from "sql-formatter";
import $ from 'jquery';
import 'brace/mode/sql';


const EditorQuery = ()=>{
    //Equivalente ao this.state do construtor do component com Classe - https://pt-br.reactjs.org/docs/hooks-state.html
    const [tiposEventoFiltro, setTiposEventoFiltro] = useState([]);
    const [tipoEvento, setTipoEvento] = useState();
    const [eventosFiltro, setEventosFiltro] = useState();
    const [eventoSelected, setEventoSelected] = useState();
    const [consultasEventoTree, setConsultasEventoTree] = useState();
    const [consultaSelected, setConsultaSelected] = useState();
    const [sqlConsultaSelected, setSqlConsultaSelected] = useState();
    const [usuario, setUsuario] = useState({});

    let messages = useRef(null);

    useEffect(()=>{
        $.ajax({
            url:"/EsocialAPI/tipoevento/lista",
            dataType: 'json',
            success:function(resposta){
              console.log(resposta);
              const tiposEvento = resposta.map((obj)=>{
                return {label: obj.nomeTipo, value: obj.idTipoEvento}
              });
              setTiposEventoFiltro(tiposEvento);
            },
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
          });
        }, []);
    useEffect(()=>{
            var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
            console.log(baseurl);
            $.ajax({
              url:"/EsocialAPI/usuario/logado/",
              dataType: 'json',
              error:function(){
                window.location.href= baseurl;
              },
              success:function(resposta){
                setUsuario(resposta);
              }.bind(this),
              beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
            });
    },[])
    useEffect(()=>{
        console.log(tipoEvento);
        if(tipoEvento!==undefined){
            $.ajax({
                url:`/EsocialAPI/evento/lista/tipo/${tipoEvento}`,
                dataType: 'json',
                success:function(resposta){
                  console.log(resposta);
                  const eventos = resposta.map((obj)=>{
                    return {label: `S-${obj.codigo} - ${obj.nome}`, value: obj.idEventoEsocial}
                  });
                  setEventosFiltro(eventos);
                },
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
              });
        }
        
    }, [tipoEvento]);

    useEffect(()=>{
        if(eventoSelected!==null && eventoSelected!==undefined){
            $.ajax({
                url:`/EsocialAPI/editor/consulta/evento/${eventoSelected}`,
                dataType: 'json',
                success:function(resposta){
                  console.log(resposta);
                  const consultas = resposta.listaConsultas.map((obj, index)=>{
                    return {key: index, label: obj.nomeConsulta, data: obj.sqlConsulta , icon: "pi pi-file"}
                  });
                  const consultasEvento = [{
                    key: eventoSelected,
                    label: resposta.codigoEvento,
                    data: "Documents Folder",
                    icon: "pi pi-folder-open",
                    children: consultas
                  }];
                  console.log(consultasEvento)
                  setConsultasEventoTree(consultasEvento);
                },
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
              });
        }
    }, [eventoSelected]);

    function handleSelectionChangeTree(key){
        const consulta = consultasEventoTree.find(obj=>obj.key===eventoSelected).children.find(obj=>obj.key===key);
        if(consulta!==undefined && consulta!==null){
            setSqlConsultaSelected(sqlFormatter.format(consulta.data,{language: "sql"}));
            setConsultaSelected(consulta.label);
        }
    }

    function handleSqlConsultaSelectChange(newSql){
        console.log(newSql);
        setSqlConsultaSelected(newSql);
    }

    function handleSubmitButtonClick(){
        const novaConsulta = {
            nomeConsulta: consultaSelected,
            sqlConsulta: sqlConsultaSelected
        }
        $.ajax({
            url:"/EsocialAPI/editor/consulta/evento",
            type: "POST",
            method: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(novaConsulta),
            success:function(resposta){
              console.log(resposta);
              showMessage(resposta);
              
            },
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
          });

        console.log(novaConsulta);
    }

    function showMessage(message){
        messages.current.show(message);
    }
    const style = {
        panel:{
            marginTop: 30,
        },
        breadCrumb:{
            marginBottom: 30,
        },
        dropdown:{
            marginRight: 30
        },
        card:{
            paddingBottom: 10
        },
        button:{
            marginTop: 20,
            marginBottom: 20
        },
        label:{
            fontSize: 16,
            fontWeight: 'bold',
            display:'block',
        }
    };
    const items = [
        { label: 'Home', icon: 'pi pi-home', url: '/home' },
        { label: 'Administração', icon: 'pi pi-home', url: '/home' },
        { label: 'Editor de Consultas', icon: 'pi pi-home', url: '/EsocialReact/administracao/editorquery' }
    ];
    
     
    

    return (
        <div>
            <MenuBar usuario={usuario}/>
            <div className="content">
                <div className="content align-center">
                    <Panel header="Editor Consultar" style={style.panel}>
                        <BreadCrumb home="principal" style={style.breadCrumb} model={items} />
                        <div id="div-principal">
                            <h3>Edição de consultas</h3>
                            <em>Página para a edição das consultas responsáveis para a montagem dos eventos.</em>
                            <div id="div-filtro">
                                <Panel header="Filtro">
                                    <div className="pure-g">
                                        <div className="pure-u-1-3">
                                            <label style={style.label}>Tipo Evento</label>
                                            <Dropdown
                                                placeholder="Selecione o tipo" 
                                                style={style.dropdown} 
                                                value={tipoEvento}
                                                onChange={(e)=>{setTipoEvento(e.value)}} 
                                                options={tiposEventoFiltro}/>
                                        </div>
                                        <div className="pure-u-1-2">
                                            <label style={style.label}>Evento</label>
                                            <Dropdown
                                                placeholder="Selecione o evento"
                                                style={style.dropdown} 
                                                value={eventoSelected}
                                                onChange={(e)=>{setEventoSelected(e.value)}} 
                                                options={eventosFiltro}
                                            />
                                        </div>
                                        
                                    </div>
                                </Panel>
                            </div> 
                            <div id="div-query" style={style.panel}>
                                <Panel header="Consultas">
                                <Messages ref={messages}></Messages>
                                    <div className="pure-g">
                                        <div className="pure-u-1-3" style={{borderRight:'2px solid #ccc'}}>
                                            <Tree 
                                                value={consultasEventoTree} 
                                                selectionMode="single"
                                                selectionKeys={consultaSelected} 
                                                onSelectionChange={(e)=>{handleSelectionChangeTree(e.value)}}/>
                                        </div>
                                        <div className="pure-u-2-3" style={{borderLeft:'2px solid #8c8c8c'}}>
                                            <AceEditor
                                                mode="sql"
                                                theme="eclipse"
                                                name="eventosql"
                                                fontSize={16}
                                                width="100%"
                                                showPrintMargin={true}
                                                showGutter={true}
                                                highlightActiveLine={true}
                                                onChange={handleSqlConsultaSelectChange}
                                                value={sqlConsultaSelected}
                                                setOptions={{
                                                enableBasicAutocompletion: true,
                                                enableLiveAutocompletion: true,
                                                enableSnippets: true,
                                                showLineNumbers: true,
                                                tabSize: 12
                                                }}
                                            />
                                            <Button 
                                                style={{float:'right'} }
                                                label="Salvar Alteração"
                                                onClick={()=>handleSubmitButtonClick()}
                                            />
                                        </div>
                                    </div>
                                    
                                </Panel>
                            </div>   
                        </div>   
                    </Panel>
                </div>
            </div>
        </div>


    );
}

export default EditorQuery;
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Panel} from 'primereact/panel';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputMask} from 'primereact/inputmask';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import $ from 'jquery';
import {TabEventosNaoPeriodicos} from '../sessoes/Trabalhador/TAbEventosNaoPeriodicos';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUserTie, faMedkit, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons'
import {PermissionCheck} from '../componentes/PermissionCheck';

export class Trabalhador extends Component{
  constructor(){
    super();
    library.add(fab, faUserTie, faMedkit, faPlaneDeparture);
    this.onPage = this.onPage.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.state={lista : []
                ,home :{icon: 'pi pi-home'}
                ,tipoEvento:"tabela"
                ,usuario:{}
                ,activeIndex:0
                ,nome:""
                ,matricula:""
                ,message:{}
                ,cpf:""
                ,rows:20
                ,pagina:0
                ,servidores:[]
                ,timeLine:null
                ,loading:true
                ,total:0
                ,expandedRows: null
                ,subMenu:[{label: 'Novo', icon: 'pi pi-fw pi-plus'},{label: 'Editar', icon: 'pi pi-fw pi-pencil'},{label: 'Apagar', icon: 'pi pi-fw pi-trash'}]
              };
              
  }
  
  rowExpansionTemplate(data) {
    //let timeLine = this.consultaEventos(data.matricula,data.nome);
    //console.log(timeLine);
    return  (
      <TabEventosNaoPeriodicos dados={data}/>
    );
}
  showMessage() {
    this.messages.show(this.state.message);
    }
    consultaTrabalhador(){
        console.log(this.state.pagina);
        var dados = {pagina:this.state.pagina,nome:this.state.nome,cpf:this.state.cpf,matricula:this.state.matricula};
      $.ajax({
        url:"/EsocialAPI/api/trabalhador/lista/",
        type:"POST",
        data:JSON.stringify(dados),
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        error:function(erro){
            var error = { "severity": "error", "summary": "Mensagem de erro", "detail": "Requisição não pode ser completada" };
            this.setState({message:error});
            this.showMessage(error);
            console.log(erro);
        }.bind(this),
        success:function(resposta){
          console.log(resposta);
          this.setState({loading:false,servidores:resposta.servidores,message:resposta.mensagem,total:resposta.totalPaginas,pagina:resposta.pagina});
          this.showMessage();
        }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    consultaUsuario(){
      var baseurl = window.location.protocol + "//" + window.location.host;
      $.ajax({
        url:"/EsocialAPI/usuario/logado/",
        dataType: 'json',
        error:function(){
          window.location.href= baseurl;
        },
        success:function(resposta){
          this.setState({usuario:resposta});
          //this.qtdeMensagens();
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }

    componentDidMount(){
        this.consultaUsuario();
        this.consultaTrabalhador();
    }
    onPage(event) {
        console.log(this.state.loading);
        this.setState({
            loading: true
        })
        //imitate delay of a backend call
        setTimeout(() => {
            const startIndex = event.first;
    
            this.setState({
                first: startIndex,
                pagina:event.page,
                loading: false
            });
            this.consultaTrabalhador();
        }, 250);
    }
  render() {

      const items = [
    {label:'Home',icon:'pi pi-home', url: '/home'},
    {label:'Trabalhador',icon:'pi pi-home', url: '/trabalhador'}
  ];
    return(


      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content" style={{marginTop:'25px'}}>
          <div className="content align-center">
          <div className="pure-u-5-5">

              <Messages ref={(el) => this.messages = el}></Messages>
          </div>
              <Panel header="Fila de eventos">
              <BreadCrumb  home="principal" model={items}/>
              <em>Eventos na fila para envio ao ESocial.</em>
              <br />
              <div className="pure-u-5-5">
                  <h5>Consulte o trabalhador</h5>
                  <br />
                    <div className="pure-u-1-5">
                        <span className="p-float-label">
                        <InputText id="txtMatricula" style={{width: '95%'}} value={this.state.matricula} onChange={(e) => this.setState({matricula: e.target.value})} />
                        <label htmlFor="txtMatricula">Matricula:</label>
                        </span>
                    </div>
                    <div className="pure-u-2-5">
                        <span className="p-float-label">
                            <InputText id="txtNome" style={{width: '95%'}} value={this.state.nome} onChange={(e) => this.setState({nome: e.target.value})} />
                            <label htmlFor="txtNome">Nome:</label>
                        </span>
                    </div>
                    <div className="pure-u-1-5">
                        <span className="p-float-label">
                            <InputMask mask="999.999.999-99" style={{width: '95%'}}  id="txtCpf"  value={this.state.cpf} onChange={(e) => this.setState({cpf: e.target.value})} />
                            <label htmlFor="txtCpf">CPF:</label>
                        </span>
                    </div>
                    <div className="pure-u-1-5">
                        <span className="p-float-label">
                        <Button label="Buscar" icon="pi pi-search" iconPos="left" onClick={(e) => this.consultaTrabalhador()} />
                        </span>
                    </div>
                    
              </div>
              <div className="pure-u-5-5"></div>
              <div className="pure-u-5-5">
              <div className="content-section implementation">
                    <DataTable value={this.state.servidores} 
                        lazy={true}
                        paginator={true} rows={this.state.rows} 
                        onPage={this.onPage}
                        first={this.state.first}
                        loading={this.state.loading}
                        totalRecords={this.state.total}
                        expandedRows={this.state.expandedRows} 
                        onRowToggle={(e) => this.setState({expandedRows:e.data})}     
                        rowExpansionTemplate={this.rowExpansionTemplate} emptyMessage="Nenhum servidor Encontrado" >
                        <Column expander={true} style={{width: '3%'}} dataKey="id" />
                        <Column field="id" header="Id" style={{width: '3%', textAligh:'center'}}/>
                        <Column field="matricula" header="Matricula" style={{width: '10%', textAligh:'center'}} />
                        <Column field="nome" header="Nome" />
                        <Column field="cpf" header="CPF" />
                    </DataTable>
                </div>

              </div>
              </Panel>
          </div>

            </div>
            <div className="footer l-box is-center">
              <PermissionCheck usuario={this.state.usuario}  />
            </div>

      </div>
    );
  }
}

import React, { Component } from 'react';
import $ from 'jquery';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { TabEnvio } from '../../../sessoes/Processamento/preview/tabEnvio';
import { TabInfoEvento } from '../../../sessoes/Processamento/preview/tabInfoEvento';
import { Messages } from 'primereact/messages';
import { EventoFila } from '../../EventoFila';



export class Preview extends Component {
  constructor() {
    super();

    this.state = {
      listaErros: [],
      listaPreRequisito: [],
      visiblePreview: false,
      sendPreview: false,
      isDetalheVisible:false,
      idEventoFila: 0,
      activeIndex: 0,
      activeView: 0,
      idEventoEsocial: 0,
      tabEnviandoDisable: true,
      envioDisabled: null,
      isEventosEnviados: false,
      respostaEnvio: [],
      evtPronto:false,
      statusEnvioDesabilitado:['RECEBIDO', 'AGUARDANDO_RETORNO', 'EM_PROCESSAMENTO']
    }

    this.onHideDialogEnvio = this.onHideDialogEnvio.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({ idEventoFila: props.idEventoFila, idEventoEsocial: this.props.idEventoEsocial, envioDisabled: !props.eventoPronto });
  }

  componentDidMount() {
    this.setState({ envioDisabled: !this.props.eventoPronto });
  }
  enviarEvento() {
    $.ajax({
      type: "POST",
      data: { 'idEventoFila[]': this.props.idEventoFila },
      url: "/EsocialAPI/api/processamento/enviar/",
      dataType: 'json',
      success: function (resposta) {
        console.log('Retorno da resposta do envio');
        $('#pgrEnviar').css("display", "none");
        this.setState({ selecionados: [] });
        this.setState({ isEventosEnviados: true });
        this.setState({ respostaEnvio: resposta })
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  handleEnviarEventoClick() {
    this.setState({ activeIndex: 1 });
    this.setState({ envioDisabled: true })
    this.enviarEvento();
    //setTimeout(()=>{}, 4000)
  }


  carregaListaErro(ideEventoFila) {
    this.setState({ sendPreview: true });

    $.ajax({
      url: "/EsocialAPI/api/processamento/valida/" + ideEventoFila,
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        console.log(resposta);
        this.setState({ listaErros: resposta.erro });
        this.setState({evtPronto: resposta.pronto})
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  carregaListaPreRequisito(ideEventoFila) {
    this.setState({ sendPreview: true });
    $.ajax({
      url: "/EsocialAPI/api/processamento/prerequisito/" + ideEventoFila,
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        console.log(resposta);
        this.setState({ listaPreRequisito: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  handleTabEnvioClick(ideEventoFila) {
    this.carregaListaErro(ideEventoFila);
    //this.carregaListaPreRequisito(ideEventoFila);
  }
  showError() {
    this.messages.show({ severity: 'error', summary: 'Evento Inconsistente.', detail: 'Não é possível enviar eventos com erros.' });
  }
  direcionar(id) {
    var url = window.location.origin + "/EventoFila/" + id;
    console.log(url);
    window.location.replace(url);
  }
  renderFooterTabEnvio(listaErro, status) {
    if (listaErro.length > 0) {
      let desativaBotao = true;
      if (this.state.evtPronto && !this.state.envioDisabled && !this.state.statusEnvioDesabilitado.includes(status) && listaErro.length==1) {
        desativaBotao = false;
      }
      const footer = (
        <div>
          <Button
            id="btnEnviar"
            label="Enviar"
            icon="pi pi-envelope"
            disabled={desativaBotao}
            onClick={(e) => this.handleEnviarEventoClick()}
          />
          <Button
            label="Não"
            icon="pi pi-times"
            onClick={(e) => this.onHideDialogEnvio()}
            className="p-button-secondary"
          />
        </div>
      );
      return footer;
    }
  }

  onHideDialogEnvio(){
    this.setState({ sendPreview: false, activeIndex: 0, activeView: 0, envioDisabled: false, activeIndex: 0 });

    if(this.state.isEventosEnviados) {
      this.props.atualizaGrid(false);
      this.setState({ isEventosEnviados: false});
    } else {
      // Atualizar a informação de apto do registro no datatable
      this.props.rowData['isReady'] = this.state.evtPronto;
      const updatedListaEvento = this.props.listaEvento.map(item => {
        if (item.idEventoFila === this.props.idEventoFila) {
          return { ...item, data: this.props.rowData };
        }
        return item;
      });

      this.props.updateListaEvento(updatedListaEvento);
    }
  }
  render() {
    return (
      <div>

        <Dialog
          header="Detalhe Evento"
          visible={this.state.isDetalheVisible}
          modal={true}
          onHide={() => this.setState({ isDetalheVisible: false })}
          maximizable
          style={{ textAlign: 'center', width:'85%' }}

        >

          <EventoFila isMenuDisabled = {true} match = {{params: {id: this.props.idEventoFila}}}/>
        </Dialog>
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-info"
          onClick={(e) => this.setState({ isDetalheVisible: true })}
        /> &nbsp;

        <Dialog
          header="Ajuda"
          visible={this.state.visiblePreview}
          modal={true}
          onHide={() => this.setState({ visiblePreview: false })}
          maximizable
          style={{ textAlign: 'center', width:'75%' }}

        >

          <TabInfoEvento
            idEventoEsocial={this.props.idEventoEsocial}
            idEventoFila={this.props.idEventoFila}
          />

        </Dialog>
        <Button
          type="button"
          icon="pi pi-question"
          className="p-button-info"
          onClick={(e) => this.setState({ visiblePreview: true })}
        />

        <Dialog
          header="Envio de Evento único"
          visible={this.state.sendPreview}
          modal={true}
          onHide={()=>this.onHideDialogEnvio()}
          footer={this.renderFooterTabEnvio(this.state.listaErros, this.props.statusEvento)}
          style={{width:'850px'}}
          maximizable
        >
          <Messages ref={(el) => this.messages = el}></Messages>

          <TabEnvio
            idEventoFila={this.props.idEventoFila}
            activeIndex={this.state.activeIndex}
            listaErros={this.state.listaErros}
            listaPreRequisito={this.state.listaPreRequisito}
            tabEnviandoDisable={this.state.tabEnviandoDisable}
            isEventosEnviados={this.state.isEventosEnviados}
            respostaEnvio={this.state.respostaEnvio}
            situacao={this.props.statusEvento}
          />
        </Dialog>

        &nbsp;
        <Button
          type="button"
          icon="pi pi-envelope"
          className="p-button-success"
          disabled = {this.props.statusEvento == 'EXCLUIDO' || (this.props.codEventoEsocial == 3000 && this.props.statusEvento == 'RECEBIDO') || this.props.statusEvento == 'ALTERADO'}
          onClick={() => this.handleTabEnvioClick(this.props.idEventoFila)}
        />

      </div>
    );
  }
}
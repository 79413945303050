import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { ProgressBar } from 'primereact/progressbar';
import React, { useState, useEffect, useRef } from 'react';
import api from '../../services/api';
import Utils from '../../utils/Util';
import $ from 'jquery';

const EvtTSVInicio = (props) => {

    const [evtTSVInicio, setEvtTSVInicio] = useState(null)
    const [activeIndex1, setActiveIndex1] = useState(0);
    const [activeIndex2, setActiveIndex2] = useState(0);
    const [activeIndex3, setActiveIndex3] = useState(0);
    const [activeIndex4, setActiveIndex4] = useState(0);
    const [activeIndex5, setActiveIndex5] = useState(0);


    useEffect(() => {
        //const response = await api.get("/api/trabalhador/preview/JSON/" + props.idEventoFila)
        //console.log(response.data.evtTSVInicio)
        $.ajax({
            url: "/EsocialAPI/api/trabalhador/preview/JSON/" + props.idEventoFila,
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta);
                setEvtTSVInicio(resposta.evtTSVInicio)        
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
        
    }, [])

    function renderEvtTSVInicio(evtTSVInicio) {
        if (evtTSVInicio != null && evtTSVInicio != undefined) {
            return (
                <TabView activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                    <TabPanel header="IdeEvento">
                        {renderIdeEvento(evtTSVInicio.ideEvento)}
                    </TabPanel>
                    <TabPanel header="IdeEmpregador">
                        {renderIdeEmpregador(evtTSVInicio.ideEmpregador)}
                    </TabPanel>
                    <TabPanel header="Trabalhador">
                        {renderTrabalhador(evtTSVInicio.trabalhador)}
                    </TabPanel>
                    <TabPanel header="InfoTSVInicio">
                        {renderInfoTSVInicio(evtTSVInicio.infoTSVInicio)}
                    </TabPanel>
                </TabView>
            )
        } else {
            return (
                <div>
                    <ProgressBar mode="indeterminate" />
                </div>
            )

        }
    }

    function renderIdeEvento(ideEvento) {
        if (ideEvento != null && ideEvento != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>indRetif:</label>{ideEvento.indRetif}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrRecibo:</label>{ideEvento.nrRecibo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>procEmi:</label>{ideEvento.procEmi}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpAmb:</label>{ideEvento.tpAmb}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>verProc:</label>{ideEvento.verProc}
                    </div>
                </div >
            )
        } else {
            return <div><p>Identificação do Evento sem preenchimento!</p></div>
        }
    }

    function renderIdeEmpregador(ideEmpregador) {
        if (ideEmpregador != null && ideEmpregador != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpInsc:</label>{ideEmpregador.tpInsc}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrInsc:</label>{ideEmpregador.nrInsc}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de identificação do empregador sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderTrabalhador(trabalhador) {
        if (trabalhador != null && trabalhador != undefined) {
            return (
                <div>
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>cpfTrab:</label>{trabalhador.cpfTrab}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>nmTrab:</label>{trabalhador.nmTrab}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>sexo:</label>{trabalhador.sexo}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>racaCor:</label>{trabalhador.racaCor}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>estCiv:</label>{trabalhador.estCiv}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>grauInstr:</label>{trabalhador.grauInstr}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>nmSoc:</label>{trabalhador.nmSoc}
                        </div>
                    </div >
                    <TabView activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <TabPanel header="Nascimento">
                            {renderNascimento(trabalhador.nascimento)}
                        </TabPanel>
                        <TabPanel header="Endereco">
                            {renderEndereco(trabalhador.endereco)}
                        </TabPanel>
                        <TabPanel header="TrabImig">
                            {renderTrabImig(trabalhador.trabImig)}
                        </TabPanel>
                        <TabPanel header="InfoDeficiencia">
                            {renderInfoDeficiencia(trabalhador.infoDeficiencia)}
                        </TabPanel>
                        <TabPanel header="Dependentes">
                            {renderDependente(trabalhador.dependente)}
                        </TabPanel>
                        <TabPanel header="Contato">
                            {renderContato(trabalhador.contato)}
                        </TabPanel>
                    </TabView>
                </div>

            )
        } else {
            return (
                <div>
                    <p>Informações do trabalhador sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderInfoTSVInicio(infoTSVInicio) {
        if (infoTSVInicio != null && infoTSVInicio != undefined) {
            return (
                <div>
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>cadIni:</label>{infoTSVInicio.cadIni}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>matricula:</label>{infoTSVInicio.matricula}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>codCateg:</label>{infoTSVInicio.codCateg}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>dtInicio:</label>{Utils.timeStampToDate(infoTSVInicio.dtInicio)}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>nrProcTrab:</label>{infoTSVInicio.nrProcTrab}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>natAtividade:</label>{infoTSVInicio.natAtividade}
                        </div>
                    </div >
                    <TabView activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <TabPanel header="InfoComplementares">
                            {renderInfoComplementares(infoTSVInicio.infoComplementares)}
                        </TabPanel>
                        <TabPanel header="MudancaCPF">
                            {renderMudancaCPF(infoTSVInicio.mudancaCPF)}
                        </TabPanel>
                        <TabPanel header="Afastamento">
                            {renderAfastamento(infoTSVInicio.afastamento)}
                        </TabPanel>
                        <TabPanel header="Termino">
                            {renderTermino(infoTSVInicio.termino)}
                        </TabPanel>

                    </TabView>
                </div>

            )
        } else {
            return (
                <div>
                    <p>Informações de Trabalhador sem vínculo sem preenchimento.</p>
                </div>
            )
        }
    }

    function renderInfoComplementares(infoComplementares) {
        if (infoComplementares != null && infoComplementares != undefined) {
            return (
                <TabView activeIndex={activeIndex4} onTabChange={(e) => setActiveIndex4(e.index)}>
                    <TabPanel header="CargoFuncao">
                        {renderCargoFuncao(infoComplementares.cargoFuncao)}
                    </TabPanel>
                    <TabPanel header="Remuneracao">
                        {renderRemuneracao(infoComplementares.remuneracao)}
                    </TabPanel>
                    <TabPanel header="FGTS">
                        {renderFGTS(infoComplementares.FGTS)}
                    </TabPanel>
                    <TabPanel header="InfoDirigenteSindical">
                        {renderInfoDirigenteSindical(infoComplementares.infoDirigenteSindical)}
                    </TabPanel>
                    <TabPanel header="InfoTrabCedido">
                        {renderInfoTrabCedido(infoComplementares.infoTrabCedido)}
                    </TabPanel>
                    <TabPanel header="InfoMandElet">
                        {renderInfoMandElet(infoComplementares.infoMandElet)}
                    </TabPanel>
                    <TabPanel header="InfoEstagiario">
                        {renderInfoEstagiario(infoComplementares.infoEstagiario)}
                    </TabPanel>

                </TabView>
            )
        } else {
            return (
                <div>
                    <p>Informações complementares sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderNascimento(nascimento) {
        if (nascimento != null && nascimento != undefined) {
            console.log(Utils.timeStampToDate(nascimento.dtNascto))
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtNascto:</label>{Utils.timeStampToDate(nascimento.dtNascto)}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>paisNascto:</label>{nascimento.paisNascto}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>paisNac:</label>{nascimento.paisNac}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de nascimento sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderEndereco(endereco) {
        if (endereco != null && endereco != undefined) {
            if (endereco.brasil != null && endereco.brasil != undefined) {
                return renderBrasil(endereco.brasil)
            } else {
                return renderExterior(endereco.exterior)
            }
        } else {
            return (
                <div>
                    <p>Informações de endereço sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderBrasil(brasil) {
        if (brasil != null && brasil != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpLograd:</label>{brasil.tpLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dscLograd:</label>{brasil.dscLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrLograd:</label>{brasil.nrLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>complemento:</label>{brasil.complemento}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>bairro:</label>{brasil.bairro}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cep:</label>{brasil.cep}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>codMunic:</label>{brasil.codMunic}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>uf:</label>{brasil.uf}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de endereço sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderExterior(exterior) {
        if (exterior != null && exterior != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>paisResid:</label>{exterior.tpLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dscLograd:</label>{exterior.dscLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrLograd:</label>{exterior.nrLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>complemento:</label>{exterior.complemento}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>bairro:</label>{exterior.bairro}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nmCid:</label>{exterior.cep}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>codPostal:</label>{exterior.codMunic}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de endereço sem preenchimento.</p>
                </div>
            )
        }
    }

    function renderTrabImig(trabImig) {
        if (trabImig != null && trabImig != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tmpResid:</label>{trabImig.tmpResid}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>condIng:</label>{trabImig.condIng}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de Trabalhador imigrante sem preenchimento.</p>
                </div>
            )
        }
    }

    function renderInfoDeficiencia(infoDeficiencia) {
        if (infoDeficiencia != null && infoDeficiencia != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>defFisica:</label>{infoDeficiencia.defFisica}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>defVisual:</label>{infoDeficiencia.defVisual}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>defAuditiva:</label>{infoDeficiencia.defAuditiva}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>defMental:</label>{infoDeficiencia.defMental}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>defIntelectual:</label>{infoDeficiencia.defIntelectual}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>reabReadap:</label>{infoDeficiencia.reabReadap}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>observacao:</label>{infoDeficiencia.observacao}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de Pessoa com deficiência sem preenchimento.</p>
                </div>
            )
        }
    }

    function renderDependente(dependente) {
        if (dependente != null && dependente != undefined) {
            return (
                <div>
                    <DataTable value={dependente} emptyMessage="Nenhum dependente encontrado para o Servidor" className="p-datatable-sm" >
                        <Column field="tpDep" header="tpDep" style={{ width: '5em' }} />
                        <Column field="nmDep" header="nmDep" />
                        <Column field="dtNascto" body={renderData} header="dtNascto" style={{ width: '15em' }} />
                        <Column field="depIRRF" header="depIRRF" style={{ width: '5em' }} />
                        <Column field="depSF" header="depSF" style={{ width: '5em' }} />
                        <Column field="incTrab" header="incTrab" style={{ width: '5em' }} />
                    </DataTable>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações dos dependentes sem preenchimento.</p>
                </div>
            )
        }
    }

    function renderContato(contato) {
        if (contato != null && contato != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>fonePrinc:</label>{contato.fonePrinc}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>emailPrinc:</label>{contato.emailPrinc}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de contato sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderCargoFuncao(cargoFuncao) {
        if (cargoFuncao != null && cargoFuncao != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nmCargo:</label>{cargoFuncao.nmCargo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>CBOCargo:</label>{cargoFuncao.CBOCargo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nmFuncao:</label>{cargoFuncao.nmFuncao}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>CBOFuncao:</label>{cargoFuncao.CBOFuncao}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de Cargo/Função ocupado pelo TSVE sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderRemuneracao(remuneracao) {
        if (remuneracao != null && remuneracao != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>vrSalFx:</label>{remuneracao.nmCargo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>undSalFixo:</label>{remuneracao.CBOCargo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dscSalVar:</label>{remuneracao.nmFuncao}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações da remuneração e periodicidade de pagamento sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderFGTS(FGTS) {
        if (FGTS != null && FGTS != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>vrSalFx:</label>{Utils.timeStampToDate(FGTS.dtOpcFGTS)}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações do FGTS sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderInfoDirigenteSindical(infoDirigenteSindical) {
        if (infoDirigenteSindical != null && infoDirigenteSindical != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>categOrig:</label>{infoDirigenteSindical.categOrig}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpInsc:</label>{infoDirigenteSindical.tpInsc}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrInsc:</label>{infoDirigenteSindical.nrInsc}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtAdmOrig:</label>{Utils.timeStampToDate(infoDirigenteSindical.dtAdmOrig)}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>matricOrig:</label>{infoDirigenteSindical.matricOrig}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegTrab:</label>{infoDirigenteSindical.tpRegTrab}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegPrev:</label>{infoDirigenteSindical.tpRegPrev}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações relativas ao dirigente sindical sem preenchimento. </p>
                </div>
            )
        }
    }
    function renderInfoTrabCedido(infoTrabCedido) {
        if (infoTrabCedido != null && infoTrabCedido != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>categOrig:</label>{infoTrabCedido.categOrig}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cnpjCednt:</label>{infoTrabCedido.cnpjCednt}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>matricCed:</label>{infoTrabCedido.matricCed}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtAdmCed:</label>{Utils.timeStampToDate(infoTrabCedido.dtAdmCed)}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegTrab:</label>{infoTrabCedido.tpRegTrab}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegPrev:</label>{infoTrabCedido.tpRegPrev}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>nformações relativas ao trabalhador cedido/em exercício em outro órgão sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderInfoMandElet(infoMandElet) {
        if (infoMandElet != null && infoMandElet != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>indRemunCargo:</label>{infoMandElet.indRemunCargo}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegTrab:</label>{infoMandElet.tpRegTrab}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>tpRegPrev:</label>{infoMandElet.tpRegPrev}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações relativas a servidor público exercente de mandato eletivo sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderInfoEstagiario(infoEstagiario) {
        if (infoEstagiario != null && infoEstagiario != undefined) {
            return (
                <div>
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>natEstagio:</label>{infoEstagiario.natEstagio}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>nivEstagio:</label>{infoEstagiario.nivEstagio}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>areaAtuacao:</label>{infoEstagiario.areaAtuacao}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>nrApol:</label>{infoEstagiario.nrApol}
                        </div>
                        <div className="pure-u-1">
                            <label style={{ fontWeight: 'bold', margin: 3 }}>dtPrevTerm:</label>{Utils.timeStampToDate(infoEstagiario.dtPrevTerm)}
                        </div>
                    </div >
                    <TabView activeIndex={activeIndex5} onTabChange={(e) => setActiveIndex5(e.index)}>
                        <TabPanel header="InstEnsino">
                            {renderInstEnsino(infoEstagiario.instEnsino)}
                        </TabPanel>
                        <TabPanel header="AgeIntegracao">
                            {renderAgeIntegracao(infoEstagiario.ageIntegracao)}
                        </TabPanel>
                        <TabPanel header="SupervisorEstagio">
                            {renderSupervisorEstagio(infoEstagiario.supervisorEstagio)}
                        </TabPanel>
                    </TabView>
                </div>

            )
        } else {
            return (
                <div>
                    <p>Informações relativas ao estagiário sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderInstEnsino(instEnsino) {
        if (instEnsino != null && instEnsino != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cnpjInstEnsino:</label>{instEnsino.cnpjInstEnsino}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nmRazao:</label>{instEnsino.nmRazao}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dscLograd:</label>{instEnsino.dscLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>nrLograd:</label>{instEnsino.nrLograd}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>bairro:</label>{instEnsino.bairro}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cep:</label>{instEnsino.cep}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>codMunic:</label>{instEnsino.codMunic}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>uf:</label>{instEnsino.uf}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações da Instituição de ensino sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderAgeIntegracao(ageIntegracao) {
        if (ageIntegracao != null && ageIntegracao != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cnpjAgntInteg:</label>{ageIntegracao.cnpjAgntInteg}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de Agente de integração sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderSupervisorEstagio(supervisorEstagio) {
        if (supervisorEstagio != null && supervisorEstagio != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cpfSupervisor:</label>{supervisorEstagio.cpfSupervisor}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de Supervisor do estágio sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderMudancaCPF(mudancaCPF) {
        if (mudancaCPF != null && mudancaCPF != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>cpfAnt:</label>{mudancaCPF.cpfAnt}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>matricAnt:</label>{mudancaCPF.matricAnt}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtAltCPF:</label>{Utils.timeStampToDate(mudancaCPF.dtAltCPF)}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>observacao:</label>{mudancaCPF.observacao}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações deInformações de mudança de CPF do trabalhador sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderAfastamento(afastamento) {
        if (afastamento != null && afastamento != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtIniAfast:</label>{new Date(afastamento.dtIniAfast).toLocaleDateString}
                    </div>
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>codMotAfast:</label>{afastamento.codMotAfast}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informações de afastamento do TSVE sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderTermino(termino) {
        if (termino != null && termino != undefined) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1">
                        <label style={{ fontWeight: 'bold', margin: 3 }}>dtTerm:</label>{Utils.timeStampToDate(termino.dtTerm)}
                    </div>
                </div >
            )
        } else {
            return (
                <div>
                    <p>Informação do término do TSVE sem preenchimento.</p>
                </div>
            )
        }
    }
    function renderData(row){
        
        const data = Utils.timeStampToDate(row.dtNascto)
        console.log(data)
        return data;
        
    }
    return (
        <div>
            {renderEvtTSVInicio(evtTSVInicio)}
        </div>
    )
}

export default EvtTSVInicio;
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { PermissionCheck } from '../../componentes/PermissionCheck';
import { Toolbar } from 'primereact/toolbar';
import {FiltroTarefasAutomatizadas} from './filtros/FiltroTarefasAutomatizadas'
import { Dialog } from 'primereact/dialog';

export class ProcessamentoAutomatizado extends Component {
    constructor(props) {
        super(props);

        this.handleTarefaChange = this.handleTarefaChange.bind(this);
        this.handleGrupoEventoChange = this.handleGrupoEventoChange.bind(this);
        this.handleEventoChange = this.handleEventoChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleAcaoChange = this.handleAcaoChange.bind(this);
        this.handleMesChange = this.handleMesChange.bind(this);
        this.handleMesDescricaoChange = this.handleMesDescricaoChange.bind(this);
        this.handleAnoChange = this.handleAnoChange.bind(this);
        this.handleIndApuracaoChange = this.handleIndApuracaoChange.bind(this);
        this.handleObjDetalheChange = this.handleObjDetalheChange.bind(this);
        this.handleIdEventoFilaChange = this.handleIdEventoFilaChange.bind(this);
        this.handleIdLoteChange = this.handleIdLoteChange.bind(this);
        this.handleTipoServidorChange = this.handleTipoServidorChange.bind(this);
        this.handleGrupoEventoDescricaoChange = this.handleGrupoEventoDescricaoChange.bind(this);
        this.handleEventoDescricaoChange = this.handleEventoDescricaoChange.bind(this);
        this.handleIndApuracaoDescricaoChange = this.handleIndApuracaoDescricaoChange.bind(this);
        this.handleTipoServidorDescricaoChange = this.handleTipoServidorDescricaoChange.bind(this);
        this.onHide = this.onHide.bind(this);

        this.state = {
            evento: null,
            grupoEvento: 0,
            idEventoFila: 0,
            idLote: 0,
            status: null,
            tarefa: null,
            acao: null,
            mes: null,
            ano: null,
            indApuracao: null,
            objDetalhe: '',
            tipoServidor: 0,
            usuario: {},
            displayBasic: false,
            filtros: {},
            grupoEventoDescricao: '',
            eventoDescricao: '',
            indApuracaoDescricao: '',
            tipoServidorDescricao: '',
            mesDescricao: ''
        };
    }

    executarTarefa(jobAction) {

        this.setState({ displayBasic: false });

        if(this.state.tarefa === null) {
            this.showMessage('', 'Selecione uma tarefa.', 'info');
        } else {
            const filtros = {
                idUsuario: parseInt(this.state.usuario.id),
                idEventoFila: parseInt(this.state.idEventoFila),
                idLote: parseInt(this.state.idLote),
                evento: parseInt(this.state.evento),
                status: this.state.status,
                tarefa: this.state.tarefa,
                acao: this.state.acao,
                objDetalhe: this.state.objDetalhe,
                mes: parseInt(this.state.mes),
                ano: parseInt(this.state.ano),
                indApuracao: parseInt(this.state.indApuracao),
                tipoServidor: parseInt(this.state.tipoServidor),
            };

            fetch('/EsocialAPI/api/processamento/' + jobAction, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            body: JSON.stringify(filtros),
            })
            .then(response => response.text())
            .then(data => {
                const mensagemDTO = JSON.parse(data);
                this.showMessage('', mensagemDTO.mensagem, mensagemDTO.tipoMensagem) ;
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    componentDidMount() {
        this.consultaUsuario();
    }

    consultaUsuario() {
        var baseurl = window.location.protocol + "//" + window.location.host;
        $.ajax({
            url: "/EsocialAPI/usuario/logado/",
            dataType: 'json',
            error: function () {
            window.location.href = baseurl;
            },
            success: function (resposta) {
            this.setState({ usuario: resposta });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    verificaDados() {

        this.setState({ displayBasic: true });

        const filtros = this.state.tarefa === 'consulta' ? 
        {} 
        :
        {
            idUsuario: parseInt(this.state.usuario.id),
            idEventoFila: parseInt(this.state.idEventoFila),
            idLote: parseInt(this.state.idLote),
            evento: parseInt(this.state.evento),
            status: this.state.status,
            tarefa: this.state.tarefa,
            acao: this.state.acao,
            objDetalhe: this.state.objDetalhe,
            mes: parseInt(this.state.mes),
            ano: parseInt(this.state.ano),
            indApuracao: parseInt(this.state.indApuracao),
            tipoServidor: parseInt(this.state.tipoServidor),
        };

        this.setState({ filtros: filtros });
    }

    limparFiltros = () => {
        this.setState({
          displayBasic: false ,
          ano: '',
          mesDescricao: '',
          mes: '',
          indApuracaoDescricao: '',
          indApuracao: '',
          idEvento: '',
          descricaoEvento: '',
          tipoServidorDescricao: '',
          idEventoFila: '',
          acao: '',
          status: '',
          eventoDescricao: '',
          grupoEventoDescricao: '',
          evento: '',
          tipoServidor: ''
        });
      }

      onHide() {
        this.setState({
            displayBasic: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Corrigir os dados" icon="pi pi-times" onClick={() => { this.limparFiltros() }}  onHide={() => { this.limparFiltros() }} className="p-button-danger" autoFocus />
                <Button label="Os dados estão corretos!" icon="pi pi-check" className="p-button-sucess" loading={this.state.loading} onClick={() => {this.executarTarefa('executar-job')}} />
            </div>
        );
    }

    handleTarefaChange(tarefa) {
        this.setState({ tarefa });
    }

    handleGrupoEventoChange(grupoEvento) {
        this.setState({ grupoEvento });
    }

    handleGrupoEventoDescricaoChange(grupoEventoDescricao) {
        this.setState({ grupoEventoDescricao });
    }

    handleEventoDescricaoChange(eventoDescricao) {
        this.setState({ eventoDescricao });
    }

    handleIndApuracaoDescricaoChange(indApuracaoDescricao) {
        this.setState({ indApuracaoDescricao });
    }

    handleStatusChange(status) {
        this.setState({ status });
    }

    handleEventoChange(evento) {
        this.setState({ evento });
    }

    handleAcaoChange(acao) {
        this.setState({ acao });
    }

    handleMesChange(mes) {
        this.setState({ mes });
    }

    handleMesDescricaoChange(mesDescricao) {
        this.setState({ mesDescricao });
    }

    handleAnoChange(ano) {
        this.setState({ ano });
    }

    handleIndApuracaoChange(indApuracao) {
        this.setState({ indApuracao });
    }

    handleObjDetalheChange(objDetalhe) {
        this.setState({ objDetalhe });
    }

    handleIdEventoFilaChange(idEventoFila) {
        this.setState({ idEventoFila });
    }

    handleIdLoteChange(idLote) {
        this.setState({ idLote });
    }

    handleTipoServidorChange(tipoServidor) {
        this.setState({ tipoServidor });
    }

    handleTipoServidorDescricaoChange(tipoServidorDescricao){
        this.setState({ tipoServidorDescricao })
    }

    showMessage(titulo, mensagem, tipoMensagem) {
        this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
    }

    render() {
        const style = {
            toolbar: {
                margin: 0,
                padding: 0,
            },
        }

        const items = [
            { label: 'Home', icon: 'pi pi-home', url: '/home' },
            { label: 'Processamento', icon: 'pi pi-home', url: '/home' },
            { label: 'Tarefas Automatizadas', icon: 'pi pi-home', url: '/processamento/automatizado' }
          ];

        const rightContents = (
            <React.Fragment>
                <Button style={{ margin: '4px' }} onClick={() => this.verificaDados()} tooltip="Executar" tooltipOptions={{ position: 'left' }} label="Executar" icon="pi pi-play"/>
                <Button style={{ margin: '4px' }} onClick={() => this.executarTarefa('check-job')} tooltip="Checar Execução" tooltipOptions={{ position: 'top' }} label="Checar Execução" className="p-button-info" icon="pi pi-info-circle"/>
            </React.Fragment>
        );
        return (
            <div>
                <MenuBar usuario={this.state.usuario} />
                <div className="content" >
                    <div className="content align-center">
                        <BreadCrumb home="principal" model={items} />
                        <div>
                            <Panel header="Tarefas Automatizadas">
                                <Messages ref={(el) => (this.messages = el)}></Messages>
                                <FiltroTarefasAutomatizadas
                                    usuario={this.state.usuario}
                                    evento={this.state.evento}
                                    idEventoFila={this.state.idEventoFila}
                                    idLote={this.state.idLote}
                                    status={this.state.status}
                                    tarefa={this.state.tarefa}
                                    acao={this.state.acao}
                                    mes={this.state.mes}
                                    mesDescricao={this.state.mesDescricao}
                                    ano={this.state.ano}
                                    grupoEventoDescricao={this.state.grupoEventoDescricao}
                                    eventoDescricao={this.state.eventoDescricao}
                                    indApuracaoDescricao={this.state.indApuracaoDescricao}
                                    indApuracao={this.state.indApuracao}
                                    objDetalhe={this.state.objDetalhe}
                                    tipoServidor={this.state.tipoServidor}
                                    tipoServidorDescricao={this.state.tipoServidorDescricao}
                                    handleTarefaChange={this.handleTarefaChange}
                                    handleGrupoEventoChange={this.handleGrupoEventoChange}
                                    handleGrupoEventoDescricaoChange={this.handleGrupoEventoDescricaoChange}
                                    handleEventoDescricaoChange={this.handleEventoDescricaoChange}
                                    handleIndApuracaoDescricaoChange={this.handleIndApuracaoDescricaoChange}
                                    handleStatusChange={this.handleStatusChange}
                                    handleEventoChange={this.handleEventoChange}
                                    handleAcaoChange={this.handleAcaoChange}
                                    handleMesChange={this.handleMesChange}
                                    handleMesDescricaoChange={this.handleMesDescricaoChange}
                                    handleAnoChange={this.handleAnoChange}
                                    handleIndApuracaoChange={this.handleIndApuracaoChange}
                                    handleObjDetalheChange={this.handleObjDetalheChange}
                                    handleIdEventoFilaChange={this.handleIdEventoFilaChange}
                                    handleIdLoteChange={this.handleIdLoteChange}
                                    handleTipoServidorChange={this.handleTipoServidorChange}
                                    handleTipoServidorDescricaoChange={this.handleTipoServidorDescricaoChange}

                                />
                            </Panel>
                            <Toolbar right={rightContents} style={style.toolbar}/>
                        </div>
                        <div>
                                <Dialog header="Confirmação de Execução da Tarefa" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter()}  onHide={() => { this.limparFiltros() }}>
                                    <b><p>Os dados estão corretos?</p></b>
                                    {this.state.grupoEventoDescricao ? <p><b>Grupo Evento: </b> {this.state.grupoEventoDescricao}</p>  : "" }
                                    {this.state.eventoDescricao ? <p> <b>Evento:</b> {this.state.eventoDescricao}</p> : ""}
                                    {this.state.filtros.status ? <p> <b>Status:</b> {this.state.filtros.status}</p> : ""}
                                    {this.state.filtros.acao ? <p> <b>Açao:</b> {this.state.filtros.acao}</p> : ""}
                                    {this.state.filtros.objDetalhe ? <p> <b>Detalhes:</b> {this.state.filtros.objDetalhe}</p> : ""}
                                    {this.state.filtros.idEventoFila ? <p> <b>ID Evento:</b> {this.state.filtros.idEventoFila}</p> : ""}
                                    {this.state.tipoServidorDescricao ? <p> <b>Tipo Servidor:</b> {this.state.tipoServidorDescricao}</p> : ""}
                                    {this.state.mesDescricao ? <p> <b>Mes: </b>{this.state.mesDescricao}</p> : ""}
                                    {this.state.filtros.ano ? <p> <b>Ano:</b> {this.state.filtros.ano}</p> : ""}
                                    {this.state.indApuracaoDescricao ? <p> <b>Indicação de Apuração:</b> {this.state.indApuracaoDescricao}</p> : ""}

                                </Dialog>
                            </div>
                    </div>
                </div>
                <div className="footer l-box is-center">
                    <PermissionCheck usuario={this.state.usuario}  />
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';


export class Mensagem extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"tabela",usuario:{},qtdeMensagens:'',mensagens:null,mensagemSlt:{corpo:null,titulo:null,dataEmissao:null}};
    this.consultaUsuario=this.consultaUsuario.bind(this);;
    this.qtdeMensagens=this.qtdeMensagens.bind(this);
    library.add(fab, faEnvelope, faEnvelopeOpenText);
  }
  componentDidMount(){
    this.consultaUsuario();
  }
  qtdeMensagens(){
    console.log('mensagens');
    var baseurl = window.location.protocol + "//" + window.location.host ;
    let us = this.state.usuario.idUsuario;
      $.ajax({
        url:"/EsocialAPI/usuario/mensagens/"+this.state.usuario.idUsuario,
        dataType: 'json',
        success:function(resposta){
          console.log(resposta);
          this.setState({qtdeMensagens:resposta.length.toString(),mensagens:resposta});  
        }.bind(this),
        error:function(error){
          console.log(error);
          //window.location.href= baseurl;
        },
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      }); 

  }
  renderStatus(rowData){
    if(rowData.lida){
      return (
        <Button tooltip="Lido" className="p-button-sm">
          <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ margin: '3px' }} />
        </Button>
      );
    }else{
      return (
        <Button tooltip="Não lido" className="p-button-sm">
          <FontAwesomeIcon icon={faEnvelope} style={{ margin: '3px' }} />
        </Button>
      );
    }
  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host ;
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        this.qtdeMensagens();
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  renderData(rowData){
    try{
      if(rowData.dataEmissao!==null){
        let dataEmissao = new Date(rowData.dataEmissao);
        return dataEmissao.toLocaleString();
      }else{
        return "";
      }

    }catch(erro){
      console.log(erro);
      return "";
    }
  }
  render() {
    const bread = [
      { label: 'Home' , url: '/home'},
      { label: 'Alertas', url: '/mensagem' }
  ];
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div >
                <div style={{width:'100%',display:'inline-block',marginBottom:'25px'}}>
                  <BreadCrumb model={bread}/>
                </div>
                <div style={{width:'100%',display:'inline-block'}}>
                    <DataTable value={this.state.mensagens} 
                      selectionMode="single" 
                      selection={this.state.mensagemSlt} 
                      onSelectionChange={e => this.setState({ mensagemSlt: e.value })} dataKey="idMensagem"
                      scrollable scrollHeight="200px" className="p-datatable-sm"
                    >
                        <Column header="Status" field="lida" body={this.renderStatus} style={{width:'5%'}} />
                        <Column header="Titulo" field="titulo"/>
                        <Column header="Data de emissão" field="dataEmissao" body={this.renderData}  />
                    </DataTable>
                </div>
                <hr />
                <div>
                  <label>Título: </label><strong>&nbsp;{this.state.mensagemSlt.titulo}</strong> <br />
                  <label>Data emissão: </label><strong>&nbsp;{this.renderData(this.state.mensagemSlt)}</strong> <br />
                  <label>Conteúdo:</label><br />
                  <div>
                    {this.state.mensagemSlt.corpo}
                  </div>
                </div>
              </div>
          </div>

            </div>
            <div className="footer l-box is-center">
                Copyright © Todos os direitos reservados.
            </div>

      </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeSquare, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from 'primereact/badge';
import { ProgressSpinner } from 'primereact/progressspinner';
import $ from 'jquery';
import { ProgressBar } from 'primereact/progressbar';
import {Messages} from 'primereact/messages';

export class EnviarFolha extends Component {
  constructor() {
    super();
    library.add(fab, faEnvelopeSquare, faEnvelope, faTimes);
    this.state = {
      idEventoFila: 0,
      bolAtivo: false,
      activeIndex: 0,
      evtValido: false,
      msgsAddServidor:null,
      ano: 2018,
      mes: 1,
      visible: false,
      wdwAddServidor: false,
      chkAgendamento: false,
      mostraDetalhes: 'none',
      chEnvioTotal: false,
      dispAgendamento: 'none',
      agendamento: null,
      dispPreparaEnvioFolha: 'none',
      servidor: {id:null, nome: 'Nenhum servidor selecionado.', cpf: '', matricula: '' },
      servidoresFiltrados: null,
      evtSelecionado: 'block',
      wdwImportarEventos: false,
      panelImportAtivo: true,
      progressBarAtivo: false,
      retornoAtivo: false,
      btnIncluir:true,
      loadServPerApur:'block',
      importado:'Servidor existe na folha.',
      mensagemRetorno: {}
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.consultaServidor = this.consultaServidor.bind(this);
    this.renderDetalhes = this.renderDetalhes.bind(this);
    this.gerarEventos = this.gerarEventos.bind(this)
    this.salvaServidorFolha = this.salvaServidorFolha.bind(this);
  }
  onHide(name) {
    this.setState({
      [`${name}`]: false
    });

  }
  onClick(name, position) {
    console.log(this.props.selecionados);
    let state = {
      [`${name}`]: true
    };

    if (position) {
      state = {
        ...state,
        position
      }
    }

    this.setState(state);
  }
  consultaServidor(event) {
    try {
      var dados = this.state.servidor;
      $.ajax({
        url: "/EsocialAPI/folha/trabalhador/",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        data: dados,
        dataType: 'json',
        success: function (resposta) {
          console.log(resposta);
          var filtrados = [];
          resposta.map(function (objeto) {
            return filtrados.push(objeto);
          });
          this.setState({ servidoresFiltrados: filtrados,mostraDetalhes:'none',loadServPerApur:'block' });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });/*
          let results = this.state.servidoresFiltrados.filter((server) => {
                return server.toLowerCase().startsWith(event.query.toLowerCase());
          });*/
      //this.setState({ servidoresFiltrados: results }).bind(this);
    } catch (err) {
      console.log(err);
    }
  }

  gerarEventos(idFolha) {

    this.setState({panelImportAtivo: false});
    this.setState({progressBarAtivo: true});
    $.ajax({
      url: "/EsocialAPI/folha/gerarFolha/" + idFolha,
      method: 'GET',
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
        this.setState({mensagemRetorno: resposta})
        this.setState({progressBarAtivo: false})
        this.setState({retornoAtivo: true})
      }.bind(this),
      error: function (resposta){

      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  onAgendamento(e) {
    try {
      if (e.checked) {
        this.setState({ chkAgendamento: true });
        $("#divPreparaEvtSlt").fadeOut();
        $("#divAgendamento").fadeIn();
      } else {
        this.setState({ chkAgendamento: false });
        $("#divPreparaEvtSlt").fadeIn();
        $("#divAgendamento").fadeOut();
      }
      console.log(e);
    } catch (erro) {
      console.log(erro);
    }
  }
  onEnvioTotal(e) {
    try {
      console.log(e);
      if (e.checked) {
        this.setState({ chEnvioTotal: true });
        $("#divPreparaEvtSlt").fadeOut();
        $("#divPreparaTodos").fadeIn();
      } else {
        this.setState({ chEnvioTotal: false });
        $("#divPreparaEvtSlt").fadeIn();
        $("#divPreparaTodos").fadeOut();
      }
    } catch (erro) {
      console.log(erro);
    }
  }
  componentDidMount() {

  }
  renderEventoRemun(rowData) {
    let id = rowData.evtRemun.id;
    if (id !== null) {
      return (
        <FontAwesomeIcon icon={faEnvelopeSquare} />
      );
    } else {
      return (
        <FontAwesomeIcon icon={faTimes} />
      );
    }
  }
  renderEventoRGPS(rowData) {
    let id = rowData.evtRmnRGPS.id;
    if (id !== null) {
      return (
        <FontAwesomeIcon icon={faEnvelopeSquare} />
      );
    } else {
      return (
        <FontAwesomeIcon icon={faTimes} />
      );
    }
  }
  renderEventoRPPS(rowData) {
    let id = rowData.evtRmnRPPS.id;
    if (id !== null) {
      return (
        <FontAwesomeIcon icon={faEnvelopeSquare} />
      );
    } else {
      return (
        <FontAwesomeIcon icon={faTimes} />
      );
    }
  }
  consultaServidorFolha(idFolha,idServidor) {
    this.setState({panelImportAtivo: false});
    this.setState({progressBarAtivo: true});
    $.ajax({
      url: "/EsocialAPI/api/trabalhador/valida/EvtsFolha/" + idFolha+"/"+idServidor,
      method: 'GET',
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
        if(resposta.evtRemun===null){
          this.setState({btnIncluir:false,loadServPerApur:'none',importado:'Servidor disponível para inclusão.'});
        }else{
          this.setState({btnIncluir:true,loadServPerApur:'none',importado:'Servidor ja se encontra na folha.'});
        }
        console.log(resposta);
      }.bind(this),
      error: function (resposta){

      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  salvaServidorFolha(idServidor,idFolha) {
    this.setState({panelImportAtivo: false});
    this.setState({progressBarAtivo: true});
    //this.msgsAddServidor.show([{ severity: 'success', summary: 'Success', detail: 'Message Content', sticky: true }]);
    console.log("/EsocialAPI/folha/servidor/add/" + idServidor+"/"+idFolha);
    $.ajax({
      url: "/EsocialAPI/folha/servidor/add/" + idServidor+"/"+idFolha,
      method: 'GET',
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
        //this.setState({ wdwAddServidor: false });
        console.log(resposta);
        this.msgsAddServidor.show([resposta]);
      }.bind(this),
      error: function (resposta){

      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  renderDetalhes(e) {
    console.log(this.state.servidor !== null);
    try {
      if(this.state.servidor.id!==null){
        this.consultaServidorFolha(this.props.idFolha,this.state.servidor.id);
        
      }
      if (this.state.servidor !== null) {
        this.setState({ mostraDetalhes: 'block' });
        console.log(this.state.mostraDetalhes);
      } else {
        console.log('Favor Selecione um servidor');
      }
    } catch (erro) {
      console.log("erro ao exibir detalhes");
    }
  }
  renderEventos(regimePrevidenciario) {
    try {
      if (regimePrevidenciario === 1) {
        return (
          <div style={{ display: 'inline-block' }}>
            <Badge value="RGPS" size="small" severity="info" />
            <Badge value="Remun" size="small" severity="info" style={{ marginLeft: '12px' }} />
          </div>
        );
      } else {
        return (
          <div style={{ display: 'inline-block' }}>
            <Badge value="RPPS" size="small" severity="info"></Badge>
            <Badge value="Remun" size="small" severity="info"></Badge>
          </div>
        );
      }
      if (regimePrevidenciario === null) {
        return (
          <div style={{ display: 'inline-block' }}>Regime previdenciário indisponível.</div>
        );
      }
    } catch (erro) {

    }
  }
  renderDesligamento(desligamento) {

    try {
      if (desligamento !== null) {
        let dataDesligamento = new Date(desligamento);
        return dataDesligamento.toLocaleDateString();
      } else {
        return "Servidor ativo";
      }
    } catch (erro) {
      console.log(erro);
    }
  }
  renderPanelImportacao(ativo) {
    if (ativo) {
      return (
        <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
          <label style={{ fontSize: '18px', }}>Importação dos eventos Periódicos</label>
          <p>Deseja importar todos os eventos de folha do período?</p>
          <Button label="Sim" icon="pi pi-check" style={{ margin: '10px' }} onClick={() => this.gerarEventos(this.props.idFolha)} />
          <Button label="Não" className="p-button-danger" icon="pi pi-times" style={{ margin: '10px' }} onClick={() => this.setState({ wdwImportarEventos: false })} />
        </div>
      );
    }
  }
  renderProgressBar(ativo) {
    if (ativo) {
      return (
        <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
          <label style={{ fontSize: '18px', }}>Importando Eventos</label>
          <p>O processo pode demorar um pouco. Por favor, aguarde.</p>
          <ProgressBar mode="indeterminate" />    
        </div>
      )
      
    }
  }
  renderRetornoImportacao(ativo, mensagem){
    console.log(mensagem);
    if(ativo){
      if(mensagem.severity == "success"){
        return (
          <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
            <label style={{ fontSize: '18px', }}>{mensagem.summary}</label>
            <p>{mensagem.detail}</p>
          </div>
        )
      }else{
        return (
          <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px red', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
            <label style={{ fontSize: '18px', }}>{mensagem.summary}</label>
            <p>{mensagem.detail}</p>
          </div>
        )
      }
      
    }
  }
  render() {
    const footer = (
      <div>
        <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
        <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.onHide('visible')} />
      </div>
    );
    const totalEventos = this.props.totalServidores * 2;
    return (
      <div>


        <Dialog header="Resumo de envio" visible={this.state.visible} style={{ width: '80%' }} footer={footer} onHide={() => this.onHide('visible')}>
          
          <div>
            <div id="dvFiltro">
              <div className="p-col-12">
                <Checkbox inputId="chkAgendamento" onChange={(e) => this.onAgendamento(e)} checked={this.state.chkAgendamento}></Checkbox>
                <label htmlFor="chkAgendamento" className="p-checkbox-label">Deseja agendar o envio para uma data futura?</label>
              </div>
              <div className="p-col-12">
                <Checkbox inputId="chkEnvioTotal" onChange={(e) => this.onEnvioTotal(e)} checked={this.state.chEnvioTotal}></Checkbox>
                <label htmlFor="chkEnvioTotal" className="p-checkbox-label">Enviar todos os eventos do período de apuração ?</label>
              </div>
            </div>
            <hr />
            <div id="dvEventos">
              <div id="divAgendamento" style={{ display: this.state.dispAgendamento }}>
                <div className="p-col-12">
                  <label htmlFor="cldAgendamento" className="p-checkbox-label">Data envio:</label>
                  <Calendar id="cldAgendamento" value={this.state.agendamento} onChange={(e) => this.setState({ agendamento: e.value })} style={{ marginLeft: '15px' }} />
                  <Button label="Gravar" icon="pi pi-save" style={{ marginLeft: '15px' }}></Button>
                </div>
                <em>Lembrando que os eventos serão enviados as 00:00 da data selecionada.</em>
              </div>
              <div id="divPreparaTodos" style={{ display: this.state.dispPreparaEnvioFolha }} >
                <div>
                  <strong>Existem {totalEventos} referentes ao período de apuração selecionado.</strong>
                </div>
              </div>
              <div id="divPreparaEvtSlt" style={{ display: this.state.evtSelecionado }}>
                <DataTable value={this.props.selecionados} emptyMessage="Nenhum evento foi selecionado para envio.">
                  <Column field="nome" header="Servidor"></Column>
                  <Column body={this.renderEventoRGPS} field="evtRmnRGPS.id" header="Regime Geral Previdência"></Column>
                  <Column body={this.renderEventoRPPS} field="evtRmnRPPS.id" header="Regime Próprio Previdência"></Column>
                  <Column body={this.renderEventoRemun} field="evtRemun.id" header="Remuneração"></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header={"Adicionar Trabalhador/Servidor ao PerApur: " + this.state.mes + "/" + this.state.ano} visible={this.state.wdwAddServidor} style={{ width: '50vw' }} onHide={() => this.setState({ wdwAddServidor: false })}>
        <Messages ref={(el) => this.msgsAddServidor = el} />
          <div style={{ display: 'inline-block', height: '380px' }}>
            <h3></h3>
            <div style={{ width: '90%', display: 'inline-block', verticalAlign: 'top' }}>
              <AutoComplete value={this.state.servidor} field="nome" inputStyle={{ width: '700px' }} suggestions={this.state.servidoresFiltrados} onClick={(e) => this.setState({ servidor: { nome: '', cpf: '', matricula: '' } })} completeMethod={this.consultaServidor} minLength={3} onChange={(e) => this.setState({ servidor: e.value })} />
            </div>
            <div style={{ width: '5%', display: 'inline-block' }}>
              <Button label="Consultar" id="btnDetalhe" className="p-button-link" onClick={(e) => this.renderDetalhes(e)} />
            </div>
            <div style={{ display: this.state.mostraDetalhes }} id="divDetalheServidor">
              <div style={{ display: 'inline-block', marginLeft: '25px', marginBottom: '25px' }}>
                <hr />
                <label>Matricula:</label><strong> {this.state.servidor.matricula}</strong>
                <label style={{ marginLeft: '15px' }}>Nome:</label><strong> {this.state.servidor.nome}</strong>
                <hr />
                <label>Servidor desligado:</label><strong>{this.renderDesligamento(this.state.servidor.datadesligamento)}</strong><br />
                <div style={{ display: 'inline-block' }}><label>Tipo de contribuição:</label></div>{this.renderEventos(this.state.servidor.regimePrevidenciario)}<br />
                <label>servidor ausente no perApur:</label><ProgressSpinner style={{ height: '22px', width: '22px', display:this.state.loadServPerApur }} />
                <strong><em>{this.state.importado}</em></strong>
              </div>

            </div>
            <div style={{ width: '100%', display: 'inline-block', verticalAlign: 'top' }}>
              <Button label="Incluir" icon="pi pi-save" disabled={this.state.btnIncluir} onClick={() => this.salvaServidorFolha(this.state.servidor.id, this.props.idFolha)} />
            </div>
          </div>
        </Dialog>

        <Dialog header={"Importar eventos Periódicos."} visible={this.state.wdwImportarEventos} style={{ width: '50vw' }} onHide={() => this.setState({ wdwImportarEventos: false })}>
          {this.renderPanelImportacao(this.state.panelImportAtivo)}
          {this.renderProgressBar(this.state.progressBarAtivo)}
          {this.renderRetornoImportacao(this.state.retornoAtivo, this.state.mensagemRetorno)}
          
        </Dialog>

        <Button icon="pi pi-envelope" label="Enviar Folha" style={{ marginBottom: '20px', marginRight: '20px' }} className="p-button-info" onClick={() => this.onClick('visible')} />
        <Button icon="pi pi-arrow-circle-down" label="Importar" style={{ marginBottom: '20px', marginRight: '20px' }} className="p-button-info" onClick={() => this.setState({ wdwImportarEventos: true })} />
        <Button icon="pi pi-user-plus" label="Adicionar" style={{ marginBottom: '20px' }} className="p-button-info" onClick={() => this.setState({ wdwAddServidor: true })} />
      </div>
    );
  }
}
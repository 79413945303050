
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import '../../../../index.css';
import React, { useState, useEffect, useRef } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';


const TblEvtBasesTrab = (props) => {

  const [infoCpCalc, setInfoCpCalc] = useState([])
  const [infoBaseCS, setInfoBaseCS] = useState([])
  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(100);

  useEffect(() => {
    setInfoCpCalc(props.relatorios.infoCpCalcDTOs);
    setInfoBaseCS(props.relatorios.infoBaseCSDTOs);
  }, [props])


  const [headerCsvInfoCpCalc, setHeaderCsvInfoCpCalc] = useState([
    { label: 'Matrícula', key: 'matricula' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Nome', key: 'nome' },
    { label: 'tpCRDesc', key: 'tpCRDesc' },
    { label: 'vrCpSeg', key: 'vrCpSeg' },
    { label: 'vrDescSeg', key: 'vrDescSeg' },
  ]);

  const [headerCsvinfoBaseCS, setHeaderCsvinfoBaseCS] = useState([
    { label: 'Matrícula', key: 'matricula' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Nome', key: 'nome' },
    { label: 'ind13', key: 'ind13' },
    { label: 'tpValorDesc', key: 'tpValorDesc' },
    { label: 'valor', key: 'valor' },
  ]);

  function geraNomeRelatorio(nome) {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nomeRelatorio = nome+ '_' + today.toISOString() + '.csv';
    return nomeRelatorio;
  }

  function onPage(event, values, setter) {
    console.log(event)
    setFirst2(event.first)
    const endIndex = event.first + event.rows;
    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= event.first && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }

  function onChangePaginator(event, options, values, setter) {
    console.log(event);
    console.log(options.props);
    options.currentPage = event.value
    const startIndex = event.value * options.rows;
    const endIndex = startIndex + options.rows
    setFirst2(startIndex);

    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= startIndex && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }
  const templatePaginatorInfoCpCalc = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCpCalcDTOs, setInfoCpCalc)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorInfoBaseCS = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoBaseCSDTOs, setInfoBaseCS)} />
            </div>
          </div>
        </>
      )
    }
  };



  return (

    <div>
      <TabView>
        <TabPanel header="infoCpCalc">
          <h3>Cálculo da contribuição previdenciária do segurado</h3>
          <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
            Cálculo da contribuição previdenciária do segurado, incidente sobre a remuneração do período de apuração e de períodos anteriores informada nos eventos S-1200, S-2299 e S-2399.
          </p>
          <div className="content-section implementation">
          {props.relatorios.infoCpCalcDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCpCalcDTOs} header={headerCsvInfoCpCalc} fileName={geraNomeRelatorio('5001_infoCpCalc_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
            <DataTable
              value={infoCpCalc}
              emptyMessage="Nenhum registro encontrado."
              lazy={true}
              loading={false}
              totalRecords={(props.relatorios.infoCpCalcDTOs.length)}
              className="p-datatable-sm"
              paginator={true}
              paginatorTemplate={templatePaginatorInfoCpCalc}
              first={first2}
              rows={100}          
              onPage={(e)=>onPage(e, props.relatorios.infoCpCalcDTOs, setInfoCpCalc)}
            >
              <Column field="matricula" header="Matrícula" style={{ width: '3em' }} />
              <Column field="cpf" header="CPF" style={{ width: '3em' }} />
              <Column field='nome' header="Nome" style={{ width: '3em' }} />
              <Column field='tpCRDesc' header="tpCRDesc" style={{ width: '3em' }} />
              <Column field='vrCpSeg' header="vrCpSeg" style={{ width: '3em' }} />
              <Column field='vrDescSeg' header="vrDescSeg" style={{ width: '3em' }} />
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="infoBaseCS">
          <h3>Informações sobre bases de cálculo, descontos e deduções de CS</h3>
          <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
            Informações sobre bases de cálculo, descontos e deduções de contribuições sociais devidas à Previdência Social e a Outras Entidades e Fundos.
          </p>
          <div className="content-section implementation">
          {props.relatorios.infoBaseCSDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoBaseCSDTOs} header={headerCsvinfoBaseCS} fileName={geraNomeRelatorio('5001_infoBaseCS_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
            <DataTable
              value={infoBaseCS}
              emptyMessage="Nenhum registro encontrado."
              lazy={true}
              rows={100}
              loading={false}
              totalRecords={props.relatorios.infoBaseCSDTOs.length}
              className="p-datatable-sm"
              paginator={true}
              paginatorTemplate={templatePaginatorInfoBaseCS}
              first={first2}      
              onPage={(e)=>onPage(e, props.relatorios.infoBaseCSDTOs, setInfoBaseCS)}
            >
              <Column field="matricula" header="Matrícula" style={{ width: '3em' }} />
              <Column field="cpf" header="CPF" style={{ width: '3em' }} />
              <Column field='nome' header="Nome" style={{ width: '3em' }} />
              <Column field='ind13' header="ind13" style={{ width: '3em' }} />
              <Column field='tpValorDesc' header="tpValorDesc" style={{ width: '3em' }} />
              <Column field='valor' header="valor" style={{ width: '3em' }} />
            </DataTable>
          </div>
        </TabPanel>
      </TabView>

    </div>

  )
}

export default TblEvtBasesTrab;
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { MenuBar } from '../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { PermissionCheck } from '../componentes/PermissionCheck';

export class LogViewer extends Component {
    constructor() {
        super();
        this.state = {
            logs: null,
            qtdLinhas: 100,
            usuario: {},
        };
        this.consultarLog = this.consultarLog.bind(this); // Bind the method to the component's context
    }

    consultarLog() {
        fetch('/EsocialAPI/api/logs?qtdLinhas=' + this.state.qtdLinhas, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
        })
        .then(response => response.text())
        .then(data => {
            const mensagemDTO = JSON.parse(data)
            if(mensagemDTO.severity === 'error') {
                this.showMessage(null, mensagemDTO.detail, mensagemDTO.severity)
            } else {
                this.setState({ logs: mensagemDTO.detail });
            }

            console.log(mensagemDTO);
        })
        .catch(error => {
            this.showMessage(null, error, 'danger')
            console.error('Error:', error);
        });
    }

    componentDidMount() {
        this.consultaUsuario();
    }

    componentWillUnmount() {
        // Cleanup code here if needed
    }

    handleQtdLinhas(qtdLinhas) {
        this.setState({ qtdLinhas });
    }

    consultaUsuario() {
        var baseurl = window.location.protocol + "//" + window.location.host;
        $.ajax({
            url: "/EsocialAPI/usuario/logado/",
            dataType: 'json',
            error: function () {
                window.location.href = baseurl;
            },
            success: function (resposta) {
                this.setState({ usuario: resposta });
            }.bind(this), // Bind the success callback to the component's context
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    showMessage(titulo, mensagem, tipoMensagem) {
        this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
    }

    render() {
        const items = [
            { label: 'Home', icon: 'pi pi-home', url: '/home' },
            { label: 'Log', icon: 'pi pi-home', url: '/log' },
        ];
        return (
            <div>
                <MenuBar usuario={this.state.usuario} />
                <div className="content" >
                    <div className="content align-center">
                        <BreadCrumb home="principal" model={items} />
                        <div>
                            <Panel header="Log da aplicação">
                                <Messages ref={(el) => (this.messages = el)}></Messages>
                                <div>
                                    <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        Quantidade de linhas:
                                    </span>
                                    <input type="number" className="p-inputtext" value={this.state.qtdLinhas} onChange={(e) => this.handleQtdLinhas(e.target.value)} />
                                    <Button
                                        label="Exibir Log"
                                        id="button-log"
                                        icon="pi pi-eye"
                                        iconPos="left"
                                        onClick={this.consultarLog}
                                    />
                                </div>
                                    <pre>{this.state.logs}</pre>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
                {/*
                <div className="footer l-box is-center">
                    <PermissionCheck usuario={this.state.usuario}  />
                </div>
                */}
            </div>
        );
    }
}

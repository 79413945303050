import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Toolbar } from 'primereact/toolbar';
import $ from 'jquery';

export class DBConfig extends Component{
  constructor(props){
    super(props);

    this.state={lista : []
              ,home :{icon: 'pi pi-home'}
              ,processoEmissao:1
              ,hostPrimaryDb:''
              ,hostPrimaryPorta:''
              ,hostPrimaryLogin:''
              ,hostPrimarySenha:''
              ,PrimaryClassName:''
              ,PrimaryDialect:''
              ,PrimaryShowSQL:''
              ,hostSecondaryDb:''
              ,hostSecondaryPorta:''
              ,hostSecondaryLogin:''
              ,hostSecondarySenha:''
              ,SecondaryClassName:''
              ,SecondaryDialect:''
              ,SecondaryShowSQL:''
          };

  }
  montaConfiguracaoDb(){
    var configuracaoDb = {
      hostDB: this.state.hostPrimaryDb,
      hostPorta: this.state.hostPrimaryPorta,
      hostLogin: this.state.hostPrimaryLogin,
      hostsenha: this.state.hostPrimarySenha,
      className: this.state.PrimaryClassName,
      dialect: this.state.PrimaryDialect,
      showSQL: this.state.PrimaryShowSQL
    }
    return configuracaoDb;
  }

  montaConfiguracaoDbCache(){
    var configuracaoDbCache = {
      hostDB:this.state.hostSecondaryDb,
      hostPorta:this.state.hostSecondaryPorta,
      hostLogin:this.state.hostSecondaryLogin,
      hostsenha:this.state.hostSecondarySenha,
      tipoBanco:'',
      className:this.state.SecondaryClassName,
      dialect:this.state.SecondaryDialect,
      showSQL:this.state.SecondaryShowSQL
    }
    return configuracaoDbCache;
  }

  mandaConfiguracao(e){
    e.preventDefault();
    var configuracaoDb = this.montaConfiguracaoDb();
    $.ajax({
      url:"/EsocialAPI/config/salva/confDb",
      type:'post',
      data:JSON.stringify(configuracaoDb),
      dataType:'json',
      success:function(resposta){
        console.log(resposta);
        console.log(configuracaoDb);
        this.showSuccess(resposta);
      }.bind(this),
      error:function(erro){
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType:'application/json',
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  testaConexao(e){
    e.preventDefault();
    var configuracaoDb = this.montaConfiguracaoDb();
    console.log(configuracaoDb);  
    $.ajax({
      url:"/EsocialAPI/config/testaconex",
      type:'post',
      data:JSON.stringify(configuracaoDb),
      dataType:'json',
      success:function(resposta){
        console.log(resposta);
        console.log(configuracaoDb);
        this.showSuccess(resposta);
      }.bind(this),
      error:function(erro){
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType:'application/json',
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
}

  mandaConfiguracaoCache(e){
    e.preventDefault();
    var configuracaoDb = this.montaConfiguracaoDbCache();
    $.ajax({
      url:"/EsocialAPI/config/salva/confCache",
      type:'post',
      data:JSON.stringify(configuracaoDb),
      dataType:'json',
      success:function(resposta){
        this.showSuccess(resposta);
      }.bind(this),
      error:function(erro){
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType:'application/json',
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  testaConexaoCache(e){
    e.preventDefault();
    var configuracaoDb = this.montaConfiguracaoDbCache();
    console.log(configuracaoDb);  
    $.ajax({
      url:"/EsocialAPI/config/testaconexCache",
      type:'post',
      data:JSON.stringify(configuracaoDb),
      dataType:'json',
      success:function(resposta){
        console.log(resposta);
        console.log(configuracaoDb);
        this.showSuccess(resposta);
      }.bind(this),
      error:function(erro){
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType:'application/json',
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
  });
  }
  showSuccess(msg) {
    this.messages.show(msg);
  }
  componentDidMount(){
    $.ajax({
      url: "/EsocialAPI/config/",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ hostPrimaryDb: resposta.configuracaoDb.hostDB });
        this.setState({ hostPrimaryPorta: resposta.configuracaoDb.hostPorta });
        this.setState({ hostPrimaryLogin: resposta.configuracaoDb.hostLogin });
        this.setState({ hostPrimarySenha: resposta.configuracaoDb.hostsenha });
        this.setState({ PrimaryClassName: resposta.configuracaoDb.className });
        this.setState({ PrimaryDialect: resposta.configuracaoDb.dialect });
        this.setState({ PrimaryShowSQL: resposta.configuracaoDb.showSQL });
        this.setState({ hostSecondaryDb: resposta.configuracaoDbCache.hostDB });
        this.setState({ hostSecondaryPorta: resposta.configuracaoDbCache.hostPorta });
        this.setState({ hostSecondaryLogin: resposta.configuracaoDbCache.hostLogin });
        this.setState({ hostSecondarySenha: resposta.configuracaoDbCache.hostsenha });
        this.setState({ SecondaryClassName: resposta.configuracaoDbCache.className });
        this.setState({ SecondaryDialect: resposta.configuracaoDbCache.dialect });
        this.setState({ SecondaryShowSQL: resposta.configuracaoDbCache.showSQL });
        
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '95%'
      },
      inputText: {
          width: '95%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }
    const dialetos = [
        {label: 'DB2', value: 'org.hibernate.dialect.DB2Dialect'},
        {label: 'DB2 AS/400 - Dados Reais', value: 'org.hibernate.dialect.DB2400Dialect'},
        {label: 'DB2 OS390', value: 'org.hibernate.dialect.DB2390Dialect'},
        {label: 'PostgreSQL', value: 'org.hibernate.dialect.PostgreSQLDialect'},
        {label: 'MySQL', value: 'org.hibernate.dialect.MySQLDialect'},
        {label: 'MySQL com InnoDB', value: 'org.hibernate.dialect.MySQLInnoDBDialect'},
        {label: 'Oracle 8', value: 'org.hibernate.dialect.OracleDialect'},
        {label: 'Oracle 9i/10g', value: 'org.hibernate.dialect.Oracle9Dialect'},
        {label: 'Microsoft SQL Server', value: 'org.hibernate.dialect.SQLServerDialect'},
        {label: 'Intersystems Caché', value: 'org.hibernate.dialect.Cache71Dialect'}
    ];
    return(
      <>
        <div className="p-grid">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <Panel header="Base de dados da Mensageria">
              <div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-2">
                      <label style={style.label}>Dialeto: </label>
                      <Dropdown 
                        style={style.dropDown} 
                        value={this.state.PrimaryDialect}
                        options={dialetos} 
                        onChange={(e) => {this.setState({PrimaryDialect: e.value})}} 
                        placeholder="Selecione o dialeto"
                      />
                    </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-2">
                      <label style={style.label}>Host: </label>
                      <InputText 
                        id="txtPrimaryDb" 
                        value={this.state.hostPrimaryDb} 
                        style={style.inputText}
                        onChange={(e) => this.setState({hostPrimaryDb: e.target.value})} 
                      />
                    </div>
                    <div className="pure-u-1-2">
                        <label style={style.label}>Porta: </label>
                        <InputText 
                          id="txthostPrimaryPorta" 
                          value={this.state.hostPrimaryPorta} 
                          style={style.inputText}
                          onChange={(e) => this.setState({hostPrimaryPorta: e.target.value})} 
                        />
                    </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-2">
                        <label style={style.label}>Login: </label>
                        <InputText 
                          id="txthostPrimaryLogin" 
                          value={this.state.hostPrimaryLogin} 
                          style={style.inputText}
                          onChange={(e) => this.setState({hostPrimaryLogin: e.target.value})} 
                        />
                    </div>
                    <div className="pure-u-1-2">
                        <label style={style.label}>Senha: </label>
                        <Password 
                          id="txtPrimaryHostSenha" 
                          value={this.state.hostPrimarySenha} 
                          style={style.inputText}
                          onChange={(e) => this.setState({hostPrimarySenha: e.target.value})} 
                        /> 
                    </div>
                  </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button label="Salvar" style={{'margin': '5px'}} icon="pi pi-save" iconPos="left" onClick={(e)=>this.mandaConfiguracao(e)} />
                  <Button className="p-button-success" style={{'margin': '5px'}} label="Testar Conexão" onClick={(e)=>this.testaConexao(e)} />
                </React.Fragment>
              } 
            />
          </div>
          <div className="p-col">
            <Panel header="Base de dados externa">
              <div>
                <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-2">
                      <label style={style.label}>Dialeto: </label>
                      <Dropdown 
                        style={style.dropDown} 
                        value={this.state.SecondaryDialect}
                        options={dialetos} onChange={(e) => {this.setState({SecondaryDialect: e.value})}} 
                        placeholder="Selecione o dialeto"
                      />
                  </div>
                </div>
                <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-2">
                      <label style={style.label}>Host: </label>
                      <InputText 
                        id="txtSecondaryDb" 
                        value={this.state.hostSecondaryDb} 
                        style={style.inputText}
                        onChange={(e) => this.setState({hostSecondaryDb: e.target.value})} 
                      />
                  </div>
                  <div className="pure-u-1-2">
                      <label style={style.label}>Porta: </label>
                      <InputText 
                        id="txthostSecondaryPorta" 
                        value={this.state.hostSecondaryPorta} 
                        style={style.inputText}
                        onChange={(e) => this.setState({hostSecondaryPorta: e.target.value})} 
                      />
                  </div>
                </div>
                <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-2">
                      <label style={style.label}>Login: </label>
                      <InputText 
                        id="txthostSecondaryLogin" 
                        value={this.state.hostSecondaryLogin} 
                        style={style.inputText}
                        onChange={(e) => this.setState({hostSecondaryLogin: e.target.value})} 
                      />
                  </div>
                  <div className="pure-u-1-2">
                      <label style={style.label}>Senha: </label>
                      <Password 
                        id="txtSecondaryHostSenha" 
                        value={this.state.hostSecondarySenha} 
                        style={style.inputText}
                        onChange={(e) => this.setState({hostSecondarySenha: e.target.value})} 
                      /> 
                  </div>
                </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button style={{'margin': '5px'}} label="Salvar" icon="pi pi-save" iconPos="left" onClick={(e)=>this.mandaConfiguracaoCache(e)} />
                  <Button className="p-button-success" style={{'margin': '5px'}} label="Testar Conexão" onClick={(e)=>this.testaConexaoCache(e)} />
                </React.Fragment>
              } 
            />
          </div>
        </div>
      </>
    );    
  }
}

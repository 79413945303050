import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import Utils from "../../utils/Util";

export class RelTotalizadores extends Component {
  constructor() {
    super();
    this.state = { 
      lista: []
      , total: 0
      , pagina: 0
      , rows: 1000
      , loading: false
      , usuario: {}
      , evento: null
      , anoPerApur: null
      , mesPerApur: null
      , indApuracao: null
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.filtrarTotalizadores = this.filtrarTotalizadores.bind(this);
    this.handleEventoChange = this.handleEventoChange.bind(this)
    this.handleMesChange = this.handleMesChange.bind(this)
    this.handleAnoChange = this.handleAnoChange.bind(this)
    this.handleIndApurChange = this.handleIndApurChange.bind(this)
    this.consultaUsuario = this.consultaUsuario.bind(this)
  }

  componentDidMount() {
    this.consultaUsuario();
  }

  handleEventoChange(evento) {
    this.setState({ evento });
  }
  handleMesChange(mesPerApur){
    this.setState({ mesPerApur });
  }
  handleAnoChange(anoPerApur){
    this.setState({ anoPerApur });
  }
  handleIndApurChange(indApuracao){
    this.setState({ indApuracao });
  }

  consultaUsuario() {
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
        url: "/EsocialAPI/usuario/logado/",
        dataType: 'json',
        error: function () {
        window.location.href = baseurl;
        },
        success: function (resposta) {
          this.setState({ usuario: resposta });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
        error: Utils.handleAjaxError
    });
  }

  filtrarTotalizadores() {
    var filtro = {
      evento: this.state.evento
      , anoPerApur: this.state.anoPerApur
      , mesPerApur: this.state.mesPerApur
      , indApuracao: this.state.indApuracao
    };

    let arrMensagem = [];

    if(this.state.evento === null || this.state.evento === '') {
      arrMensagem.push({severity:'info', detail:'Evento é campo obrigatório.'});
    }

    if((this.state.anoPerApur === null || this.state.anoPerApur === '')) {
      arrMensagem.push({severity:'info', detail:'Ano apuração é campo obrigatório.'});
    }

    if((this.state.mesPerApur === null || this.state.mesPerApur === '')) {
      arrMensagem.push({severity:'info', detail:'Mês apuração é campo obrigatório.'});
    }
    
    // Se exibir mensagem, não executa
    if(arrMensagem.length > 0) {
      this.showToast(arrMensagem);
    } else {
      this.setState({ loading: true });

      $.ajax({
        type: "POST",
        data: JSON.stringify(filtro),
        url: "/EsocialAPI/relatorios/totalizadores",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        success: function (resposta) {
          console.log(resposta);
          this.setState({ loading: false, lista: resposta.listaCpf, total: resposta.totalPaginas, pagina: resposta.pagina });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
        error: Utils.handleAjaxError
      });
    }
  }

  showMessage(titulo, mensagem, tipoMensagem) {
    this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
  }

  showToast(arrMensagens) {
    this.toast.show(arrMensagens);
  }

  onClick() {
    this.setState({visible: true});
  }

  onHide() {
      this.setState({visible: false});
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '80%'
      },
      inputText: {
          width: '80%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }

    const month = [
      { label: "Janeiro", value: 1 },
      { label: "Fevereiro", value: 2 },
      { label: "Março", value: 3 },
      { label: "Abril", value: 4 },
      { label: "Maio", value: 5 },
      { label: "Junho", value: 6 },
      { label: "Julho", value: 7 },
      { label: "Agosto", value: 8 },
      { label: "Setembro", value: 9 },
      { label: "Outubro", value: 10 },
      { label: "Novembro", value: 11 },
      { label: "Dezembro", value: 12 },
    ];

    const currentYear = new Date().getFullYear();
    const anos = [];
    anos.push({ label: "Todos", value: "" });
    for (let year = currentYear; year >= 2018; year--) {
        anos.push(year);
    }

    const indApuracao = [{label: 'Mensal', value:1}, {label: 'Anual', value:2}];

    const dropdownEventos = [{label:'S5001',value:'S5001'},{label:'S5002',value:'S5002'},{label:'S5003',value:'S5003'}];

    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/EsocialReact/home' },
      { label: 'Relatórios', icon: 'pi pi-home', url: '/EsocialReact/relatorio/inconsistencias' },
      { label: 'Relatório de Totalizadores', icon: 'pi-home', url: '/EsocialReact/relatorio/totalizadores' }
    ];

    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
            <Panel header="Totalizadores">
              <Toast ref={(el) => this.toast = el} position="bottomright"></Toast>
              <BreadCrumb home="principal" model={items} />
              <div className="pure-u-1 content-section implementation" style={{ marginTop: '15px' }}>
                <Messages ref={(el) => (this.messages = el)}></Messages>
                <Panel header="Filtros">
                  <div style={{ margin: '15px' }} >
                    <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-5">
                          <label style={style.label}>Evento: </label>
                          <Dropdown
                              value={this.state.evento}
                              options={dropdownEventos}
                              onChange={(e) => { this.handleEventoChange(e.value) }}
                              placeholder="Escolha o evento"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Mês Apuração: </label>
                          <Dropdown
                              value={this.state.mesPerApur}
                              options={month}
                              onChange={(e) => { this.handleMesChange(e.value) }}
                              placeholder="Escolha o Mês"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Ano Apuração: </label>
                          <Dropdown
                              value={this.state.anoPerApur}
                              options={anos}
                              onChange={(e) => { this.handleAnoChange(e.value) }}
                              placeholder="Escolha o Ano"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Ind. Apuração: </label>
                          <Dropdown
                              value={this.state.indApuracao}
                              options={indApuracao}
                              onChange={(e) => { this.handleIndApurChange(e.value) }}
                              placeholder="Escolha a Apuração"
                              style={style.dropDown}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="pure-u-3-3" style={{ textAlign: 'right' }}>
                    <Button icon="pi pi-refresh" style={{ margin: '4px' }} onClick={() => this.filtrarTotalizadores()} tooltip="Filtrar" tooltipOptions={{ position: 'right' }} label="Filtrar" />
                  </div>
                </Panel>
                <div className="content-section implementation">
                  <DataTable 
                    value={this.state.lista} 
                    emptyMessage="Nenhum registro encontrado."
                    lazy={true}
                    rows={this.state.rows}
                    loading={this.state.loading}
                    first={this.state.first}
                    totalRecords={this.state.total} 
                    className="p-datatable-sm"
                  >
                    <Column field="matricula" header="Matrícula" style={{ width: '3em' }}/>
                    <Column field="cpf" header="CPF" style={{ width: '3em' }}/>
                    <Column field='nome' header="Nome" style={{ width: '3em' }}/>
                    <Column field="totalBaseSomado" header="BaseCP" style={{ width: '3em' }}/>
                    <Column field="totalCpCalcSomado" header="CPCalcSegurado" style={{ width: '3em' }}/>
                    <Column field="totalCpCalcDescSomado" header="CPCalcDesc" style={{ width: '3em' }}/>
                    <Column field="totalIrrf" header="BaseIRRF" style={{ width: '3em' }}/>
                    <Column field="totalIrrfDescSomado" header="IRRFDesc" style={{ width: '3em' }}/>
                    <Column field="totalBaseFGTSSomado" header="BaseFGTS" style={{ width: '3em' }}/>
                    <Column field="totalDepositoFGTSSomado" header="DepositoFGTS" style={{ width: '3em' }}/>
                  </DataTable>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Toolbar } from 'primereact/toolbar';
import $ from 'jquery';

export class MensageriaConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senha: ''
      , TipoCertificado: ''
      , NomeArquivo: ''
      , NomeArquivoCadeiaCert: ''
      , HostJMS: ''
      , UsuarioJMS: ''
      , SenhaJMS: ''
      , SenhaArquivo: ''
      , TipoInscricao: ''
      , CaminhoArquivo: ''
    };
  }
  toggle() {
    this.setState({ disabled: !this.state.disabled });
  }
  componentDidMount() {
    $.ajax({
      url: "/EsocialAPI/config/",
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        this.setState({ SenhaArquivo: resposta.configuracaoMensageria.senhaArquivo });
        this.setState({ TipoCertificado: resposta.configuracaoMensageria.tipoCertificado });
        this.setState({ NomeArquivoCadeiaCert: resposta.configuracaoMensageria.nomeArquivoCadeiaCert });
        this.setState({ NomeArquivo: resposta.configuracaoMensageria.arquivoCertificado });
        this.setState({ CaminhoArquivo: resposta.configuracaoMensageria.caminhoArquivoCert });
        this.setState({ HostJMS: resposta.configuracaoMensageria.servidorJms });
        this.setState({ UsuarioJMS: resposta.configuracaoMensageria.usuarioJms });
        this.setState({ SenhaJMS: resposta.configuracaoMensageria.SenhaJms });
        this.setState({ TipoInscricao: resposta.configuracaoMensageria.tipoInscricao });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  montaConfiguracaoMensageria() {
    var configuracaoMensageria = {
      senhaArquivo: this.state.SenhaArquivo,
      tipoInscricao: this.state.TipoInscricao,
      caminhoArquivoCert: this.state.CaminhoArquivo,
      arquivoCertificado: this.state.NomeArquivo,
      nomeArquivoCadeiaCert: this.state.NomeArquivoCadeiaCert,
      servidorJms: this.state.HostJMS,
      usuarioJms: this.state.UsuarioJMS,
      senhaJms: this.SenhaJms
    }
    return configuracaoMensageria;
  }
  enviaConfiguracao(e) {
    e.preventDefault();
    var configuracaoMensageria = this.montaConfiguracaoMensageria();
    console.log(configuracaoMensageria);
    $.ajax({
      url: "/EsocialAPI/config/salva/confMensageria",
      type: 'post',
      data: JSON.stringify(configuracaoMensageria),
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        console.log(configuracaoMensageria);
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  instalarCadeiaCert(e) {
    e.preventDefault();
    var configuracaoMensageria = this.montaConfiguracaoMensageria();
    console.log(configuracaoMensageria);
    $.ajax({
      url: "/EsocialAPI/config/salva/confMensageria",
      type: 'post',
      data: JSON.stringify(configuracaoMensageria),
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        console.log(configuracaoMensageria);
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  showSuccess(msg) {
    this.messages.show(msg);
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '95%'
      },
      inputText: {
          width: '95%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }
    return (
      <>
        <div className="p-grid">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-grid">
          <div className="p-col-6">
            <Panel header="Configurações Certificado e JMS">
              <div style={style.inputContainer} className="pure-g">
                <div className="pure-u-1-3">
                  <label style={style.label}>Host de filas: </label>
                  <InputText 
                    id="txtHostJms" 
                    value={this.state.HostJMS} 
                    style={style.inputText}
                    onChange={(e) => this.setState({ HostJMS: e.target.value })} 
                  />
                </div>
                <div className="pure-u-1-3">
                  <label style={style.label}>Usuário JMS: </label>
                  <InputText 
                    id="txtUsuarioJMS" 
                    value={this.state.UsuarioJMS} 
                    style={style.inputText}
                    onChange={(e) => this.setState({ UsuarioJMS: e.target.value })} 
                  />
                </div>
                <div className="pure-u-1-3">
                    <label style={style.label}>Senha JMS: </label>
                    <Password 
                      value={this.state.SenhaJMS} 
                      style={style.inputText}
                      onChange={(e) => this.setState({ SenhaJMS: e.target.value })} 
                      promptLabel="Senha do Serviço" 
                    />
                </div>
              </div>
              <div style={style.inputContainer} className="pure-g">
                <div className="pure-u-1-3">
                    <label style={style.label}>Nome Arquivo Certificado: </label>
                    <InputText 
                      id="nomeCert" 
                      value={this.state.NomeArquivo} 
                      style={style.inputText}
                      onChange={(e) => this.setState({ NomeArquivo: e.target.value })} 
                    />
                </div>
                <div className="pure-u-1-3">
                    <label style={style.label}>Senha Certificado: </label>
                    <Password 
                      value={this.state.SenhaArquivo} 
                      style={style.inputText}
                      onChange={(e) => this.setState({ SenhaArquivo: e.target.value })} 
                      promptLabel="Senha do Arquivo" 
                    />
                </div>
                <div className="pure-u-1-3">
                    <label style={style.label}>Nome Arq. Cadeia de Cert.: </label>
                    <InputText 
                      id="txtNomeArquivo" 
                      value={this.state.NomeArquivoCadeiaCert} 
                      style={style.inputText}
                      onChange={(e) => this.setState({ NomeArquivoCadeiaCert: e.target.value })} 
                      tooltip="Ex: EsocialCacerts" 
                    />
                </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button style={{'margin': '5px'}} label="Salvar" icon="pi pi-save" iconPos="left" onClick={(e) => this.enviaConfiguracao(e)} />
                </React.Fragment>
              } 
            />
          </div>
        </div>
      </>
    );
  }
}

const Utils = {
    isObjectHasValue:(obj)=>{
        if(obj && Object.keys(obj).length === 0 && obj.constructor === Object){
            return false;
        }
        return true;
    },
    timeStampToDate:(timestamp)=>{
        var date = new Date(timestamp);
        const dataFormatada = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()
        return dataFormatada
    },
    handleAjaxError(xhr, status, error) {
        if (xhr.status === 403) {
            window.location.href = "/"; 
        } else {
            console.log(error);
        }
    },
}

export default Utils;
import React,{Component} from 'react';
import 'primereact/resources/themes/saga-green/theme.css';
import { Principal} from './sessoes/Principal';
import { MenuBar } from './componentes/MenuBar';
import $ from 'jquery';
import {PermissionCheck} from './componentes/PermissionCheck';
import { locale, addLocale} from 'primereact/api';
import pt from './pt';
import Utils from "./utils/Util";

export class Home extends Component {
  constructor(){
    super();
    this.state={visible: false,lista : [],menuBar :[],usuario:{}};
    this.changeLocale = this.changeLocale.bind(this);
  }
  componentDidMount(){
    this.consultaUsuario();
  }
  consultaUsuario(){
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      success:function(resposta){
        this.setState({usuario:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); },
      error: Utils.handleAjaxError
    });
  }

  changeLocale(){
    console.log(pt)
    addLocale('pt', pt.pt);
    locale('pt');
  }

  render() {
    this.changeLocale();
    return(
    <div>
      <MenuBar usuario={this.state.usuario} />
      <div className="content">
          <Principal  />

          </div>
          <div className="footer l-box is-center">
            <PermissionCheck usuario={this.state.usuario} url="/home" />
          </div>

    </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import $ from 'jquery';
import{ MenuBar } from '../../../componentes/MenuBar';
import {Panel} from 'primereact/panel';
import { BreadCrumb } from 'primereact/breadcrumb';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {PermissionCheck} from '../../../componentes/PermissionCheck';


export class AjudaSessao extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"1",sessoes:[],ajuda:{},usuario:{}};
  }
  componentDidMount(){
    this.consultaUsuario();
    this.atualizaSessao();
  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizaSessao(){
    console.log('Atualizando');
    $.ajax({
      url:"/EsocialAPI/sessao/listar",
      dataType: 'json',
      success:function(rsp){
        console.log(rsp);
        this.setState({sessoes:rsp});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  actionTemplate(rowData, column) {
    return <div>
        <Button type="button" icon="pi pi-question-circle" style={{marginRight: '.5em'}}></Button>
        <Button type="button" icon="pi pi-pencil"></Button>
    </div>;
}
  render() {
    const items = [
        {label:'Home',icon:'pi pi-home', url: '/home'},
        {label:'Administração',icon:'pi pi-home', url: '/home'},
        {label:'Ajuda',icon:'pi pi-home', url: '/home'},
        {label:'Ajuda Sessão',icon:'pi pi-home', url: '/EsocialReact/administracao/ajudasessao'}
      ];
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-5-5">
                  <br />
                  <Panel header="Ajuda de Sessão" >
                    <BreadCrumb  home="principal" model={items}/>
                    <em>Gerenciamento de documentação de sessões da aplicação</em>
                    <br />
                    <DataTable value={this.state.sessoes} emptyMessage="Nenhuma Ajuda foi registrada">
                        <Column field="idSessao" header="Id" style={{width:'5%'}} />
                        <Column field="nome" header="Sessão" />
                        <Column field="titulo" header="Titulo" />
                        <Column field="dataInclusao" header="Data da Postagem" />
                        <Column body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                  </Panel>
              </div>
          </div>

            </div>
            <div className="footer l-box is-center">
              <PermissionCheck usuario={this.state.usuario}  />
            </div>

      </div>
    );
  }
}

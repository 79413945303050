import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {LoteView} from '../Processamento/dialog/LoteView';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import BotaoConsultaLote from '../../componentes/botoes/BotaoConsultaLote';
import FiltroLote from '../../sessoes/Processamento/filtros/FiltroLote';

export class LoteTabela extends Component {
  constructor() {
    super();
    this.state = { 
      lista: []
      , total: 0
      , pagina: 0
      , rows: 10
      , loading: false
      , status: null
      , idLote: 0
      , usuario: null
      , protocolo: null
      , dtEnvioInicial: null
      , dtEnvioFinal: null
      , linhaExpandida:null
      , visible:false
      , activeIndex:0
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.rowExpansionTemplate=this.rowExpansionTemplate.bind(this);
    this.direcionar=this.direcionar.bind(this);
    this.actionTemplate=this.actionTemplate.bind(this);
    this.atualizaGrid=this.atualizaGrid.bind(this);
    this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this)
    this.handleFilterIdLoteChange = this.handleFilterIdLoteChange.bind(this)
    this.handleFilterUsuarioChange = this.handleFilterUsuarioChange.bind(this)
    this.handleFilterProtocoloChange = this.handleFilterProtocoloChange.bind(this)
    this.handleFilterDtEnvioInicialChange = this.handleFilterDtEnvioInicialChange.bind(this)
    this.handleFilterDtEnvioFinalChange = this.handleFilterDtEnvioFinalChange.bind(this)
  }
  componentDidMount() {
    this.atualizaGrid(false);
  }

  handleFilterStatusChange(value) {
    this.setState({ status: value })
  }

  handleFilterIdLoteChange(value) {
    this.setState({ idLote: value })
  }

  handleFilterUsuarioChange(value) {
    this.setState({ usuario: value })
  }

  handleFilterProtocoloChange(value) {
    this.setState({ protocolo: value })
  }

  handleFilterDtEnvioInicialChange(value) {
    this.setState({ dtEnvioInicial: value })
  }

  handleFilterDtEnvioFinalChange(value) {
    this.setState({ dtEnvioFinal: value })
  }

  atualizaGrid(bolOnpage) {
    var filtro = {
      status: this.state.status
      , idLote: this.state.idLote
      , usuario: this.state.usuario
      , protocolo: this.state.protocolo
      , dtEnvioInicial: this.state.dtEnvioInicial
      , dtEnvioFinal: this.state.dtEnvioFinal
    };
    this.setState({ loading: true });
    let pagina = 0;
    if (bolOnpage === false) {
      pagina = this.state.pagina - 1;
      if (pagina < 0) {
        pagina = 0;
      }
    } else {
      pagina = this.state.pagina;
    }

    var dados = { pagina: pagina, qtdRegistros: this.state.rows, filtro: filtro };

    $.ajax({
      type: "POST",
      data: JSON.stringify(dados),
      url: "/EsocialAPI/api/lote/lista/filtro",
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        this.setState({ loading: false, lista: resposta.lotes, total: resposta.totalPaginas, pagina: resposta.pagina });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  consultaServicoRetorno(protocolo) {
    $.ajax({
      type: "POST",
      data: { "protocolo": protocolo },
      url: "/EsocialMensageria/ConsultaRetorno/",
      dataType: 'text',
      success: function (resposta) {

      }
      //,beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  onPage(event) {
    this.setState({ loading: true });
    setTimeout(() => {
      const startIndex = event.first;
      this.setState({
        first: startIndex,
        pagina: event.page,
        loading: false
      });
      this.atualizaGrid(true);
    }, 250);
  }

  onChangePaginator(event, options) {
    options.currentPage = event.value
    this.setState({ loading: true , pagina: event.value});
    setTimeout(() => {
      const page = parseInt(this.state.pagina);
      const startIndex = this.state.pagina ? options.rows * page : 0;
      this.setState({
        first: startIndex,
        pagina: event.value,
        loading: false
      });
      this.atualizaGrid(true);
    }, 250);
  }

  onClick() {
    this.setState({visible: true});
  }

  onHide() {
      this.setState({visible: false});
  }
  actionTemplate(rowData) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        }}>
        <LoteView idLote={rowData.idLote} useLink={false} />
        <BotaoConsultaLote protocolo={rowData.protocolo} atualizarGrid={this.atualizaGrid}/>
      </div>
    );
  }
  retornaMatricula(data){
    var obj = JSON.parse(data.eventos.objDetalhe);
    return obj.matricula;
  }
  retornaNome(data){
    var obj = JSON.parse(data.eventos.objDetalhe);
    return obj.nome;
  }
  direcionar(id){
    var url = window.location.origin+"/EventoFila/"+id;
    ;
  }
  rowExpansionTemplate(data) {
    let eventos = data.eventos;
    let retorno= '';
    eventos.map(function(evento,index){
      let descricao = JSON.parse(evento.descEventoFila);
      let eventoFila = evento.idEventoFila;
      var url = window.location.origin+"/EventoFila/"+eventoFila;
      let descEvento = descricao.matricula+' - '+descricao.nome;
      retorno = (
        <tbody>
        <tr key={index}>
          <td >{evento.idHistEventoMensageria}</td>
          <td >{evento.evento}</td>
          <td>{descEvento}</td>
          <td >{evento.statusEvento}</td>
          <td>
            <Button type="button" icon="pi pi-eye" className="p-button-info" onClick={(e) => window.location.replace(url)} />
          </td>
        </tr>
        </tbody>
      );
    });

    return  (
      <table style={{marginLeft:'88px'}}>
        <thead>
        <tr>
          <th>IdHistorico</th>
          <th>Evento</th>
          <th>Descrição</th>
          <th>Status</th>
          <th>Ação</th>
        </tr>
        </thead>
        {retorno}
      </table>
    );
  }

  getDataCadastro(rowData) {
    var hora = new Date(rowData['dataHoraCadastro']).toLocaleTimeString('pt-BR');
    var data = new Date(rowData['dataHoraCadastro']).toLocaleDateString();
    return <Badge value={data + ' ' + hora} className='p-badge-info-outlined'/>
  }

  getProtocolo(data){
    return (
      <Badge value={`${data.protocolo !== null ? data.protocolo : '-'}`} className={'p-badge-success-outlined'}/>
    )
  }

  getStatus(data){
    let severity = "";

    switch (data.status) {
      case 'LOTE_INCORRETO':
      case 'AGUARDANDO_RETORNO':
      case 'EM_PROCESSAMENTO':
      case 'PENDENTE':
        severity = 'warning';
        break;
      case 'ENVIANDO':
      case 'RECEBIDO_COM_OCORRENCIAS':
        severity = 'info';
        break;
      case 'RECEBIDO':
        severity = 'primary';
        break;
      case 'REJEITADO':
        severity = 'danger';
        break;
      default:
        severity = 'danger';
    }
    return (
      <Badge value={`${data.status.replace(/_/g, ' ')}`} severity={severity} className='p-badge-square' />
    )
  }

  retornaEventosPorStatus(data){
    return (
      <div>
        
        <i className="pi pi-send p-mr-4 p-mt-2 p-text-secondary p-overlay-badge" style={{ fontSize: '1.6rem' }}>
          <Badge value={`${data.qtdEvt}`} severity="info" />
        </i>
        <i className="pi pi-check-circle p-mr-4 p-mt-2 p-text-secondary p-overlay-badge" style={{ fontSize: '1.6rem' }}>
          <Badge value={`${data.qtdEvtRecebido}`} severity="primary" />
        </i>
        <i className="pi pi-times-circle p-mr-4 p-mt-2 p-text-secondary p-overlay-badge" style={{ fontSize: '1.6rem' }}>
          <Badge value={`${data.qtdEvtRejeitado}`} severity="danger" />
        </i>
          
        
      </div>
    )
  }

  render() {
    const templatePaginator = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        'CurrentPageReport': (options) => {
          const totalReg = options.totalRecords === null ? 0 : options.totalRecords
          const dropdownOptions = [];
          const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
          for (let i = 0; i <= totalPages; i++) {
            const item = {
              label: i + 1,
              value: i
            };

            dropdownOptions.push(item);
          }

            return (
              <>
                <div className="p-grid">
                  <div className="p-col">
                    <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square'/>
                    <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => this.onChangePaginator(e, options)} />
                  </div>
                </div>
              </>
            )
        }
    };

    return (
      <div>
        <div className="content-section introduction">
          <div className="feature-intro">
            <h1>Lotes Enviados</h1>
          </div>
        </div>
        <FiltroLote
            lotes={this.state.rows}
            statusSelecionado={this.state.status}
            onStatusChange={this.handleFilterStatusChange}
            onIdLoteChange={this.handleFilterIdLoteChange}
            onUsuarioChange={this.handleFilterUsuarioChange}
            onProtocoloChange={this.handleFilterProtocoloChange}
            onDtEnvioInicialChange={this.handleFilterDtEnvioInicialChange}
            onDtEnvioFinalChange={this.handleFilterDtEnvioFinalChange}
            onSubmit={this.atualizaGrid}
        />
        <div className="content-section implementation">
          <DataTable 
            value={this.state.lista} 
            paginator={true} 
            paginatorTemplate={templatePaginator} 
            emptyMessage="Nenhum registro encontrado."
            lazy={true}
            rows={this.state.rows}
            onPage={this.onPage.bind(this)}
            loading={this.state.loading}
            first={this.state.first}
            totalRecords={this.state.total} 
            className="p-datatable-sm"
          >
            <Column field="idLote" header="ID Lote" headerStyle={{width: '80px'}}/>
            <Column body={this.getProtocolo} header="Protocolo" headerStyle={{width: '220px'}}/>
            <Column body={this.getDataCadastro}  header="Dt. Envio" headerStyle={{width: '160px'}}/>
            <Column field="usuario" header="Usuario" headerStyle={{width: '140px'}}/>
            <Column body={this.getStatus} header="Status" headerStyle={{width: '220px'}}/>
            <Column body={this.retornaEventosPorStatus} header="Qtd. Eventos"/>
            <Column body={this.actionTemplate.bind(this)}/>
          </DataTable>
        </div>
        <p>
      </p>
      </div>
    );
  }
}

import React, { Component } from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {TabelaEvtAfastamento} from '../Trabalhador/TabelaEvtAfastamento';
import {TabelaEvtAdmissao} from '../Trabalhador/TabelaEvtAdmissao';
import {TabelaEvtSegTrabalho} from '../Trabalhador/TabelaEvtSegTrabalho';
import { Timeline } from 'primereact/timeline';
import $ from 'jquery';

export class TabEventosNaoPeriodicos extends Component{
    constructor(){
        super();
        this.state={idEventoFila:0,bolAtivo:false,evtValido:true , activeIndex:0,timeLine:null,nome:null,matricula:null};
        this.consultaEventos=this.consultaEventos.bind(this);
      }
      componentDidMount() {
        this.setState({nome:this.props.dados.nome,matricula:this.props.dados.matricula});
        this.consultaEventos();
      }
      consultaEventos(){
        try{
          if(this.props.dados!==null){
            if(this.state.matricula===null){
              this.setState({nome:this.props.dados.nome,matricula:this.props.dados.matricula});
            }
            let objDetalhe = {"matricula":this.props.dados.matricula,"nome":this.props.dados.nome};
            objDetalhe = JSON.stringify(objDetalhe);
            console.log(objDetalhe);
            $.ajax({
                url:"/EsocialAPI/api/trabalhador/timeline/"+objDetalhe,
                dataType: 'json',
                success:function(resposta){
                  this.setState({timeLine:resposta});           
                }.bind(this),
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
              });
          }
        }catch(erro){
          console.log(erro);
        }
    
      }
      renderTagTimeLine(item){
        console.log(item.color);
        return(
          <div>
            <i className={item.icon} style={{color:item.color}}></i>
            <label>{item.codEvento}</label>
          </div>
        );
      }  
      render() {
        const events = [
          { status: 'Admissão', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0' },
          { status: 'Afastamento', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
          { status: 'Afastamento', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
          { status: 'Desligamento', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
      ];
        return(
            <div>
            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                <TabPanel header="Eventos" leftIcon="pi pi-chart-line">
                <h5>Eventos Não periódicos</h5>
                <hr />
                <div>
                  <Timeline value={this.state.timeLine} layout="horizontal" 
                  marker={(item) => this.renderTagTimeLine(item)} align="alternate" dataKey={(item)=>item.id} />
                </div>
                <div>
                  <label><strong>Eventos Pendentes:</strong></label><label>{this.props.dados.evtPendentes}</label><br />
                  <label><strong>Eventos Rejeitados:</strong></label><label>{this.props.dados.evtRejeitados}</label><br />
                  <label><strong>Eventos Processados:</strong></label><label>{this.props.dados.evtProcessados}</label><br />
                </div>

                </TabPanel>
                <TabPanel header="Admissão" leftIcon="pi pi-user-plus" disabled={this.props.dados.hasAdmissao}>
                  <TabelaEvtAdmissao idObjeto={this.props.dados.id}/>
                </TabPanel>
                <TabPanel header="Afastamento" disabled={this.props.dados.hasAfastamento}>
                  <TabelaEvtAfastamento idObjeto={this.props.dados.id} leftIcon="pi pi-calendar-plus" />
                </TabPanel>
                <TabPanel header="Segurança do Trabalho" disabled={this.props.dados.hasEvtSaudeOcupacional} leftIcon="pi pi-briefcase" >
                  <TabelaEvtSegTrabalho idObjeto={this.props.dados.id} />
                </TabPanel>
                <TabPanel header="Desligamento" disabled={this.props.dados.hasDesligamento} leftIcon="pi pi-user-minus">
                    Content III
                </TabPanel>
            </TabView>
    
          </div>
        );
  }
  }
    
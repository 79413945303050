import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';

export class EditarUsuario extends Component {

    constructor() {
        super();
        this.state = {  lista: [],
                        visible: false, 
                        usuario: {}, 
                        nome: "", 
                        login: "", 
                        email: "", 
                        password: "", 
                        recebesms: false, 
                        whatsapp: false, 
                        telegram: false, 
                        grupo: null, 
                        grupos: [], 
                        visibleEditar: false,
                        idUsuario:null,
                        dataCadastro:"",
                        dataExpiracao:""
                    };

        this.renderFooter = this.renderFooter.bind(this);
        this.editarUsuario = this.editarUsuario.bind(this);
    }

    componentDidMount() {
        this.setState({ nome: this.props.nome });
        this.setState({ login: this.props.login });
        this.setState({ password: this.props.password });
        this.setState({ grupo: this.props.grupo });
        this.setState({ email: this.props.email });
        this.setState({ recebeSms: this.props.recebeSms });
        this.setState({ whatsapp: this.props.whatsapp });
        this.setState({ telegram: this.props.telegram });
        this.setState({ idUsuario: this.props.idUsuario  });
        this.setState({dataCadastro: this.props.dataCadastro});
        this.setState({dataExpiracao: this.props.dataExpiracao});
    }

    editarUsuario() {

        if(this.state.password!=null && this.state.password!=undefined && this.state.password!=''){
            var grupo = { idGrupo: this.state.grupo };
            var usuario = { idUsuario: this.state.idUsuario, 
                            nome: this.state.nome, 
                            login: this.state.login, 
                            grupo: grupo, 
                            email: this.state.email, 
                            password: this.state.password, 
                            recebeSms: true, 
                            whatsapp: this.state.whatsapp, 
                            telegram: this.state.telegram,
                            recebeEmail: true,
                            dataCadastro:this.state.dataCadastro,
                            dataExpiracao:this.state.dataExpiracao,
                        };
    
            var dados = JSON.stringify(usuario);
            console.log(dados);
            $.ajax({
              url: "/EsocialAPI/usuario/atualiza",
              type: "POST",
              contentType: "application/json; charset=utf-8",
              dataType: 'json',
              data: dados,
              error: function (erro) {
                console.log('Não sei o que rolou ainda')
              }.bind(this),
              success: function (resposta) {
                this.messages.show(resposta);
               console.log('Deletou')
              }.bind(this),
              beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
        }else{
            this.messages.show({ severity: 'error', summary: 'Senha é obrigatório! ', detail: ' Por favor, preencha.' });
        }
 
      }
    renderFooter() {

        const footer = (
            <div>
                <Button
                    id="btnEnviar"
                    label="Editar"
                    icon="pi pi-envelope"
                    onClick={(e) => this.editarUsuario()}
                />
                <Button
                    label="Não"
                    icon="pi pi-times"
                    onClick={(e) => console.log('fecha')}
                    className="p-button-secondary"
                />
            </div>
        );
        return footer;

    }
    render() {
        return (
            <div>

                <Button icon="pi pi-user-edit" className="p-button-rounded p-button-secondary" onClick={(e) => this.setState({ visibleEditar: true })} />

                <Dialog
                    header="Editar Usuário"
                    visible={this.state.visibleEditar}
                    modal={true}
                    style={{ width: '50vw' }}
                    onHide={() => this.setState({ visibleEditar: false })}
                    footer={this.renderFooter}
                >
                    <Messages ref={(el) => this.messages = el}></Messages>
                    <div className="pure-g">
                        <div className="pure-u-2-3">
                            <br />
                            <span className="p-float-label">
                                <InputText id="nomeCompleto" value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} />
                                <label htmlFor="nomeCompleto">Nome Completo</label>
                            </span>
                            <br />
                            <span className="p-float-label">
                                <InputText id="usuario" value={this.state.login} onChange={(e) => this.setState({ login: e.target.value })} />
                                <label htmlFor="usuario">Usuário</label>
                            </span>
                            <br />
                            <span className="p-float-label">
                                <Password id="senha" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                <label htmlFor="senha">Senha</label>
                            </span>
                            <br />
                            <Dropdown optionLabel="label" value={this.state.grupo} options={this.props.grupos} onChange={(e) => { this.setState({ grupo: e.value }) }} placeholder="Escolha o Grupo" />
                            <br />
                            <br />
                            <span className="p-float-label">
                                <InputText id="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                <label htmlFor="email">Email</label>
                            </span>
                            <br />
                            <Checkbox checked={this.state.recebeSms} onChange={e => this.setState({ recebesms: e.checked })} /> - SMS<br />
                            <Checkbox checked={this.state.whatsapp} onChange={e => this.setState({ whatsapp: e.checked })} /> - WhatsApp<br />
                            <Checkbox checked={this.state.telegram} onChange={e => this.setState({ telegram: e.checked })} /> - Telegram<br />
                        </div>
                        <div className="pure-u-1-3">
                            <p>Recebe SMS: <span style={{ fontWeight: 'bold' }}>{this.state.recebesms ? 'true' : 'false'}</span></p>
                            <p>Recebe WhatsApp: <span style={{ fontWeight: 'bold' }}>{this.state.whatsapp ? 'true' : 'false'}</span></p>
                            <p>Recebe Telegram: <span style={{ fontWeight: 'bold' }}>{this.state.telegram ? 'true' : 'false'}</span></p>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Badge } from 'primereact/badge';


const TblEvtIrrfBenef = (props) => {

    const [infoIrDTO, setInfoIrDTO] = useState([])
    const [totApurMenDTO, setTotApurMenDTO] = useState([])
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(100);

    useEffect(() => {
        setInfoIrDTO(props.relatorios.infoIrDTO);
        setTotApurMenDTO(props.relatorios.totApurMenDTO);
    }, [props])


    const [headerCsvInfoIR, setHeaderCsvInfoIR] = useState([
        { label: 'Matrícula', key: 'matricula' },
        { label: 'CPF', key: 'cpf' },
        { label: 'Nome', key: 'nome' },
        { label: 'tpInfoIRDesc', key: 'tpInfoIRDesc' },
        { label: 'valor', key: 'valor' },
    ]);

    const [headerCsvTotApurMen, setHeaderCsvTotApurMen] = useState([
        { label: 'Matrícula', key: 'matricula' },
        { label: 'CPF', key: 'cpf' },
        { label: 'Nome', key: 'nome' },
        { label: 'crMenDesc', key: 'crMenDesc' },
        { label: 'vlrCRMen', key: 'vlrCRMen' },
    ]);

    function geraNomeRelatorio(nome) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const nomeRelatorio = nome+ '_' + today.toISOString() + '.csv';
        return nomeRelatorio;
      }



    function onPage(event, values, setter) {
        console.log(event)
        setFirst2(event.first)
        const endIndex = event.first + event.rows;
        const newArray = [];
        for (let i = 0; i < values.length; i++) {
            if (i >= event.first && i < endIndex) {
                newArray.push(values[i])
            } 
        }
        setter(newArray);
    }

    function onChangePaginator(event, options, values, setter) {
        console.log(event);
        console.log(options.props);
        options.currentPage = event.value
        const startIndex = event.value * options.rows;
        const endIndex = startIndex + options.rows
        setFirst2(startIndex);

        const newArray = [];
        for (let i = 0; i < values.length; i++) {
            if (i >= startIndex && i < endIndex) {
                newArray.push(values[i])
            } 
        }
        setter(newArray);
    }
    const templatePaginatorInfoIrDTO = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        'CurrentPageReport': (options) => {
            const totalReg = options.totalRecords === null ? 0 : options.totalRecords
            const dropdownOptions = [];
            const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
            for (let i = 0; i <= totalPages; i++) {
                const item = {
                    label: i + 1,
                    value: i
                };

                dropdownOptions.push(item);
            }

            return (
                <>
                    <div className="p-grid">
                        <div className="p-col">
                            <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                            <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoIrDTO, setInfoIrDTO)} />
                        </div>
                    </div>
                </>
            )
        }
    };
    
    const templatePaginatorTotApurMenDTO = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        'CurrentPageReport': (options) => {
            const totalReg = options.totalRecords === null ? 0 : options.totalRecords
            const dropdownOptions = [];
            const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
            for (let i = 0; i <= totalPages; i++) {
                const item = {
                    label: i + 1,
                    value: i
                };

                dropdownOptions.push(item);
            }

            return (
                <>
                    <div className="p-grid">
                        <div className="p-col">
                            <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                            <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.totApurMenDTO, setTotApurMenDTO)} />
                        </div>
                    </div>
                </>
            )
        }
    };
    return (
        <div>
            <TabView>
                <TabPanel header="infoIR" onTabChange={(e) => setFirst2(1)}>
                    <h3>Rendimentos tributáveis, deduções, isenções e retenções do IRRF</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Rendimentos tributáveis, deduções, isenções e retenções do IRRF
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoIrDTO.length > 0 ? <BtnExportCsv data={props.relatorios.infoIrDTO} header={headerCsvInfoIR} fileName={geraNomeRelatorio('5002_infoIR_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={infoIrDTO}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            loading={false}
                            totalRecords={props.relatorios.infoIrDTO.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoIrDTO}
                            first={first2}
                            rows={rows2}
                            onPage={(e)=>onPage(e, props.relatorios.infoIrDTO, setInfoIrDTO)}
                            paginatorClassName="p-jc-end"
                            setter={setInfoIrDTO}
                            values={props.relatorios.infoIrDTO}
                        >
                            <Column field="matricula" header="Matrícula" style={{ width: '1px' }} />
                            <Column field="cpf" header="CPF" style={{ width: '0.5em' }} />
                            <Column field='nome' header="Nome" style={{ width: '0.5em' }} />
                            <Column field='tpInfoIRDesc' header="tpInfoIRDesc" style={{ width: '0.5em' }} />
                            <Column field='valor' header="valor" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="totApurMen" onTabChange={(e) => setFirst2(1)}>
                    <h3>Totalizador de tributos com período de apuração mensal</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Totalizador de tributos com período de apuração mensal.
                    </p>
                    <div className="content-section implementation">
                    {props.relatorios.totApurMenDTO.length > 0 ? <BtnExportCsv data={props.relatorios.totApurMenDTO} header={headerCsvTotApurMen} fileName={geraNomeRelatorio('5002_totApurMen_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={totApurMenDTO}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            loading={false}
                            totalRecords={props.relatorios.totApurMenDTO.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorTotApurMenDTO}
                            first={first2}
                            rows={rows2}
                            onPage={(e)=>onPage(e, props.relatorios.totApurMenDTO, setTotApurMenDTO)}
                            paginatorClassName="p-jc-end"
                        >
                            <Column field="matricula" header="Matrícula" style={{ width: '1px' }} />
                            <Column field="cpf" header="CPF" style={{ width: '0.5em' }} />
                            <Column field='nome' header="Nome" style={{ width: '0.5em' }} />
                            <Column field='crMenDesc' header="crMenDesc" style={{ width: '0.5em' }} />
                            <Column field='vlrCRMen' header="vlrCRMen" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>

        </div>

    )
}

export default TblEvtIrrfBenef;
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Button } from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import {Steps} from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';


export class WizardImportacao extends Component{
    constructor(){
      super();
      this.state={
        display:'none',
        qtdServidores:0,
        mesesSlt:[],
        confirmacao:'block', 
        execucao:'none',
        load:'block',
        loadFolhasEnviadas:'block',
        perApur:null,
        perApurs:[],
        selecionado:null,
        selecionadoObj:{label:''},
        indApuracao:null,
        prds:[],
        BolResumo:'none',
        noPrd:'block',
        evtRemun:0,
        evtRmnRPPS:0,
        evtPgtos:0,
        dvAlertaResponsavelTecnico:'',
        evtInfoComplPer:0,
        evtTotConting:0,
        evtReabreEvPer:0,
        evtFechaEvPer:0,
        qtd:0,
        activeStep:0,
        respTecnico:{nome:'',email:'',telefone:'',cpf:'',id:0},
        nome:'',
        email:'',
        cpf:'',
        telefone:'',
        proximoDesabilita:false,
        anteriorDesabilita:true
      };
      this.onApurChange = this.onApurChange.bind(this);
      this.classificaQuantidade = this.classificaQuantidade.bind(this);
      this.anteriorStep = this.anteriorStep.bind(this);
      this.proximoStep = this.proximoStep.bind(this);
      this.calculaQtdEventos = this.calculaQtdEventos.bind(this);
      this.importarFolha = this.importarFolha.bind(this);
    }
    componentDidMount(){  
        //this.setState({qtdServidores:this.props.qtd});
        this.consultaFolhasDisponiveis();
    }
    calculaQtdEventos(){
      try{
        let qtd = this.state.evtRemun + this.state.evtRmnRPPS + this.state.evtPgtos + this.state.evtInfoComplPer + this.state.evtTotConting + this.state.evtReabreEvPer + this.state.evtFechaEvPer;
        console.log(this.state.evtRemun);
        return (
          <em>{this.state.qtd}</em>
          );
      }catch(error){
        console.log(error);
      }
      return 0;
    }
    mostraSelecao(event){
      console.log(event.value);
      this.setState({sessao: event.value});
    }
    consultaFolhasDisponiveis(){
      $.ajax({      
        url:"/EsocialAPI/folha/importacao/cache",
        dataType: 'json',
        success:function(retorno){
          console.log(retorno);
          this.setState({perApur:retorno,loadFolhasEnviadas:'none'});
          let periodos = retorno.map(function(d){
            let mes = new Date(d.perApur).getMonth()+1;
            let ano = new Date(d.perApur).getFullYear();
            return d;
          });
          console.log(periodos);
          this.setState({prds:periodos});
          this.renderizarDatas();
          //this.setState({items : retorno});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    importarFolha(){
      try{
        let mesAno =this.state.selecionado;
        if(mesAno===null){
          mesAno="0";
        }
        let ano = "";
        let mes = "";
        if(mesAno.length===6){
          ano = mesAno.substr(2, 4);
          mes = mesAno.substr(0, 1);
          mes = '0'+mes;
          console.log('Ano: '+ano);
          console.log('Mês: '+mes);
        }
        if(mesAno.length===7){
          ano = mesAno.substr(3, 4);
          mes = mesAno.substr(0, 2);
          mes = '0'+mes;
          console.log('Ano: '+ano);
          console.log('Mês: '+mes);
        }
        let dados = JSON.stringify({mes:mes,ano:ano,responsavel:this.state.respTecnico,idServidorCache:this.state.respTecnico.id,indApuracao:this.state.indApuracao});
        $.ajax({      
          url:"/EsocialAPI/folha/importacaoCache/wizard",
          data: dados,
          method:'post',
          contentType: "application/json",
          success:function(retorno){
            console.log(retorno);
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }catch(error){
        console.log(error);
      }
      
    }
    showMessage(message) {
      this.messages.show({ severity: message.severity, summary: message.summary, detail: message.detail });
    }
    onApurChange(e) {
      let selectedApur = [...this.state.perApurs];
      if(e.checked)
        selectedApur.push(e.value);
      else
        selectedApur.splice(selectedApur.indexOf(e.value), 1);
  
      this.setState({perApurs: selectedApur});
  }
    showMeses(){
      var meses = this.props.meses;
      return (<div>
        {meses.map((mes, index) => (
            <label key={index} style={{marginLeft:'15px'}}>{mes} </label>
        ))}
        </div>);
    }
    imprimeMesApur(dataApur,index){
      let mes = new Date(dataApur).getMonth()+1;
      let ano = new Date(dataApur).getFullYear();
      let perApurComp = mes.toString()+"/"+ano.toString();
      return (
        <div key={index} className="p-col-4">
          <Checkbox inputId={'cb'+index.toString()} onChange={this.onApurChange} value={perApurComp} checked={this.state.perApurs.includes(perApurComp)}></Checkbox>
          <label htmlFor={index} key={index} className="p-checkbox-label">{perApurComp}</label>
        </div>
      );
    }
    onPrdChange(e){
      console.log(e.value);
      this.setState({qtd:0});
      $('#div_resumo').fadeOut();
      if(e.value.perApur!==null){
        this.carregaPreview(e.value.perApur);
        $('#div_resumo').fadeIn();
      }else{
        $('#div_resumo').fadeOut();
        $('#div_none').fadeIn();
      }
      console.log(e.value);
      let mes = new Date(e.value.perApur).getMonth()+1;
      let ano = new Date(e.value.perApur).getFullYear();
      let mesAno = mes.toString()+"/"+ano.toString();
      this.setState({noPrd:'none',selecionado:mesAno,indApuracao:e.value.indApuracao,selecionadoObj:e.value});
    }
    carregaEventoFechamento(){
      try{
        let mesAno =this.state.selecionado;
        let ano = "";
        let mes = "";
        if(mesAno.length===6){
          ano = mesAno.substr(2, 4);
          mes = mesAno.substr(0, 1);
          mes = '0'+mes;
          console.log('Ano: '+ano);
          console.log('Mês: '+mes);
        }
        if(mesAno.length===7){
          ano = mesAno.substr(3, 4);
          mes = mesAno.substr(0, 2);
          mes = '0'+mes;
          console.log('Ano: '+ano);
          console.log('Mês: '+mes);
        }
       let url = "/EsocialAPI/folha/eventofechamento/"+mes+"/"+ano;
       $.ajax({      
          url: url,
          dataType: 'json',
          success:function(retorno){
              let resp={id:retorno.id,nome:retorno.nome,email:retorno.email,telefone:retorno.telefone,cpf:retorno.cpf};
              this.setState({respTecnico:resp});
              console.log(retorno);
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }catch(error){
        console.log(error);
      }
    }
    carregaPreview(e){
      console.log(e);
      let mes = new Date(e).getMonth()+1;
      let ano = new Date(e).getFullYear();
      let mesAno = mes.toString()+"/"+ano.toString();;
      let url = "/EsocialAPI/folha/importacao/detalhes/"+mesAno;
      this.setState({evtRemun:0,evtRmnRPPS:0,evtPgtos:0,evtInfoComplPer:0,evtTotConting:0,evtReabreEvPer:0,evtFechaEvPer:0,loadFolhasEnviadas:'block'});
      $.ajax({      
        url:"/EsocialAPI/folha/importacao/detalhes/"+mesAno,
        dataType: 'json',
        success:function(retorno){
          this.classificaQuantidade(retorno);
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
    classificaQuantidade(result){
      try{
        console.log(result);
          result.map(function (linha){
            console.log(linha);
            if(linha.codigo===1200){
              this.setState({evtRemun:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            if(linha.codigo===1202){
              this.setState({evtRmnRPPS:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            if(linha.codigo===1210){
              this.setState({evtPgtos:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            if(linha.codigo===1280){
              this.setState({evtInfoComplPer:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            if(linha.codigo===1295){
              this.setState({evtTotConting:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
              if(linha.quantidade===0){
                this.setState({evtTotConting:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
              }
            }
            if(linha.codigo===1298){
              this.setState({evtReabreEvPer:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            if(linha.codigo===1299){
              this.carregaEventoFechamento();
              this.setState({evtFechaEvPer:linha.quantidade,qtd:this.state.qtd+linha.quantidade});
            }
            this.setState({loadFolhasEnviadas:'none'});
            this.calculaQtdEventos();
          }.bind(this));
      }catch(e){
        console.log(e);
      }
    }
    renderizarDatas(){
      let retorno ;
      try{
        let perApur = this.state.perApur;
        if(perApur!==null){
          return(<div>
            {perApur.map((mes, index) => (
              this.imprimeMesApur(mes,index)            
          ))}
          </div>);
          
        }else{
          retorno = (<div><em>Nenhuma folhar registrada</em></div>);
        }
        return retorno;
      }catch(e){
        console.log(e);
      }

    }
    calculaQtdEventos(){
        try{
          let retorno = this.state.qtdServidores*2;
          let qtdMeses =this.props.meses;
          return retorno*qtdMeses;
        }catch(err){
          console.log('Desculpe houve um erro ao calcular!');
        }
    }
    proximoStep(){
      try{
        let ativo = this.state.activeStep;
        if(ativo < 2){
          ativo = ativo+1;
          this.setState({activeStep:ativo});
          this.ativaDesativaDiv(ativo);
        }else{
          this.setState({activeStep:ativo+1,proximoDesabilita:true});          
          this.ativaDesativaDiv(ativo+1);
        }
        if(ativo>0){
          this.setState({anteriorDesabilita:false});          
        }
        console.log(ativo);
      }catch(erro){
        console.log(erro);
      }
    }
    ativaDesativaDiv(index){
      try{
        $('#divSelecionaPeriodo').fadeOut();
        $('#divResponsavel').fadeOut();
        $('#divResumo').fadeOut();
        $('#divFim').fadeOut();
        if(index===0){
          console.log("Folha");
          $('#divSelecionaPeriodo').fadeIn();
        }
        if(index===1){
          console.log("Responsável");
          $('#divResponsavel').fadeIn();
        }
        if(index===2){
          console.log("Resumo");
          $('#divResumo').fadeIn();
        }
        if(index===3){
          console.log("Fim");
          $('#divFim').fadeIn();
        }
      }catch(error){
        console.log(error);
      }

    }
    anteriorStep(){
      try{
        let ativo = this.state.activeStep;
        if(ativo > 1){
          ativo = ativo-1;
          this.setState({activeStep:ativo});
          this.ativaDesativaDiv(ativo);
        }else{
          this.setState({activeStep:ativo-1,anteriorDesabilita:true});          
          this.ativaDesativaDiv(ativo-1);
        }
        if(ativo<2){
          this.setState({proximoDesabilita:false});          
        }
        console.log(ativo);

      }catch(erro){
        console.log(erro);
      }
    }
    setarResponsavelTecnico(){
      if(this.state.evtFechaEvPer>0){
        return (
          <div style={{textAlign:'justify', border:'1px solid #ccc',padding:'8px 8px 8px 8px'}}>
            <h5>Responsável técnico para envio da folha.</h5>
            <hr />
            <div style={{display:'inline-block'}}>
              <label>Nome: </label><br />
              <label>CPF: </label><br />
              <label>Telefone: </label><br />
              <label>Email: </label>
            </div>
            <div style={{display:'inline-block'}}>
              <label>{this.state.respTecnico.nome}</label><br />
              <label>{this.state.respTecnico.cpf}</label><br />
              <label>{this.state.respTecnico.telefone}</label><br />
              <label>{this.state.respTecnico.email}</label>
            </div>
          </div>
        );
      }else{
        return (
          <div style={{textAlign:'justify', border:'1px solid #ccc',padding:'8px 8px 8px 8px'}}>
            <h5>Responsável técnico para envio da folha.</h5>
            <hr />
            <div style={{display:'inline-block'}}>
              <label>Nome: </label><br />
              <label>CPF: </label><br />
              <label>Telefone: </label><br />
              <label>Email: </label>
            </div>
            <div style={{display:'inline-block'}}>
              <InputText value={this.state.nome} onChange={(e) => this.setState({nome:e.value})} /><br />
              <InputMask mask="999.999.999-99" value={this.state.cpf} onChange={(e) => this.setState({cpf:e.value})} /><br />
              <InputMask mask="(99) 9 9999-9999" value={this.state.telefone} onChange={(e) => this.setState({telefone:e.value})} /><br />
              <InputText value={this.state.email} onChange={(e) => this.setState({email:e.value})} /><br />
            </div>
          </div>
        );
      }

    }
    render() {
      const items = [
        {label: 'Folha'},
        {label: 'Responsável'},
        {label: 'Resumo'},
        {label: 'Fim'}
      ];
      return(
        <div>
          <div>
            <Steps model={items}  activeIndex={this.state.activeStep}/>
          </div>
        
        <hr />
        <div>
          <ProgressBar mode="indeterminate" style={{display:this.state.loadFolhasEnviadas}} />
          <div className="pure-g" id="divSelecionaPeriodo"> 
            <div className="pure-u-3-8" style={{borderRight:'1px'}}>
              <em>Selecione as folhas de pagamento ja enviadas pela versão anterior da mensageria.</em><br />
              <Dropdown value={this.state.selecionadoObj} options={this.state.prds} optionLabel="label" onChange={(e) => this.onPrdChange(e)} placeholder="Selecione uma folha"/>
            </div>
            <div className="pure-u-5-8">
              <div id="div_resumo" style={{display:this.state.BolResumo}}>
                <h4>Período de Apuração: {this.state.selecionado} - {this.state.indApuracao}</h4>
                <label>1200 - Evento remuneração de trabalhador vinculado ao Regime Geral de Previdência Social:</label><strong>{this.state.evtRemun}</strong><br />
                <label>1202 - Remuneração de servidor vinculado a Regime Próprio de Previd. Social :</label><strong>{this.state.evtRmnRPPS}</strong><br />
                <label>1210 - Evento pagtos. de rendimentos do trabalho :</label><strong>{this.state.evtPgtos}</strong><br />
                <label>1280 - Informações Complementares aos Eventos Periódicos :</label><strong>{this.state.evtInfoComplPer}</strong><br />
                <label>1295 - Solicitação de Totalização para Pagamento em Contingência:</label><strong>{this.state.evtTotConting}</strong><br />
                <label>1298 - Reabertura dos Eventos Periódicos :</label><strong>{this.state.evtReabreEvPer}</strong><br />
                <label>1299 - Fechamento dos Eventos Periódicos :</label><strong>{this.state.evtFechaEvPer}</strong>
              </div>
              <div id="div_none" style={{display:this.state.noPrd}}>
                <strong>Nenhuma folha selecionada.</strong>
              </div>
            </div>
          </div>
          <div style={{display:'none'}} id="divResponsavel">
            {this.setarResponsavelTecnico()}
          </div>
          <div style={{display:'none'}} id="divResumo">
            <div style={{textAlign:'center'}}>
              <strong>Deseja importar {this.state.qtd} eventos para a mensageria?</strong>
              <div>
                <Button icon="pi pi-plus" label="Sim" style={{marginRight:'12px'}}className="p-button-secondary" onClick={this.importarFolha}  />
                <Button icon="pi pi-times-circle" label="Não" style={{marginLeft:'12px'}} className="p-button-danger" onClick={this.anteriorStep}/>
              </div>
            </div>
          </div>
          <div style={{display:'none'}} id="divFim">
            <em><h4>Parabéns!</h4> a folha de pagamento {this.state.selecionadoObj.label} foi importada com sucesso com o Status FECHADA.</em>
          </div>
          <div style={{margin:'12px 12px 12px 12px'}}>
            <Button icon="pi pi-arrow-left"  label="Anterior" disabled={this.state.anteriorDesabilita} style={{float:'left'}} onClick={this.anteriorStep} />
            <Button icon="pi pi-arrow-right"  label="Próximo" disabled={this.state.proximoDesabilita} style={{float:'right'}} onClick={this.proximoStep}/>
          </div>
        </div>
      </div>
    );
}
}

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';


const TblEvtCS = (props) => {

  const [infoCPSegDTOs, setInfoCPSegDTOs] = useState([])
  const [basesRemunDTOs, setBasesRemunDTOs] = useState([])
  const [infoCREstabDTOs, setInfoCREstabDTOs] = useState([])
  const [infoCRContribDTOs, setInfoCRContribDTOs] = useState([])
  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(100);

  useEffect(() => {
    setInfoCPSegDTOs(props.relatorios.infoCPSegDTOs);
    setBasesRemunDTOs(props.relatorios.basesRemunDTOs);
    setInfoCREstabDTOs(props.relatorios.infoCREstabDTOs);
    setInfoCRContribDTOs(props.relatorios.infoCRContribDTOs);
  }, [props])


  const [headerCsvInfoCPSegDTOs, setHeaderCsvInfoCPSegDTOs] = useState([
    { label: 'vrDescCP', key: 'vrDescCP' },
    { label: 'vrCpSeg', key: 'vrCpSeg' }
  ]);

  const [headerCsvBasesRemunDTOs, setHeaderCsvBasesRemunDTOs] = useState([
    { label: 'codCateg', key: 'codCateg' },
    { label: 'vrBcCp00', key: 'vrBcCp00' },
    { label: 'vrBcCp15', key: 'vrBcCp15' },
    { label: 'vrBcCp20', key: 'vrBcCp20' },
    { label: 'vrBcCp25', key: 'vrBcCp25' },
    { label: 'vrSalMat', key: 'vrSalMat' }
  ]);

  const [headerCsvInfoCREstabDTOs, setHeaderCsvInfoCREstabDTOs] = useState([
    { label: 'tpCR', key: 'tpCR' },
    { label: 'vrCR', key: 'vrCR' },
    { label: 'vrSuspCR', key: 'vrSuspCR' }
  ]);

  const [headerCsvInfoCRContribDTOs, setHeaderCsvInfoCRContribDTOs] = useState([
    { label: 'tpCR', key: 'tpCR' },
    { label: 'vrCR', key: 'vrCR' },
    { label: 'vrSuspCR', key: 'vrSuspCR' }
  ]);

  function geraNomeRelatorio(nome) {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nomeRelatorio = nome+ '_' + today.toISOString() + '.csv';
    return nomeRelatorio;
  }



  function onPage(event, values, setter) {
    console.log(event)
    setFirst2(event.first)
    const endIndex = event.first + event.rows;
    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= event.first && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }

  function onChangePaginator(event, options, values, setter) {
    console.log(event);
    console.log(options.props);
    options.currentPage = event.value
    const startIndex = event.value * options.rows;
    const endIndex = startIndex + options.rows
    setFirst2(startIndex);

    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= startIndex && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }
  const templatePaginatorInfoCPSeg = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCPSegDTOs, setInfoCPSegDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorBasesRemun = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.basesRemunDTOs, setBasesRemunDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorInfoCREstab = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCREstabDTOs, setInfoCREstabDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorInfoCRContrib = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCRContribDTOs, setInfoCRContribDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

    return (
        <div>
            <TabView>
                <TabPanel header="infoCPSeg">
                    <h3>Informações de contribuição previdenciária do segurado</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações de contribuição previdenciária do segurado.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCPSegDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCPSegDTOs} header={headerCsvInfoCPSegDTOs} fileName={geraNomeRelatorio('5011_infoCPSeg_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={infoCPSegDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCPSegDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCPSeg}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCPSegDTOs, setInfoCPSegDTOs)}
                        >
                            <Column field="vrDescCP" header="vrDescCP" style={{ width: '1px' }} />
                            <Column field="vrCpSeg" header="vrCpSeg" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="basesRemun">
                    <h3>Bases de cálculo por categoria</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Bases de cálculo da contribuição previdenciária incidente sobre remunerações, por categoria.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.basesRemunDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.basesRemunDTOs} header={headerCsvBasesRemunDTOs} fileName={geraNomeRelatorio('5011_basesRemun_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={basesRemunDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.basesRemunDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorBasesRemun}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.basesRemunDTOs, setBasesRemunDTOs)}
                        >
                            <Column field="codCateg" header="codCateg" style={{ width: '1px' }} />
                            <Column field="vrBcCp00" header="vrBcCp00" style={{ width: '0.5em' }} />
                            <Column field='vrBcCp15' header="vrBcCp15" style={{ width: '0.5em' }} />
                            <Column field='vrBcCp20' header="vrBcCp20" style={{ width: '0.5em' }} />
                            <Column field='vrBcCp25' header="vrBcCp25" style={{ width: '0.5em' }} />
                            <Column field='vrSalMat' header="vrSalMat" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="infoCREstab">
                    <h3>Códigos de Receita por estabelecimento</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações das contribuições sociais devidas à Previdência Social e Outras Entidades e Fundos, consolidadas por estabelecimento e por Código de Receita - CR.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCREstabDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCREstabDTOs} header={headerCsvInfoCREstabDTOs} fileName={geraNomeRelatorio('5011_infoCREstab_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={infoCREstabDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCREstabDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCREstab}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCREstabDTOs, setInfoCREstabDTOs)}
                        >
                            <Column field="tpCR" header="tpCR" style={{ width: '1px' }} />
                            <Column field="vrCR" header="vrCR" style={{ width: '0.5em' }} />
                            <Column field='vrSuspCR' header="vrSuspCR" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="infoCRContrib">
                    <h3>Totalizador dos Códigos de Receita do contribuinte</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações consolidadas das contribuições sociais devidas à Previdência Social e Outras Entidades e Fundos, por Código de Receita - CR.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCRContribDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCRContribDTOs} header={headerCsvInfoCRContribDTOs} fileName={geraNomeRelatorio('5011_infoCRContrib_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <DataTable
                            value={infoCRContribDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCRContribDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCRContrib}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCRContribDTOs, setInfoCRContribDTOs)}
                        >
                            <Column field="tpCR" header="tpCR" style={{ width: '1px' }} />
                            <Column field="vrCR" header="vrCR" style={{ width: '0.5em' }} />
                            <Column field='vrCRSusp' header="vrCRSusp" style={{ width: '0.5em' }} />
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>

        </div>
    )
}

export default TblEvtCS;
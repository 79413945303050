import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import {PermissionCheck} from '../componentes/PermissionCheck';
import {Panel} from 'primereact/panel';
import {Inplace,InplaceDisplay,InplaceContent} from 'primereact/inplace';
import {InputText} from 'primereact/inputtext';
import {TabView,TabPanel} from 'primereact/tabview';
import $ from 'jquery';
import { Calendar } from 'primereact/calendar';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputSwitch} from 'primereact/inputswitch';
import { Requisito } from './Catalogo/Requisito';
import { Button } from 'primereact/button';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class Catalogo extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"1",
    catalogo:{idcatalogo:1,versao:"",vigente:true,inicioVigencia:"",fimVigencia:""}    
    ,txtCatalogo:"",dtInicio:"",dtFim:"",txtNatureza:"",txtStatus:"",txtDescricao:""
    ,tabela:null,nperiodico:null,periodico:null,tabAtiva:'',usuario:{},qtdeMensagens:'',catalogos:null,btnCatalogoAtivo:true,txtNotaTecnica:''
  };

    this.atualizaEvento = this.atualizaEvento.bind(this);
    this.importacaoCheck = this.importacaoCheck.bind(this);
    this.consultaUsuario=this.consultaUsuario.bind(this);
    this.consultaCatalogos=this.consultaCatalogos.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
  }
  componentDidMount(){
      this.atualizaEventos();
      this.consultaUsuario();
      this.consultaCatalogos();
  }
  classificaTipo(tipoEvento){
    //console.log(tipoEvento.nomeTipo);
  }
  consultaCatalogos(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url:"/EsocialAPI/catalogo/lista",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        let vigente = this.retornaVigente(resposta);
        console.log(vigente);
        this.setState({catalogos:resposta,catalogo:vigente});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  retornaVigente(catalogos){
    let retorno = {};
    catalogos.map(function(obj, index){
      if(obj.vigente===true){
        retorno = obj;
      }
    });
    return retorno;
  }
  atualizaEventos(){
    var url = "/EsocialAPI/catalogo/vigente";
    $.ajax({
      url:url,
      dataType: 'json',
      success:function(resposta){
        this.setState({catalogo:resposta});
        //console.log(resposta.tipos);
        if(resposta.tipos!==null){
          resposta.tipos.map(function(tipo,index){
            if(tipo.nomeTipo==='TABELA'){
                this.setState({tabela:tipo.eventos});
            }
            if(tipo.nomeTipo==='NÃO_PERIÓDICO'){
                this.setState({nperiodico:tipo.eventos});
            }
            if(tipo.nomeTipo==='PERIÓDICO'){
                this.setState({periodico:tipo.eventos});
            }
        }.bind(this));
        }

      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  actionTemplate(rowData, column) {
    let retorno = '';
      retorno = (
        <div>
            <Requisito idEvento={rowData['idEvento']}  />            
        </div>
      )
    return retorno
  }
  importacaoCheck(rowData, props){
    let retorno = '';
    retorno=(
      <div>
        <InputSwitch checked={rowData['isImportAtivo']} onChange={(e) => this.atualizaEvento(rowData.idEvento,e.value)} />
      </div>
    )
    return retorno;
  };
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  qtdeMensagens(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    let us = this.state.usuario.idUsuario;
    if((us!==null)&&(us!==undefined)){
      $.ajax({
        url:"/EsocialAPI/usuario/mensagens/"+this.state.usuario.idUsuario,
        dataType: 'json',
        success:function(resposta){
          this.setState({qtdeMensagens:resposta.length.toString()});
  
        }.bind(this),
        error:function(error){
          console.log(error);
          window.location.href= baseurl;
        },
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      }); 
    }else{
      console.log('Não recebi usuario');
      console.log(this.props.usuario);
    }
  }
  renderData(rowData){
    try{
      let inicioVigencia = rowData.inicioVigencia;
      let fimVigencia = rowData.fimVigencia;
      let dataEmissao = "";
      if(inicioVigencia!==null){
        dataEmissao = new Date(inicioVigencia);
        return dataEmissao.toLocaleString();
      }
      if(fimVigencia!==null){
        dataEmissao = new Date(fimVigencia);
        return dataEmissao.toLocaleString();
      }
      return dataEmissao;
    }catch(erro){
      console.log(erro);
      return "";
    }
  }
  renderStatus(rowData){
    if(rowData!==null){
      if(rowData.vigente){
        return (
          <Button tooltip="Ativo" className="p-button-sm">
            <FontAwesomeIcon icon={faCheckSquare} style={{ margin: '3px' }} />
          </Button>
        );
      }else{
        return "";

      }
    }

  }
  ativaCatalogo(){
    var url = "/EsocialAPI/catalogo/ativar/"+this.state.catalogo.idCatalogo;
    $.ajax({
      url:url,
      dataType: 'json',
      success:function(resposta){
        this.messages.show(resposta);
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });    
  }
  atualizaEvento(idEvento,statusEvento){
    var url = "/EsocialAPI/evento/editar/habilitai/"+idEvento+"/"+statusEvento;
    //console.log(url);
    $.ajax({
      url:url,
      dataType: 'json',
      success:function(resposta){
        //console.log(resposta.mensagem);
        this.messages.show(resposta.mensagem);
        this.atualizaEventos();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  } 
  onSelectCatalogChange(catalogo){
    try{
      this.setState({catalogo: catalogo});
      if(catalogo.vigente===false){
        this.setState({btnCatalogoAtivo:false});
      }else{
        this.setState({btnCatalogoAtivo:true});
      }
      catalogo.tipos.map(function(tipo,index){
        if(tipo.nomeTipo==='TABELA'){
            this.setState({tabela:tipo.eventos});
        }
        if(tipo.nomeTipo==='NÃO_PERIÓDICO'){
            this.setState({nperiodico:tipo.eventos});
        }
        if(tipo.nomeTipo==='PERIÓDICO'){
            this.setState({periodico:tipo.eventos});
        }
    }.bind(this));
    }catch(error){
      console.log(error);
    }
  }
  onEditorValueChangeForRowEditing(props, value) {
    let updatedCars = [...props.value];
    updatedCars[props.rowIndex][props.field] = value;
    this.setState({cars2: updatedCars});
  }
  render() {
    return(
      <div>
        <MenuBar usuario={this.state.usuario} qtdeMensagens={this.state.qtdeMensagens}/>
        <div style={{marginTop:'25px'}}>
        <Messages ref={(el) => this.messages = el}></Messages>
          <div>
            <DataTable value={this.state.catalogos} header="Catalogos registrados" 
            selectionMode="single" selection={this.state.catalogo} onSelectionChange={e => this.onSelectCatalogChange(e.value)}
            className="p-datatable-sm">
              <Column field="idCatalogo" header="id" style={{width:'3%'}}></Column>
              <Column field="versao" header="versão"></Column>
              <Column field="notaTecnica" header="Nota técnica"></Column>
              <Column field="inicioVigencia" header="Início vigência" body={this.renderData}></Column>
              <Column field="fimVigencia" header="Fim vigência" body={this.renderData}></Column>
              <Column field="vigente" header="Vigente" body={this.renderStatus}></Column>
            </DataTable>
          </div>
          <div style={{textAlign:'right',marginTop:'22px' }}>
            <Button label="Ativar" disabled={this.state.btnCatalogoAtivo} onClick={(e)=> this.ativaCatalogo()}  />
          </div>
        </div>
        <hr />
        <div className="content">
          <div >
            <Panel header="Catálogo Vigente" style={{marginTop:'35px'}}>
              <div className="pure-u-3-3">
                <em>Clique para editar</em>
              </div>
              <div className="pure-u-1-3">                
                <Inplace active={this.state.activeE} onToggle={(e) => this.setState({activeE: e.value})}>               
                    <InplaceDisplay>
                    <label>Catalogo: &nbsp;</label>{this.state.catalogo.versao}
                    </InplaceDisplay>
                    <InplaceContent>
                    <label>Catalogo: &nbsp;</label>
                    <InputText value={this.state.catalogo.versao} onChange={(e) => this.setState({txtCatalogo: e.target.value})}  />
                    </InplaceContent>
                </Inplace>
                <Inplace active={this.state.activeC} onToggle={(e) => this.setState({activeC: e.value})}>               
                    <InplaceDisplay>
                    <label>Inicio da Vigência: &nbsp;</label>{new Date(this.state.catalogo.inicioVigencia).toLocaleDateString()}
                    </InplaceDisplay>
                    <InplaceContent>
                        <label>Inicio da Vigência: &nbsp;</label>
                        <Calendar dateFormat="dd/mm/yy" value={new Date(this.state.catalogo.inicioVigencia)} onChange={(e) => this.setState({dtInicio: e.target.value})}></Calendar>
                    </InplaceContent>
                </Inplace>
                <Inplace active={this.state.activeG} onToggle={(e) => this.setState({activeG: e.value})}>               
                    <InplaceDisplay>
                    <label>Fim da Vigência: &nbsp;</label>{new Date(this.state.catalogo.fimVigencia).toLocaleDateString()}
                    </InplaceDisplay>
                    <InplaceContent>
                        <label>Fim da Vigência: &nbsp;</label>
                        <Calendar dateFormat="dd/mm/yy" value={new Date(this.state.catalogo.fimVigencia)} onChange={(e) => this.setState({dtFim: e.target.value})}></Calendar>
                    </InplaceContent>
                </Inplace>
              </div>
              <div className="pure-u-1-3">
                <Inplace active={this.state.activeN} onToggle={(e) => this.setState({activeN: e.value})}>               
                    <InplaceDisplay>
                    <label>Nota Técnica: &nbsp;</label>{this.state.catalogo.notaTecnica}
                    </InplaceDisplay>
                    <InplaceContent>
                    <label>Nota Técnica: &nbsp;</label>
                    <InputText value={this.state.catalogo.notaTecnica} onChange={(e) => this.setState({txtNotaTecnica: e.target.value})}  />
                    </InplaceContent>
                </Inplace>
              </div>
              <div className="pure-u-1-3" style={{verticalAlign:'bottom'}}>
                <Button label="Salvar" icon="pi pi-save" disabled={true} />
              </div>
                <hr />
              <div className="pure-u-3-3">
                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <TabPanel header="Tabela">
                        <DataTable value={this.state.tabela} emptyMessage="O evento não possui histórico" scrollable={true} scrollHeight="200px" sortField="idEvento" sortOrder="1"  >
                            <Column field="idEvento" header="id" style={{width:'3%',textAlign:'center'}} sortable={true}/>
                            <Column field="codigoEvento" header="Cod.Evento" style={{width:'10%',textAlign:'center'}} />
                            <Column field="evento" header="Nome"/>
                            <Column header="Importação" style={{width:'10%',textAlign:'center'}} body={this.importacaoCheck}/>
                            <Column header="Ação" style={{width:'10%',textAlign:'center'}} body={this.actionTemplate}/>
                        </DataTable>

                    </TabPanel>
                    <TabPanel header="Não Periódico">
                        <DataTable value={this.state.nperiodico} emptyMessage="O evento não possui histórico" scrollable={true} scrollHeight="200px" sortField="idEvento" sortOrder="1" >
                            <Column field="idEvento" header="id" style={{width:'3%',textAlign:'center'}} sortable={true}/>
                            <Column field="codigoEvento" header="Cod.Evento" style={{width:'10%',textAlign:'center'}} />
                            <Column field="evento" header="Nome"/>
                            <Column header="Importação" style={{width:'10%',textAlign:'center'}} body={this.importacaoCheck}/>
                            <Column header="Ação" style={{width:'10%',textAlign:'center'}} body={this.actionTemplate}/>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Periódico">
                        <DataTable value={this.state.periodico} emptyMessage="O evento não possui histórico" scrollable={true} scrollHeight="200px" sortField="idEvento" sortOrder="1"  >
                          <Column field="idEvento" header="id" style={{width:'3%',textAlign:'center'}} sortable={true}  />
                          <Column field="codigoEvento" header="Cod.Evento" style={{width:'10%',textAlign:'center'}} />
                          <Column field="evento" header="Nome"/>
                          <Column header="Importação" style={{width:'10%',textAlign:'center'}} body={this.importacaoCheck}/>
                          <Column header="Ação" style={{width:'10%',textAlign:'center'}} body={this.actionTemplate}/>
                        </DataTable>
                    </TabPanel>
                </TabView>
              </div>
            </Panel>
          </div>
            </div>
            <div className="footer l-box is-center">
                <PermissionCheck usuario={this.state.usuario}  />
            </div>

      </div>
    );
  }
}

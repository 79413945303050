import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {Accordion,AccordionTab} from 'primereact/accordion';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import{ AmbienteConfig } from '../sessoes/Configuracoes/Ambiente';
import{ DBConfig } from '../sessoes/Configuracoes/DbConfig';
import{MensageriaConfig } from '../sessoes/Configuracoes/MensageriaConfig';
import{ EmailConfig } from '../sessoes/Configuracoes/EmailConfig';
import {Steps} from 'primereact/steps';
import {Panel} from 'primereact/panel';
import {PermissionCheck} from '../componentes/PermissionCheck';
import { TabView, TabPanel } from 'primereact/tabview';

export class Configuracao extends Component{
  constructor(){
    super();
    this.state={
                lista : []
                ,configuracoes:[]
                ,configuracoesAmbiente:{}
                ,configuracaoInstitucional:{}
                ,configuracaoDb:{}
                ,configuracaoDbCache:{}
                ,configuracaoMensageria:{}
                ,configuracaoEmail:{}
                ,home :{icon: 'pi pi-home'}
                ,agendamentos:[]
                ,tipoEvento:"tabela"
                ,etapas:[]
                ,estado:0
                ,usuario:{}
              };
  }
  classificaTipoInscricao(valor){
    if(valor===14){
      return 1;
    }else {
      return 2;
    }
  }
  componentWillMount(){
    

  }
  componentDidMount(){
    this.consultaUsuario();

    $.ajax({
      url:"/EsocialAPI/config/",
      dataType: 'json',
      success:function(resposta){
        console.log(resposta);
        this.setState({configuracoes:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  render() {
    const etapas =[
          {label: 'Ambiente'},
          {label: 'Banco de Dados'},
          {label: 'Mensageria'},
          {label: 'E-mail'}
        ]
        const tipoInscricao = this.classificaTipoInscricao(String(this.state.configuracaoInstitucional.cpfCnpj).length);
    return(

      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
        <Panel header="Configurações de Sistema">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              
              <div style={{marginBottom:'13px'}}>
                <h3>Configurações</h3>
              </div>
              <div className="pure-md-u-1-5">
              </div>
              <div className="pure-md-u-3-5">
                <form>
                  <TabView className="tabview-custom">
                    <TabPanel header="Ambiente" leftIcon="pi pi-cog">
                      <AmbienteConfig/>
                    </TabPanel>
                    <TabPanel header="Banco de dados" leftIcon="pi pi-table">
                      <DBConfig/>
                    </TabPanel>
                    <TabPanel header="Certificado e Mensageria" leftIcon="pi pi-cog">
                      <MensageriaConfig/>
                    </TabPanel>
                    <TabPanel header="Email" leftIcon="pi pi-envelope">
                      <EmailConfig/>
                    </TabPanel>
                  </TabView>
                </form>
              </div>
          </div>
              </Panel>

            </div>
            <div className="footer l-box is-center">
              <PermissionCheck usuario={this.state.usuario}  />
            </div>

      </div>
    );
  }
}

import React, { useState, useEffect, useRef } from 'react';

import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import 'brace/mode/sql';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MenuBar } from '../../../componentes/MenuBar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import $ from 'jquery';





const AgendaDetalhe = (props) => {
    //Equivalente ao this.state do construtor do component com Classe - https://pt-br.reactjs.org/docs/hooks-state.html

    const [agendaDetalheVisivel, setAgendaDetalheVisivel] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [dataInicio, setDataIncio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [diasAntesNotificacao, setdiasAntesNotificacao] = useState('');
    const [grupos, setGrupos] = useState([]);
    const [gruposSelecionados, setGruposSelecionados] = useState([]);

    useEffect(() => {
        setTitulo(props.agendamento.titulo);
        setDescricao(props.agendamento.descricao);
        setDataIncio(props.agendamento.dataInicio);
        setDataFim(new Date(props.agendamento.dataFim));
        setdiasAntesNotificacao(props.agendamento.diasAntesNotificar);
        setGrupos(props.grupos);

        const gruposSelecionados = formatGrupos(props.agendamento.grupos).map((grupo) => {
            return grupo.value
        });

        setGruposSelecionados(gruposSelecionados);

    }, [])

    function montaObjAgenda() {
        const gruposSelecionadosFormatado = grupos.filter((grupo) => {
            console.log(grupo)
            console.log(gruposSelecionados)
            if(gruposSelecionados.includes(grupo.idGrupo)){
                return true;
            }
            
        })

        console.log(gruposSelecionadosFormatado);
        let objAgenda = {
            idAgenda: props.agendamento.idAgenda,
            titulo: titulo,
            descricao: descricao,
            grupos: gruposSelecionadosFormatado
        }

        return objAgenda;
    }

    function enviarEmail() {
        const data = montaObjAgenda()
        console.log(data)
        $.ajax({
            url: "/EsocialAPI/admin/agenda/enviar-notificacao",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            method: 'post',
            data: JSON.stringify(data),
            success: function (resposta) {
                console.log(resposta)
                setGrupos(resposta);
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    function formatGrupos(grupos) {
        const gruposFormatados = grupos.map((grupo) => {
            return { label: grupo.descricao, value: grupo.idGrupo }
        })
        return gruposFormatados;
    }
    const style = {
        panel: {
            marginTop: 30,
        },
        breadCrumb: {
            marginBottom: 30,
        },
        dropdown: {
            marginRight: 30
        },
        card: {
            paddingBottom: 10
        },
        button: {
            marginTop: 20,
            marginBottom: 20
        },
        label: {
            fontSize: 16,
            fontWeight: 'bold',
            display: 'block',
        }
    };

    const footer = (
        <div>
            <Button
                id="btnEnviar"
                label="Enviar Notificação"
                icon="pi pi-envelope"
                onClick={(e) => enviarEmail()}
            />
            <Button
                id="btnEnviar"
                label="Salvar"
                icon="pi pi-check"
                onClick={(e) => this.editarUsuario()}
            />
            <Button
                label="Não"
                icon="pi pi-times"
                onClick={(e) => console.log('fecha')}
                className="p-button-secondary"
            />
        </div>
    );

    return (
        <div>

            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-secondary" onClick={() => setAgendaDetalheVisivel(true)} />

            <Dialog
                header="Detalhe Agendamento"
                visible={agendaDetalheVisivel}
                modal={true}
                style={{ width: '50vw' }}
                onHide={()=>setAgendaDetalheVisivel(false)}
                footer={footer}
            >

                <div className="pure-g" style={style.panel}>
                    <div className="pure-u-1 p-field">
                        <label style={{ fontWeight: 'bold', display: 'inline-block' }}>Título </label>
                        <InputText id="titulo" value={titulo} style={{ display: 'inline-block', width: '100%' }} onChange={(e) => setTitulo(e.target.value)} />
                    </div>
                </div>
                <div className="pure-g" style={style.panel}>
                    <div className="pure-u-1 p-field">
                        <label style={{ fontWeight: 'bold', display: 'inline-block' }}>Descrição </label>
                        <InputTextarea style={{ display: 'inline-block', width: '100%' }} rows={3} value={descricao} onChange={(e) => setDescricao(e.target.value)} autoResize />
                    </div>
                </div>
                <div className="pure-g" style={style.panel}>
                    <div className="pure-u-1-2 p-field">
                        <label style={{ fontWeight: 'bold', display: 'inline-block' }}>Data</label><br />
                        <Calendar id="icon" value={dataFim} onChange={(e) => console.log(e.value)} showIcon dateFormat='dd/mm/yy' />
                    </div>
                </div>
                <div className="pure-g" style={style.panel}>
                    <div className="pure-u-1-2 p-field">
                        <label style={{ fontWeight: 'bold', display: 'inline-block' }}>Dias Antes de Notificar</label><br />
                        <InputNumber inputId="minmax-buttons" value={diasAntesNotificacao} onValueChange={(e) => console.log("teste")} mode="decimal" showButtons min={1} max={10} />
                    </div>
                    <div className="pure-u-1-2 p-field">
                        <label style={{ width: '90%', fontWeight: 'bold', display: 'inline-block' }}>Grupo </label><br />
                        <MultiSelect style={{ width: '80%' }} value={gruposSelecionados} options={formatGrupos(grupos)} onChange={(e) => console.log(e.value)} />
                    </div>
                </div>
                {/* <div className="pure-g" style={style.panel}>
                    <div className="pure-u-1 p-field">
                        <p>Resumo: Compromisso agendado para dia {dataFim}. Notificação acontecerá {diasAntesNotificacao} dia(s) antes para usuários do(s) grupo(s): Administrador </p>
                    </div>
                </div> */}
            </Dialog>
        </div>


    );
}

export default AgendaDetalhe;
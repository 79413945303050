import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import {GrupoTabela} from '../sessoes/Grupo/GrupoTabela.js';
import $ from 'jquery';
import{ MenuBar } from '../componentes/MenuBar';
import {PermissionCheck} from '../componentes/PermissionCheck';

export class Grupo extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"tabela",usuario:{}};
  }
  componentDidMount(){
    this.consultaUsuario();
  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host ;
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  render() {
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-5-5">
                <GrupoTabela />
              </div>
          </div>

            </div>
            <div className="footer l-box is-center">
              <PermissionCheck usuario={this.state.usuario} url="/grupos"  />
            </div>

      </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import $ from 'jquery';

export class TabelaWizard extends Component {
  constructor() {
    super();
    this.state = {
       popup: false
      , importacaoWizard: 'block'
      , importacaoStatus: 'none'
      , infoImportacao: 'none'
      , evento: 'Nenhum Evento'
      , status: ['IMPORTANDO', 'CONCLUIDO', 'ERRO', 'EM ESPERA']
      , importacaoAtual: 1000
      , importacoesConcluidas: []
      , estiloImportacaoBotao: 'p-button-outlined p-button-info'
      , eventosFila: []
      , statusPos: 0
      , percentualImportacao: 0
      , resumo: null
      , eventoResumoAtual:{}
    };
  }

  async iniciarImportacao(eventosFila) {
    console.log(eventosFila);
    
    const eventos =  eventosFila.map((evt)=>{
      return evt.codigo;
    });
    
    this.setState({
      popup: false,
      importacaoStatus: 'block',
      importacaoWizard: 'none',
      infoImportacao: 'inline-block',
      importacaoAtual: eventos[0],
      eventoResumoAtual:eventosFila[0]
    });
    const funcImport = this.importacao();
    let importedEvts = [];
    for (const [index, codigoEvt] of eventos.entries()) {
      this.setState({importacaoAtual: eventos[index] })
      this.setState({eventoResumoAtual:eventosFila[index]})
      //this.setState({eventosFila:eventos})
      console.log("importação atual: " + codigoEvt)
      try {
        const response = await funcImport(1, codigoEvt);
        console.log(response)
        importedEvts.push(response);
        this.setState({ importacoesConcluidas: importedEvts });
        console.log(importedEvts);
      } catch (e) {
        const eventoErro = {
          codigoEvento:codigoEvt,
          status:'ERRO',
          msgRetorno: {severity: "erro", summary: "Erro interno, consulte o suporte técnico", detail: codigoEvt+" : 0 eventos .", sticky: null}
        }
        importedEvts.push(eventoErro);
        this.setState({ importacoesConcluidas: importedEvts });
        console.log('Erro ao importar');
      }
    }
  }

  popup() {
    this.setState({ popup: true });
    this.preview();
  }
  preview() {
    $.ajax({
      url: "/EsocialAPI/api/importacao/preview/1",
      dataType: 'json',
      method: 'GET',
      error: function (erro) {
        this.setState({ infoImportacao: 'none', statusPos: 2 });
      }.bind(this),
      success: function (resposta) {
        this.setState({ infoImportacao: 'none', statusPos: 0, resumo: this.classificaEventosAImportar(resposta), eventosFila: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  classificaEventosAImportar(resposta) {
    //
    var retorno = [];
    console.log(resposta);
    if (resposta.length > 0) {
      var anterior = null;
      resposta.map(function (evt, pos) {
        var evento = { nome: '', codigo: null, qtd: 0, status: '' };
        if (pos > 0) {
          var i = pos - 1;
          anterior = resposta[i];
          if (anterior.idEvento === evt.idEvento) {
            evento = retorno.find(function (ret) {
              return ret.codigo === anterior.codigo;
            });
            retorno.pop();
            evento.nome = evt.nome;
            evento.qtd = anterior.quantidade + evt.quantidade;
            evento.status = 'ESPERA';
            retorno.push(evento);
          } else {
            evento.nome = evt.nome;
            evento.codigo = evt.codigo;
            evento.qtd = evt.quantidade;
            evento.status = 'ESPERA';
            retorno.push(evento);
          }
        } else {
          evento.nome = evt.nome;
          evento.codigo = evt.codigo;
          evento.qtd = evt.quantidade;
          evento.status = 'IMPORTANDO';
          retorno.push(evento);
        }
        return null;
      });
      return retorno;
    }
  }
  importacao() {
    return (grupo, idEvento) => new Promise((resolve, reject) => {
      var endereco = "/EsocialAPI/api/importacao/start/" + grupo + "/" + idEvento;
      $.ajax({
        url: endereco,
        dataType: 'json',
        method: 'GET',
        success: function (resposta) {
          resolve(resposta)
        },
        error: function (erro) {
          reject(erro)
        },
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    })

  }
  changeStyleButton(importacaoAtual, importacoesConcluidas, key) {
    const eventoConcluido = importacoesConcluidas.find((evtsConcluidos) => evtsConcluidos.codigoEvento == key)
    if (eventoConcluido != undefined) {
      if(eventoConcluido.status=='CONCLUIDO'){
        return 'p-button-outlined p-button-success';
      }else if(eventoConcluido.status=='ERRO'){
        return 'p-button-outlined p-button-danger';
      }
    }
    if (importacaoAtual === key) {
      return 'p-button-outlined p-button-warning';
    } else {
      return 'p-button-outlined p-button-info';
    }
  }
  renderEvento(evento) {

    return (
      <div key={evento.codigo}>
        <Button 
          label={"S" + evento.codigo} 
          className={this.changeStyleButton(this.state.importacaoAtual, this.state.importacoesConcluidas, evento.codigo)} 
          onClick={()=>this.changeEventoImportadoDetalhe(evento.codigo)}
        />
        {/* <label style={estilo} key={evento.codigo}>S{evento.codigo}</label> */}
      </div>

    );
  }
  renderEventos(resumo) {
    if (resumo === null) {
      resumo = [];
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }} >
        {resumo.map((evento) => this.renderEvento(evento))}
      </div>
    );
  }
  changeEventoImportadoDetalhe(eventoSelecionado){
    console.log(eventoSelecionado);
    console.log(this.state.importacoesConcluidas);
    const eventoImportadoSelecionado = this.state.eventosFila.find(e=>e.codigo==eventoSelecionado);
    console.log(eventoImportadoSelecionado);
    if(eventoSelecionado!=undefined){
      this.setState({eventoResumoAtual:eventoImportadoSelecionado});
    }
  }
  renderDetalheEventoImportado(eventoSelecionado) {
    
    const eventoImportado = this.state.importacoesConcluidas.find((e)=>e.codigoEvento==((eventoSelecionado!=undefined)?eventoSelecionado.codigo:''));
    return (
      <div>
        <div style={{ display: 'inline' }}>
          {this.renderProgressSpinner(eventoImportado)}
          <label>Evento: </label>
        </div>
        <div style={{ display: 'inline' }}>
          <strong>{eventoSelecionado!=undefined?eventoSelecionado.codigo+' - '+eventoSelecionado.nome:''} </strong>
        </div><br />
        <div style={{ display: 'inline' }}>
          {this.renderProgressSpinner(eventoImportado)}
          <label>Status: </label>
        </div>
        <div style={{ display: 'inline' }}>
          <strong> {(eventoImportado==undefined) ?  'IMPORTANDO' : eventoImportado.status}</strong>
        </div><br />
        <div style={{ display: 'inline' }}>
          {this.renderProgressSpinner(eventoImportado)}
          <label>Eventos importados:</label>
        </div>
        <div style={{ display: 'inline' }}>
          <strong> {eventoImportado!=undefined ? eventoImportado.registros : ''}</strong>
        </div><br />
        <div style={{ display: 'inline' }}>
          <Button icon="pi pi-search" className="p-button-rounded p-button-success p-button-outlined" label="Visualizar eventos importados" />
        </div>
      </div>
    );
  }

  renderProgressSpinner(statusImportacao){
    if(statusImportacao==undefined || statusImportacao==null){
      return <ProgressSpinner style={{ width: '18px', height: '18px', display: this.state.infoImportacao }} />
    }else{
      return <i className="pi pi-check" ></i>
    }
  }
  render() {
    const renderFooter = (name) => {
      return (
        <div>
          <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ popup: false })} className="p-button-text" />
          <Button label="Importar" icon="pi pi-check" onClick={(e) => this.iniciarImportacao(this.state.eventosFila)} autoFocus />
        </div>
      );
    }
    return (
      <div className="content align-center">
        <div>
          <div>
            <h4>Eventos de tabela:</h4>
            <div style={{ display: this.state.importacaoWizard }}>
              <p>A seguir serão importados os eventos iniciais do esocial.</p>
              <p>Favor clique no botão importar para iniciar o processo.</p>
              <div>
                <Button label="Importar" icon="pi pi-check" iconPos="left" className="p-button-sm" onClick={(e) => this.popup()} />
                <Dialog header="Importação eventos de tabela" visible={this.state.popup} style={{ width: '50vw' }} onHide={(e) => this.setState({ popup: false })} footer={renderFooter()}>
                  <div>
                    <strong>Os seguintes ítens serão importados: </strong>
                    <em>
                      <ol>
                        <li>Históricos de envio.</li>
                        <li>Última interação de lote.</li>
                        <li>Id do evento e número do recibo.</li>
                      </ol>
                    </em>
                  </div>
                  <div>
                    <DataTable value={this.state.eventosFila} scrollable scrollHeight="200px">
                      <Column field="codigo" header="Código"></Column>
                      <Column field="status" header="status"></Column>
                      <Column field="quantidade" header="Quantidade"></Column>
                    </DataTable>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: this.state.importacaoStatus }}>
          <ProgressBar mode="indeterminate" />
          <div>{this.renderEventos(this.state.resumo)}</div>
          <div>{this.renderDetalheEventoImportado(this.state.eventoResumoAtual)}</div>
        </div>
      </div>

    );
  }
}
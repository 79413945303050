import React,{Component} from 'react';
//import 'primereact/resources/themes/nova-light/theme.css';
//import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/themes/saga-green/theme.css';
import $ from 'jquery';
import {PermissionCheck} from '../componentes/PermissionCheck';
import { Messages } from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import { ProgressBar } from 'primereact/progressbar';


export class Erro extends Component {
  constructor(){
    super();
    this.state={visible: false,lista : [],menuBar :[],usuario:{},erro:{codErro:0,descErro:'Erro Inesperado.',detalhes:'',exibeErro:'none', loadErro:'block'} };
  }
  componentDidMount(){
    this.consultaUsuario();
    this.retornaErro();
  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        //window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta,loadErro:'none'});
        $('#dvErro').fadeIn();
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  retornaErro(){
    console.log(this.props.match.params.id);
    $.ajax({
      url:"/EsocialAPI/api/erros/"+this.props.match.params.id,
      dataType: 'json',
      success:function(resposta){
        this.setState({erro:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }


  render() {
    return(
        <div>
            <MenuBar usuario={this.state.usuario} />
            <div className="content">
            <ProgressBar mode="indeterminate" style={{display:this.state.loadErro}} />
            <div className="content align-center" style={{display:this.state.exibeErro}} id="dvErro">
                <Messages ref={(el) => this.messages = el}></Messages>
                <div style={{textAlign:'center'}}>
                    <div style={{verticalAlign:'middle',height:'200px', display:'inline-block', border:'1px solid #ffa78c', padding:'30px 30px 30px 30px'}} className="pure-u-5-5">
                        <i style={{display:'inline-block',marginRight:'20px', color:'#ffa78c'}} className="pi pi-exclamation-triangle"></i>
                        <h5 style={{display:'inline-block'}}>{this.state.erro.codErro}: {this.state.erro.descErro}</h5>
                        <br />
                        <p>
                            <em>{this.state.erro.detalhes}</em>
                        </p>
                    </div>
                </div>
            </div>

                </div>

        </div>
    );
  }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Ajuda } from '../../sessoes/Processamento/preview/Ajuda';
import { Menubar } from 'primereact/menubar';
import XMLViewer from 'react-xml-viewer'
import { ProgressBar } from 'primereact/progressbar';
import $ from 'jquery';
import EvtRemunRPPS from '../FolhaPagamento/DTO/1.0/EvtRemunRPPS';
import Utils from '../../utils/Util';
import { ListaErros } from '../Processamento/preview/listaErros';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import EnvioComponent from '../Processamento/preview/EnvioComponent';



export class DialogRemunRpps extends Component {
    constructor() {
        super();
        library.add(fab, faHandshake, faHandHoldingUsd, faWallet);
        this.state = {
            idEventoFila: 0,
            bolAtivo: false,
            activeIndex: 0,
            isEnvioDisabled: true,
            resumo: 'none',
            xmlEvento: 'none',
            xmlEnvio: '',
            xml:'',
            ajuda: 'none',
            loadBar: 'block',
            evtRmnRPPS: {},
            ativaListaErro:false,
            listaErros:[],
            ativaResumo: true,
            isMensagemErroAtivo: false,
            ativaXml:false,
            isEventosEnviados:false,
            respostaEnvio:[]
        };
        this.renderDialog = this.renderDialog.bind(this);
    }
    componentDidMount() {
        //this.loadInfoEvtRemun();
    }
    teste(rowData) {
        console.log(rowData);
    }
    enviar() {
        $('#dspEnvio').fadeOut();
        /*$('#dspEnviando').html(<Submit idEventoFila={this.props.idEventoFila} />);*/
        $('#dspEnviando').fadeIn();
    }
    enviarEvento() {
        //this.setState({activeIndex:1,tabEnviandoDisable: false});
        console.log(this.props.idEventoFila);
        $.ajax({
          type: "POST",
          data: { 'idEventoFila[]': this.props.eventoFila.id },
          url: "/EsocialAPI/api/processamento/enviar/",
          dataType: 'json',
          success: function (resposta) {
            console.log(resposta);
            console.log('Retorno da resposta do envio');
            this.setState({ selecionados: [] });
            this.setState({ativaXml: false});
            this.setState({ativaResumo:false});
            this.setState({ativaListaErro: false});
            this.setState({ isEventosEnviados: true });
            this.setState({ respostaEnvio: resposta })
          }.bind(this),
          beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
    carregaListaErro(ideEventoFila) {
    
        $.ajax({
          url: "/EsocialAPI/api/processamento/valida/" + ideEventoFila,
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function (resposta) {
            console.log(resposta);
            this.setState({xml: resposta.xml})
            this.setState({ listaErros: resposta.erro });
          }.bind(this),
          beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
    visualizarEvento() {
        this.loadInfoEvtRemun();
        this.setState({ bolAtivo: true });
        $("#divValidado").html();
    }
 
    formataCPF(numCPF) {
        try {
            const cpf = require('node-cpf');
            return cpf.mask(numCPF);
        } catch (erro) {
            console.log(erro);
        }
    }
    renderAjuda() {
        try {
            this.setState({ loadBar: 'none', resumo: 'none', xmlEvento: 'none', ajuda: 'block' });
        } catch (e) {
            console.log(e);
        }
        //xmlEnvio
    }
    renderXmlEditor() {
        try {
            console.log("/EsocialAPI/folha/preview/XML/" + this.props.eventoFila.id);
            this.setState({ loadBar: 'block' });
            $.ajax({
                url: "/EsocialAPI/folha/preview/XML/" + this.props.eventoFila.id,
                dataType: 'json',
                success: function (resposta) {
                    this.setState({ xmlEnvio: resposta.xml, loadBar: 'none', resumo: 'none', xmlEvento: 'block', ajuda: 'none' });
                    console.log(resposta);
                }.bind(this),
                error: function (resposta) {
                    this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
                }.bind(this),
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
        } catch (e) {
            console.log(e);
        }
        //xmlEnvio
    }
    loadInfoEvtRemun() {

        if(!Utils.isObjectHasValue(this.state.evtRmnRPPS)){
            console.log("/EsocialAPI/folha/preview/Json/" + this.props.eventoFila.id);
            this.setState({ loadBar: 'block' });
            $.ajax({
                url: "/EsocialAPI/folha/preview/Json/" + this.props.eventoFila.id,
                dataType: 'json',
                success: function (resposta) {
                    console.log(resposta);
                    if (resposta.json !== "null") {
                        let objetoRemun = JSON.parse(resposta.json);
                        //this.carregaListaErro(this.props.eventoFila.id);
                        this.setState({ evtRmnRPPS: objetoRemun.evtRmnRPPS, loadBar: 'none', resumo: 'block', xmlEvento: 'none', ajuda: 'none' });
                        console.log(objetoRemun);
                    } else {
                        this.setState({ loadBar: 'none', xmlEvento: 'none', ajuda: 'none' });
                    }
                }.bind(this),
                error: function (resposta) {
                    this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
                }.bind(this),
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
        }else{
            this.setState({ativaListaErro:false});
            this.setState({ativaResumo:true});
            this.setState({ativaXml: false});
        }
        
    }
    renderListaErro(ativo){
        if(ativo){
            return <ListaErros listaErros={this.state.listaErros}></ListaErros>
        }
    }
    renderEstadoEvento(listaErros, ativo){
        console.log(ativo);
        if(listaErros.length===0){
            this.carregaListaErro(this.props.eventoFila.id);
            
        }
        if(listaErros.length>0 && ativo==false){
            console.log("Mensagem de erro deveria aparecer")
            this.setState({isMensagemErroAtivo:true});
            this.showMessage(this.state.listaErros);
        }
        
    }
    showMessage(listaErros){
        console.log(listaErros);
        if(listaErros.length!==0){
            if(listaErros[0]!=="Evento pronto para Envio"){
                console.log('Evento Inconsistente!')
                this.messages.show({severity: 'error', summary: 'Evento Inconsistente!', detail: 'Por favor, verifique "Lista de Erro" para a correção.', sticky:true})
            }else{
                console.log('Evento Pronto!')
                this.setState({isEnvioDisabled:false})
                this.messages.show({severity: 'success', summary: 'Evento Pronto!', detail: 'Evento pronto para o envio.', sticky:true})
            }    
        }
    }
    renderEvtRemunRPPS(evtRemunRPPS, ativo){
        if(ativo){
            return <EvtRemunRPPS evtRemunRPPS={evtRemunRPPS} />
        }
    }
    renderXml(ativo){
        if(ativo){
            return <XMLViewer xml={this.state.xml} />
        }
    }
    renderEnvioComponent(ativo, resposta){
        if(ativo){
            return <EnvioComponent isEventosEnviados={this.state.isEventosEnviados} respostaEnvio={resposta[0]}></EnvioComponent>
        }
    }
    renderDialog(ativo, evtRemunRPPS, items, footer, listaErro) {

        if (Utils.isObjectHasValue(evtRemunRPPS)) {
            if (ativo) {
                return (
                    <Dialog header="Evento de Remuneração" footer={footer} visible={ativo} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '80vw'}} modal={true} onHide={() => this.setState({ bolAtivo: false })}>
                        <div style={{ padding: '12px 12px 12px 12px', border: '1px solid #ccc' }}>
                            <Menubar model={items} style={{ marginTop: '12px' }} />
                            <ProgressBar mode="indeterminate" id="loadBarDiv" style={{ display: this.state.loadBar }} />
                            <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.resumo }}>

                                <label>Nome:</label><strong>{this.props.nome}</strong><br />
                                <label>CPF:</label><strong>{this.formataCPF(evtRemunRPPS.ideTrabalhador.cpfTrab)}</strong><br />
                                <Messages id="msgInfo" ref={(el) => this.messages = el}></Messages> 
                                {this.renderEstadoEvento(listaErro, this.state.isMensagemErroAtivo)}
                                
                                <hr />

                                {this.renderEvtRemunRPPS(evtRemunRPPS, this.state.ativaResumo)}
                                {this.renderListaErro(this.state.ativaListaErro)}
                                {this.renderXml(this.state.ativaXml)}
                                {this.renderEnvioComponent(this.state.isEventosEnviados, this.state.respostaEnvio)}
                                
                            </div>
                            {/* <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.xmlEvento }}>
                                <XMLViewer xml={this.state.xmlEnvio} />
                            </div> */}
                            {/* <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.ajuda }}>
                                <Ajuda idEventoEsocial={this.props.eventoFila.idEvento} />
                            </div> */}
                        </div>
                    </Dialog>
                );
            }
        }
    }

    render() {
        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviarEvento()} disabled={this.state.isEnvioDisabled} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ bolAtivo: false })} />
            </div>
        );
        const baseurl = window.location.protocol + "//" + window.location.host;
        const items = [
            {
                label: 'Resumo',
                icon: 'pi pi-id-card',
                command: (e) => {
                    this.loadInfoEvtRemun();
                }
            },
            {
                label: 'Lista Erro',
                icon: 'pi pi-id-card',
                command: ()=>{this.setState({ativaXml: false}); this.setState({ativaResumo:false}); this.setState({ativaListaErro:true});}
            },
            {
                label: 'Informações adicionais',
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Visualizar evento',
                        icon: 'pi pi-fw pi-pencil',
                        command: (e) => {
                            window.location.href = baseurl + "/EventoFila/" + this.props.eventoFila.id;
                        }
                    },
                    {
                        label: 'XML',
                        icon: 'pi pi-fw pi-file-o',
                        command: (e) => {
                            this.setState({ativaXml: true});
                            this.setState({ativaResumo:false});
                            this.setState({ativaListaErro: false});
                            //this.renderXmlEditor();
                        }
                    },
                    // {
                    //     label: 'Ajuda',
                    //     icon: 'pi pi-fw pi-question-circle',
                    //     command: (e) => {
                    //         this.renderAjuda();
                    //     }
                    // }
                ]
            }
        ];
        return (
            <div style={{ display: 'inline' }}>
                {this.renderDialog(this.state.bolAtivo, this.state.evtRmnRPPS, items, footer, this.state.listaErros)}
                <Button onClick={(e) => this.visualizarEvento()} tooltip="S1202"><FontAwesomeIcon icon={faWallet} style={{ margin: '3px' }} /></Button>&nbsp;
            </div>
        );
    }
}

import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {BreadCrumb} from 'primereact/breadcrumb';
import {TabView,TabPanel} from 'primereact/tabview';
import {Messages} from 'primereact/messages';

import { Tree } from 'primereact/tree';


export class AjudaTabela extends Component{
  constructor(){
    super();
    this.state={sessao:null,descricao:null
      ,selecionado:null,lista : [],items:null,visible:false,usuario:{},nome:"",login:"",email:"",senha:"",recebesms:false,whatsapp:false,telegram:false
      ,grupo:null,grupos:[],ajudaEvento:{autor:'',corpo:'',dataEdicao:new Date(),titulo:''}};
  }
  componentDidMount(){  
    var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        console.log(resposta);
        this.setState({usuario:resposta});
        this.construirMenu();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });    

  }
  mostraSelecao(event){
    console.log(event.value);
    this.setState({sessao: event.value});
  }
  construirMenu(){
    $.ajax({      
      url:"/EsocialAPI/sessao/arvore/"+this.state.usuario.login,
      dataType: 'json',
      success:function(retorno){
        console.log(retorno);
        this.setState({items : retorno});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  onSelect(event) {
    this.setState({descricao: event.node.data,sessao:event.node.label});
    this.consultaAjudaSessao(event.node.label);
  }
  consultaAjudaSessao(sessao){
      $.ajax({      
        url:"/EsocialAPI/ajuda/sessao/"+sessao,
        dataType: 'json',
        success:function(retorno){
          console.log(retorno);
          this.setState({ajudaEvento : retorno});
          this.showMessage(retorno.mensagem);
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
  }
  showMessage(message) {
    this.messages.show({ severity: message.severity, summary: message.summary, detail: message.detail });
}
  render() {
    const items = [
      {label:'Home',icon:'pi pi-home', url: '/EsocialReact/home'},
      {label:'Ajuda',icon:'pi pi-home', url: '/EsocialReact/ajuda/'}
    ];
    return(
      <div>
        <BreadCrumb  home="principal" model={items}/>
        <div>
                            <div className="feature-intro">
                              <br />
                                <em>Ajuda referente as abas do sistema:</em>
                            </div>
                            
        </div>
                        <div className="content-section implementation">
                        <Messages ref={(el) => this.messages = el}></Messages>
                          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                              <TabPanel header="Ajuda Sessao">
                              <div class="pure-g">
                                <div style={{display:'inline'}} class="pure-u-1-3">
                                  
                                  <Tree value={this.state.items} selectionMode="single"  
                                  selectionKeys={this.state.selecionado} onSelectionChange={e => this.setState({selecionado: e.value})} onSelect={this.onSelect.bind(this)}/>
                                </div>
                                <div style={{display:'inline'}} class="pure-u-2-3">
                                  <h4>Sessão: {this.state.ajudaEvento.titulo} </h4>
                                  <hr />
                                  {this.state.ajudaEvento.corpo}
                                  <hr />
                                  {new Date(this.state.ajudaEvento.dataEdicao).toLocaleString()}
                                </div>
                              </div>

                              </TabPanel>
                          </TabView>
                        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Panel } from 'primereact/panel';
import $ from 'jquery';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import {FiltroTarefasAutomatizadas} from './Processamento/filtros/FiltroTarefasAutomatizadas'

export class ProcessamentoAgendado extends Component {
  constructor() {
    super();
    this.handleTarefaChange = this.handleTarefaChange.bind(this);
    this.handleGrupoEventoChange = this.handleGrupoEventoChange.bind(this);
    this.handleEventoChange = this.handleEventoChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleAcaoChange = this.handleAcaoChange.bind(this);
    this.handleMesChange = this.handleMesChange.bind(this);
    this.handleAnoChange = this.handleAnoChange.bind(this);
    this.handleIndApuracaoChange = this.handleIndApuracaoChange.bind(this);
    this.handleObjDetalheChange = this.handleObjDetalheChange.bind(this);
    this.handleIdEventoFilaChange = this.handleIdEventoFilaChange.bind(this);
    this.handleIdLoteChange = this.handleIdLoteChange.bind(this);
    this.handleTipoServidorChange = this.handleTipoServidorChange.bind(this);
    this.onHide = this.onHide.bind(this);
    this.agendar = this.agendar.bind(this);
    this.save = this.save.bind(this);

    this.state = {
      showFiltro: false
      , evento: null
      , grupoEvento: 0
      , idEventoFila: 0
      , idLote: 0
      , status: null
      , tarefa: null
      , acao: null
      , mes: null
      , ano: null
      , indApuracao: null
      , objDetalhe: ''
      , tipoServidor: 0
      // Tela
      , titulo: ""
      , bolDataExecucao: false
      , dataExecucao: ""
      , anoPermanente: null
      , mesPermanente: null
      , diaPermanente: null
      , horaPermanente: null
      , minutoPermanente: null
      , bolEventoPermanente: false
      , usuario: {}
      , alvoAgendamento: null
      , events: []
    };
  }

  atualizaGrid() {
    $.ajax({
      url: "/EsocialAPI/api/agendamento/principal/",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ events: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  agendar() {
    let canExecute = true;
    let arrMensagem = [];

    if(this.state.titulo === null || this.state.titulo === '') {
      canExecute = false;
      arrMensagem.push({severity:'info', detail:'Título é campo obrigatório.'});
    }

    if((this.state.dataExecucao === null || this.state.dataExecucao === '') && this.state.bolDataExecucao === false) {
      canExecute = false;
      arrMensagem.push({severity:'info', detail:'Data Execução é campo obrigatório.'});
    }

    if(this.state.bolDataExecucao === true && this.state.diaPermanente === null && this.state.mesPermanente === null && this.state.anoPermanente === null) {
      canExecute = false;
      arrMensagem.push({severity:'info', detail:'Pelo menos um campo de data permantente é obrigatório.'});
    }

    if(this.state.tarefa === null) {
      canExecute = false;
      arrMensagem.push({severity:'info', detail:'Selecione uma tarefa.'});
    } 
    
    if(arrMensagem.length > 0) {
      this.showToast(arrMensagem);
    }

    if(canExecute) {
      const obj = {
        filtro: {
          evento: this.state.evento,
          idEventoFila: this.state.idEventoFila,
          idLote: this.state.idLote,
          status: this.state.status,
          tarefa: this.state.tarefa,
          acao: this.state.acao,
          mes: this.state.mes,
          ano: this.state.ano,
          indApuracao: this.state.indApuracao,
          objDetalhe: this.state.objDetalhe,
          tipoServidor: this.state.tipoServidor,
        },
        titulo: this.state.titulo,
        dataExecucao: this.state.dataExecucao,
        anoPermanente: this.state.anoPermanente,
        mesPermanente: this.state.mesPermanente,
        diaPermanente: this.state.diaPermanente,
        horaPermanente: this.state.horaPermanente,
        minutoPermanente: this.state.minutoPermanente,
        bolEventoPermanente: this.state.bolEventoPermanente,
      };

      $.ajax({
        url: "/EsocialAPI/api/agendamento/novo/",
        type: 'post',
        data: JSON.stringify(obj),
        dataType: 'json',
        contentType: 'application/json',
        error: function (erro) {
          console.log(erro);
          this.toast.show({ severity: 'error', summary: 'Erro ao agendar rotina automática', detail: 'Falha de comunicação:' + erro });
        }.bind(this),
        success: function (resposta) {
          this.atualizaGrid();
          this.toast.show(resposta);
          this.onHide();
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    }
  }

  componentDidMount() {
    this.consultaUsuario();
    this.atualizaGrid();
  }

  consultaUsuario() {
    var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
    $.ajax({
      url: "/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error: function () {
        window.location.href = baseurl;
      },
      success: function (resposta) {
        this.setState({ usuario: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  habilitaEventoPermanente(e) {
    this.setState({ bolEventoPermanente: e.checked });
    if (e.checked) {
      $("#eventoPermanente").fadeIn();
      this.setState({ bolDataExecucao: e.checked });
    } else {
      $("#eventoPermanente").fadeOut();
      this.setState({ bolDataExecucao: e.checked });
    }
  }

  save() {
    this.agendar();
    this.atualizaGrid();
  }

  onHide() {
    this.setState({ showFiltro: false })
  }

  handleTarefaChange(tarefa) {
      this.setState({ tarefa });
  }

  handleGrupoEventoChange(grupoEvento) {
      this.setState({ grupoEvento });
  }

  handleStatusChange(status) {
      this.setState({ status });
  }

  handleEventoChange(evento) {
      this.setState({ evento });
  }

  handleAcaoChange(acao) {
      this.setState({ acao });
  }

  handleMesChange(mes) {
      this.setState({ mes });
  }

  handleAnoChange(ano) {
      this.setState({ ano });
  }

  handleIndApuracaoChange(indApuracao) {
      this.setState({ indApuracao });
  }

  handleObjDetalheChange(objDetalhe) {
      this.setState({ objDetalhe });
  }

  handleIdEventoFilaChange(idEventoFila) {
      this.setState({ idEventoFila });
  }

  handleIdLoteChange(idLote) {
      this.setState({ idLote });
  }

  handleTipoServidorChange(tipoServidor) {
      this.setState({ tipoServidor });
  }

  showMessage(titulo, mensagem, tipoMensagem) {
    this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
  }

  showToast(arrMensagens) {
    this.toast.show(arrMensagens);
  }

  render() {
    const style = {
      label: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'block',
      },
      inputText: {
        width: '80%'
      },
      inputContainer: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
    }
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/EsocialReact/home' },
      { label: 'Processamento', icon: 'pi pi-home', url: '/EsocialReact/home' },
      { label: 'Agendamento', icon: 'pi pi-home', url: '/EsocialReact/processamento/fila' }
    ];
    
    const dialogFooter = (
      <div>
          <Button style={{ margin: '4px' }} label="Salvar" icon="pi pi-check" onClick={this.save} />
          <Button style={{ margin: '4px' }} label="Fechar" icon="pi pi-times" onClick={this.onHide} className="p-button-danger"/>
      </div>
    );
    return (
      <>
      <div>
        <MenuBar usuario={this.state.usuario} />
        <Toast ref={(el) => this.toast = el} position="bottomright"></Toast>
        <div className="content" >
            <div className="content align-center">
              <BreadCrumb home="principal" model={items} />
              <div className="p-grid">
                <div className="p-col">
                  <Panel header="Agendamento de Tarefas Automatizadas">
                    <div className="p-grid">
                      <div className="p-col-2">
                        <Button label="Cadastrar" icon="pi pi-plus" onClick={(e) => this.setState({ showFiltro: true })} />
                      </div>
                      <div className="p-col-10">
                        <FullCalendar 
                            events={this.state.events} 
                            plugins={[dayGridPlugin]} 
                            interactive={true} 
                            locale={'pt-BR'}
                            buttonText={{
                              today: 'Hoje',
                              month: 'Mês',
                              week: 'Semana',
                              day: 'Hoje',
                              list: 'Lista'
                            }}
                        />
                      </div>
                    </div>
                  </Panel>

                  <Dialog
                      header="Registrar agendamento"
                      footer={dialogFooter}
                      visible={this.state.showFiltro}
                      onHide={this.onHide}
                      dismissableMask={false}
                      style={{width: '90vw'}}
                  >
                    <div className="p-dialog-content">
                      <div className="p-grid">
                        <Messages ref={(el) => this.messages = el}></Messages>
                      </div>
                      <div style={style.inputContainer} className="pure-g">
                        <div className="pure-u-1-3">
                          <label style={style.label}>Título: </label>
                          <InputText id="titulo" value={this.state.titulo} onChange={(e) => this.setState({ titulo: e.target.value })} style={style.inputText}/>
                        </div>
                        <div className="pure-u-1-3">
                          <label style={style.label} htmlFor="dataEnvio">Data Execução</label>
                          <Calendar id="dataEnvio" dateFormat="dd/mm/yy" disabled={this.state.bolDataExecucao} value={this.state.dataExecucao}
                              onChange={(e) => this.setState({ dataExecucao: e.value })} showTime={true} showSeconds={true} />
                        </div>
                        <div className="pure-u-1-3">
                          <label style={style.label}>Agendamento Permanente</label>
                          <Checkbox onChange={e => this.habilitaEventoPermanente(e)} checked={this.state.bolEventoPermanente}></Checkbox>
                        </div>
                      </div>
                      <div style={style.inputContainer} className="pure-g">
                        <div id="eventoPermanente" style={{ display: 'none' }}>
                          <div className="pure-u-1-3">
                            <label style={style.label} htmlFor="minuto">Minuto</label>
                            <InputText keyfilter={/^[0-9*]/} tooltip="Inserir o minuto ou '99' para execução recorrente" tooltipOptions={{ position: 'right' }} id="minutoPermanente" value={this.state.minutoPermanente} onChange={(e) => this.setState({ minutoPermanente: e.target.value })} style={style.inputText} />
                          </div>
                          <div className="pure-u-1-3">  
                            <label style={style.label} htmlFor="hora">Hora</label>
                            <InputText keyfilter={/^[0-9*]/} tooltip="Inserir o hora ou '99' para execução recorrente" tooltipOptions={{ position: 'right' }} id="horaPermanente" value={this.state.horaPermanente} onChange={(e) => this.setState({ horaPermanente: e.target.value })} style={style.inputText} />
                          </div>
                          <div className="pure-u-1-3">
                            <label style={style.label} htmlFor="dia">Dia</label>
                            <InputText keyfilter={/^[0-9*]/} tooltip="Inserir de 1 a 31 ou '99' para execução recorrente" tooltipOptions={{ position: 'right' }} id="diaPermanente" value={this.state.diaPermanente} onChange={(e) => this.setState({ diaPermanente: e.target.value })} style={style.inputText} />
                          </div>
                          <div className="pure-u-1-3">
                            <label style={style.label} htmlFor="mes">Mês</label>
                            <InputText keyfilter={/^[0-9*]/} tooltip="Inserir de 1 a 12 ou '99' para execução recorrente" tooltipOptions={{ position: 'bottom' }} id="mesPermanente" value={this.state.mesPermanente} onChange={(e) => this.setState({ mesPermanente: e.target.value })} style={style.inputText} />
                          </div>
                          <div className="pure-u-1-3">  
                            <label style={style.label} htmlFor="ano">Ano</label>
                            <InputText keyfilter={/^[0-9*]/} tooltip="Inserir o ano ou '99' para execução recorrente" tooltipOptions={{ position: 'right' }} id="anoPermanente" value={this.state.anoPermanente} onChange={(e) => this.setState({ anoPermanente: e.target.value })} style={style.inputText} />
                          </div>
                        </div>
                      </div>
                      <div style={style.inputContainer} className="pure-g">
                        <div className="p-col-12">
                          <FiltroTarefasAutomatizadas
                              usuario={this.state.usuario}
                              evento={this.state.evento}
                              idEventoFila={this.state.idEventoFila}
                              idLote={this.state.idLote}
                              status={this.state.status}
                              tarefa={this.state.tarefa}
                              acao={this.state.acao}
                              mes={this.state.mes}
                              ano={this.state.ano}
                              indApuracao={this.state.indApuracao}
                              objDetalhe={this.state.objDetalhe}
                              tipoServidor={this.state.tipoServidor}
                              handleTarefaChange={this.handleTarefaChange}
                              handleGrupoEventoChange={this.handleGrupoEventoChange}
                              handleStatusChange={this.handleStatusChange}
                              handleEventoChange={this.handleEventoChange}
                              handleAcaoChange={this.handleAcaoChange}
                              handleMesChange={this.handleMesChange}
                              handleAnoChange={this.handleAnoChange}
                              handleIndApuracaoChange={this.handleIndApuracaoChange}
                              handleObjDetalheChange={this.handleObjDetalheChange}
                              handleIdEventoFilaChange={this.handleIdEventoFilaChange}
                              handleIdLoteChange={this.handleIdLoteChange}
                              handleTipoServidorChange={this.handleTipoServidorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {Panel} from 'primereact/panel';
import {BreadCrumb} from 'primereact/breadcrumb';
import{ MenuBar } from '../componentes/MenuBar'
import {Checkbox} from 'primereact/checkbox';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import {InputMask} from 'primereact/inputmask';
import {InputText} from 'primereact/inputtext';
import {PermissionCheck} from '../componentes/PermissionCheck';
import {Messages} from 'primereact/messages';
export class UserConfig extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"1",usuario:{},sms:false,whatsApp:false,telegram:false
    ,grupo:{},password:'',telefone:'',email:''};

    this.editarUsuario = this.editarUsuario.bind(this);
  }
  componentDidMount(){
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      success:function(resposta){
        console.log(resposta);
        this.setState({usuario:resposta,
                      email:resposta.email, 
                      sms:resposta.recebeSms,
                      whatsApp:resposta.whatsapp,
                      telegram:resposta.telegram,
                      grupo:resposta.grupo,
                      telefone:resposta.telefone, 
                    });
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
    this.consultaUsuario();
  }
  editarUsuario() {

    if(this.state.password!=null && this.state.password!=undefined && this.state.password!=''){
        var grupo = { idGrupo: this.state.usuario.grupo.idGrupo };
        var usuario = { idUsuario: this.state.usuario.idUsuario, 
                        nome: this.state.usuario.nome, 
                        login: this.state.usuario.login, 
                        grupo: grupo, 
                        email: this.state.email, 
                        password: this.state.password, 
                        recebeSms: this.state.sms, 
                        whatsapp: this.state.whatsApp, 
                        telegram: this.state.telegram,
                        recebeEmail: this.state.usuario.recebeEmail,
                        dataCadastro:this.state.usuario.dataCadastro,
                        dataExpiracao:this.state.usuario.dataExpiracao,
                        telefone: this.state.telefone
                    };

        var dados = JSON.stringify(usuario);
        console.log(dados);
        $.ajax({
          url: "/EsocialAPI/usuario/atualiza",
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: 'json',
          data: dados,
          error: function (erro) {
          }.bind(this),
          success: function (resposta) {
            this.messages.show(resposta);
           console.log('Deletou')
          }.bind(this),
          beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }else{
        this.messages.show({ severity: 'error', summary: 'Senha é obrigatório! ', detail: ' Por favor, preencha.' });
    }

  }
  consultaUsuario(){
    var baseurl = window.location.protocol + "//" + window.location.host + "/EsocialReact";
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  render() {
    const items = [
      {label:'Home',icon:'pi pi-home', url: '/EsocialReact/home'},
      {label:'Configurações',icon:'pi pi-home', url: '/EsocialReact/user/config'}
    ];
    const footer = <span>
    <Button label="Salvar" onClick={(e) => this.editarUsuario()} icon="pi pi-check" style={{marginRight: '.25em'}}/>
    <Button label="Cancel" icon="pi pi-times" className="p-button-secondary"/>
 </span>;
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="pure-u-5-5">
            <Panel header="Configurações">
            <Messages ref={(el) => this.messages = el}></Messages>
            <BreadCrumb  home="principal" model={items}/>
            <br />
            <br />
            <Card title="Dados do usuário" subTitle={this.state.usuario.nome} footer={footer} style={{width:'400px'}}>
            <Messages ref={(el) => this.messages = el}></Messages>
              <div>
                <label style={{fontWeight:'bold'}}>Email : </label> <InputText value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} /> <label></label><br />
                <label style={{fontWeight:'bold'}}>Senha : </label> <Password value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} /><br />
                <label style={{fontWeight:'bold'}}>Telefone :  </label><InputMask mask="(99) 99999-9999" value={this.state.telefone} onChange={(e) => this.setState({telefone: e.value})}></InputMask><br />
                <label style={{fontWeight:'bold'}}>Login :  </label><label>{this.state.usuario.login}</label><br />
                <label style={{fontWeight:'bold'}}>Recebe SMS :  </label><Checkbox onChange={e => this.setState({sms: e.checked})} checked={this.state.sms}></Checkbox><br />
                <label style={{fontWeight:'bold'}}>WhatsApp :  </label><Checkbox onChange={e => this.setState({whatsApp: e.checked})} checked={this.state.whatsApp}></Checkbox><br />
                <label style={{fontWeight:'bold'}}>Telegram :  </label><Checkbox onChange={e => this.setState({telegram: e.checked})} checked={this.state.telegram}></Checkbox> <br /> 
              </div>
            </Card>
            </Panel>
          </div>

          </div>
          <div className="footer l-box is-center">
            {/* <PermissionCheck usuario={this.state.usuario}  /> */}
          </div>

      </div>
    );
  }
}
